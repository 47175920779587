// import { Model, Tag, User, Rating, Parser } from '@datamodel';
import { Model, Parser } from '@datamodel';

// class TopAttendee extends Model {
//     static get model() {
//         return ({
//             attendanceCount: 0,
//             userId: ''
//         })
//     }

//     /**
//      * When parsing this would receive a object of the shape:
//      * attendaceCount: Number
//      * profile: Object
//      */
//     static get parseMap() {
//         return ({
//             profile: Model._wrapParseMapFunction((profile) => [{ 'userId': User.parse(profile).userId }])
//         })
//     }

//     constructor(args) {
//         super(args);
//         Object.assign(this, TopAttendee.unserialize({ ...TopAttendee.model, ...args }, { toClass: true }))
//     }
// }

export class PreparationGroup extends Model {
    static get uuid() { return 'preparationGroupId' };
    static get model () {
        return ({
            name: '',
            description: null,
            placeId: '',
            hasServingStatus: null,
            showServingStatus: null,
            separateQRCodes: null,
            ordering: -1,
            warningText: null,
        })
    }

    static get keymap() {
        return {
            id: PreparationGroup.uuid,
            place: 'placeId',
            separate_qr_codes: 'separateQRCodes'
        }
    }
}

export class PickupBar extends Model {
    static get uuid() { return 'pickupBarId' };
    static get model() {
        return ({
            name: '',
            hasStandardPickup: false,
            hasPreparedPickup: false,
            hasDelivery: false,
            hasOrderGrill: false,
            printCustomerTickets: 'optional',
            printKitchenTickets: 'optional',
            printQRsSeparately: 'never',
            place: Place.model
        })
    }

    static get keymap() {
        return {
            id: PickupBar.uuid,
            print_qrs_separately: 'printQRsSeparately'
        }
    }

    static get parseMap() {
        return {
            place: p => Place.parse(p)
        }
    }

    static get unserialMap() {
        return {
            place: p => Place.unserialize(p)
        }
    }

    static get toClassMap() {
        return {
            place: p => new Place(p)
        }
    }
    
    static getAnyPrepared(pickupBars) {
        const pb = pickupBars.find(pb => pb.hasPreparedPickup) || {};
        return pb.pickupBarId;
    }

    static getAnyDelivery(pickupBars) {
        const pb = pickupBars.find(pb => pb.hasDelivery) || {};
        return pb.pickupBarId;
    }
}

export class Place extends Model {
    static CACHE = 1000 * 60 * 5;
    static get uuid() { return 'placeId' };
    static get model() {
        return ({
            address: '',
            slug: '',
            name: '',
            pic: 'https://i.gyazo.com/4d63de0782d3f6c5efb94342a6b7b801.png',
            menuSalesAvailable: false,
            primaryColor: '',
            minimumOrderAmount: 0,
            logoURL: '',
        })
    }

    static get keymap() {
        return {
            uuid: Place.uuid,
            photo_url: 'pic',
            logo_url: 'logoURL'
        }
    }

    // static get parseMap() {
    //     return {
    //         lastUpdated: (val) => Parser.date(val),
    //         tags: (tags) => tags.map(tag => Tag.parse(tag)),
    //         topAttendees: (topAttendees) => topAttendees.map(ta => TopAttendee.parse(ta)),
    //         ratings: (ratings) => ratings.map(r => Rating.parse(r))
    //     }
    // }

    // static get unserialMap() {
    //     return {
    //         tags: (tags) => tags.map(tag => Tag.unserialize(tag)),
    //         topAttendees: (topAttendees) => topAttendees.map(ta => TopAttendee.unserialize(ta)),
    //         ratings: (ratings) => ratings.map(r => Rating.unserialize(r))
    //     }
    // }

    // static get toClassMap() {
    //     return {
    //         tags: (tags) => tags.map(tag => new Tag(tag)),
    //         topAttendees: (topAttendees) => topAttendees.map(ta => new TopAttendee(ta)),
    //         ratings: (ratings) => ratings.map(r => new Rating(r))
    //     }
    // }

    static shouldFetch({ object, ignoreCache, forceEvents }) {
        let emptyEvents = null;
        if (object && object.events) {
            emptyEvents = (object.events.upcoming.length + object.events.ended.length) < 1
        }
        return Model.shouldFetch({ object, ignoreCache }) || (forceEvents && emptyEvents);
    }

    static canSellExternalTickets(place) {
        // Can sell external tickets if ticketURL is available
        return (place.ticketURL !== null)
    }

    // static findMinimumPrice(place) {
    //     return place.tags.filter(tag => tag.code === 'copa_nacional_price').length > 0 ? place.tags.filter(tag => tag.code === 'copa_nacional_price')[0].numericValue : 0;
    // }


    // static shouldFetch({ place, ignoreCache }) {
    //     if (!place) return true;
    //     if (place.isFetching) return false;
    //     if (ignoreCache) return place.didInvalidate;
    //     return place.didInvalidate && (Date.now() - place.lastUpdated > Place.CACHE);
    // }

    constructor(args) {
        super(args);
        Object.assign(this, Place.unserialize({ ...Place.model, ...args }, { toClass: true }))
    }

}