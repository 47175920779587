import React, { useState, useEffect } from 'react';
import { FlatList, View } from 'react-native';

export const FlatGrid = ({ data, numColumns, ...props }) => {
    const addBlanks = (data, numColumns) => {
        const nLastRow = data.length % numColumns;
        const nBlanks = nLastRow ? numColumns - nLastRow : nLastRow;
        return [...data, ...Array.from({ length: nBlanks }, (_, i) => ({ key: `blank-${i}`, blank: true }))]
    }
    return (
        <FlatList
            key={numColumns}
            data={addBlanks(data, numColumns)}
            numColumns={numColumns}
            {...props}
        />
    )
}

export const SmartFlatGridView = ({ nRows, elementWidth, minSpacing, style, data, ...props }) => {

    const [numColumns, setNumColumns] = useState(data.length);
    const [ visibleData, setVisibleData ] = useState(data);
    const findNumberOfColumns = (containerSize, elementWidth, minSpacing) => {
        return Math.max(1, Math.floor((containerSize + minSpacing) / (elementWidth + minSpacing)))
    }

    const spacingProps = numColumns > 1 ?
        {
            columnWrapperStyle: {
                justifyContent: 'space-between'
            }
        } : {};

    const onLayout = ({ nativeEvent }) => {
        const nCols = findNumberOfColumns(nativeEvent.layout.width, elementWidth, minSpacing);
        setNumColumns(nCols);
        if (nRows) {
            setVisibleData( data.slice(0, Math.min(nRows * nCols, data.length) ) )
        }
        if (props.onLayout) props.onLayout();
    }

    useEffect(() => {
        if (nRows) {
            setVisibleData(data.slice(0, Math.min(nRows * numColumns, data.length ) ))
        } else {
            setVisibleData(data);
        }
    }, [nRows, data.length])

    return (
        <FlatGrid data={visibleData} numColumns={numColumns} {...props} onLayout={onLayout} style={style} {...spacingProps} />
    )
}