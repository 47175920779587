import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { View, Text, Pressable, StyleSheet, Dimensions, Animated, Keyboard } from 'react-native';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { Mixins, Icon, Typography, Spacing } from '@styles';
import Animation from '@utils/animations';

const {width: deviceWidth, height: deviceHeight} = Dimensions.get('window');

// export const ChildrenWrapper = forwardRef(({children, initialState, ...props}, ref) => {
//     const [ state, setState ] = useState(initialState);

//     useImperativeHandle(ref, () => ({
//         getState: () => state
//     }))

//     return
// });

/**
 * Action objects have the following properties:
 * 
 * {
 *  function,
 *  displayText,
 *  type, // This can be 'destructive', 'main' or none
 * }
 */

export const ActionSheet = forwardRef(({ _dismissModal, actions, children, initialState, dismissText, style,...props }, ref) => {

    const animation = useRef(new Animated.Value(0)).current;
    const { useElevation, useColor } = useTheme();
    const elevation = useElevation(24);
    const {translate} = useLanguage();
    const [bottomMargin, setBottomMargin] = useState(Spacing.MARGINS);
    const [state, setState] = useState(initialState);
    const [titleColor, body, destructive, secondary, line] = useColor(['title', 'body', 'error', 'secondary', 'line']);

    useImperativeHandle(ref, () => ({
        getState: () => state
    }))

    const _wrapAction = (action) => async () => {
        if (!action) {
            animateDismiss();
            return;
        }

        const result = await action();
        if (result && _dismissModal) animateDismiss();
    }

    useEffect(() => {
        Animated.timing(animation, Animation.create(deviceHeight, 350)).start();

        Keyboard.addListener('keyboardWillShow', _keyboardDidShow);
        // cleanup function
        return () => {
            Keyboard.removeListener('keyboardWillShow', _keyboardDidShow);
            animation.stopAnimation();
        };
    }, [])

    const _keyboardDidShow = ({ endCoordinates }) => {
        setBottomMargin(bottomMargin + endCoordinates.height)
    }
    
    const animateDismiss = () => {
        Animated.timing(animation, Animation.create(0, 250)).start(({finished}) => {
            if (finished) _dismissModal();
        })
    }
    
    return (
        <Animated.View style={[styles.wrapper,
            {
                marginBottom: bottomMargin,
                maxHeight: animation,
                opacity: animation.interpolate(Animation.keyframes(.9, 1))
            }
        ]}>

            {
                !children ?
                <View style={[styles.buttonContainer, elevation, Mixins.rounding(deviceWidth - 2 * Spacing.MARGINS), style]}>
                    {actions.map((a,i) => {
                        return <Pressable key={i} style={[styles.textButton, { borderColor: line, borderTopWidth: i ? 1 : 0 }]} onPress={_wrapAction(a.function)} >
                            <Text style={[Typography.SUBTITLE, {
                                color: a.type === 'destructive' ? destructive : secondary,
                            }]}>{a.displayText}</Text>
                        </Pressable>
                    })}
                </View>
                :
                <View style={[styles.container, elevation, style]} {...props}>
                    { initialState ? children(state, setState) : children }
                    { actions.length > 0 && <View style={[styles.buttonContainer]}>
                        {actions.map((a, i) => {
                            return <Pressable key={i} style={[styles.textButton, {borderColor: line}]} onPress={_wrapAction(a.function)} >
                                <Text style={[Typography.SUBTITLE, {
                                    color: a.type === 'destructive' ? destructive : secondary,
                                }]}>{a.displayText}</Text>
                            </Pressable>
                        })}
                    </View> }
                </View>
            }
            <Pressable style={[styles.textButton, styles.cancelButton, elevation,]} onPress={animateDismiss} >
                <Text style={[Typography.TITLE, {
                    color: secondary,
                }]}>{ dismissText || translate('cancel') }</Text>
            </Pressable>
        </Animated.View>
    )
})

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 'auto',
        marginHorizontal: Spacing.MARGINS,
    },
    container: {
        paddingTop: Spacing.M,
        ...Mixins.rounding(deviceWidth - 2 * Spacing.MARGINS),
    },
    cancelButton: {
        marginTop: Spacing.M,
        ...Mixins.rounding(deviceWidth - 2 * Spacing.MARGINS),
        borderTopWidth: 0,
    },
    buttonContainer: {
        justifyContent: 'space-around',
        marginTop: Spacing.M
    },
    textButton: {
        paddingVertical: Spacing.M,
        flexGrow: 1,
        alignItems: 'center',
        borderTopWidth: 1,
    }
})