import { useTheme } from '@themeProvider';
import { ScrollView, Dimensions, View, Text, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Spacing, Typography, Mixins } from '@styles';
import { writeSettings } from '@redux/actions';
import { useLanguage } from '@languageProvider';

import Constants from 'expo-constants';
import { Button, RadioButton, Switch } from '@atoms';
import { printService } from '@utils/printing';
const API_PREFIX = Constants.manifest.extra.API_PREFIX;


const dWidth = Dimensions.get('window').width;

// `tripay/api/qr?string=%5E%23SC%5E303FFF0&format=png&error_correction=Q&rounded=false`

export const SettingsScreen = () => {
    const { translate } = useLanguage();
    const { useColor, useElevation } = useTheme();
    const [backgroundColor, title] = useColor(['background', 'title']);
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const elevation = useElevation(2);

    const handlePrintModeChange = (printMode) => {
        dispatch(writeSettings({ printMode }));
        printService.mode = printMode;
    }

    const handlePaperWidthChange = (paperWidth) => {
        dispatch(writeSettings({ paperWidth }));
    }
    
    return (
        <View style={{ flex: 1, backgroundColor, padding: Spacing.MARGINS }}>
            <View style={{ gap: Spacing.L }}>
                <Text style={[Typography.H6, { color: title }]}>{translate('printSettings')}</Text>
                <View style={[Mixins.HORIZONTAL, { justifyContent: 'space-between' }]}>
                    <View style={{ gap: Spacing.S }}>
                        <Text style={[Typography.OVERLINE]}>{translate('printModeLabel')}</Text>
                        <View style={{ gap: Spacing.M }}>
                            <RadioButton
                                label={translate('printModeSystem')}
                                checked={settings.printMode === 'system'}
                                onPress={() => handlePrintModeChange('system')}
                            />
                            <RadioButton
                                label={translate('printModeESCPOS')}
                                checked={settings.printMode === 'escpos'}
                                onPress={() => handlePrintModeChange('escpos')}
                            />
                        </View>
                    </View>

                    <View style={{ gap: Spacing.S }}>
                        <Text style={[Typography.OVERLINE]}>{translate('paperWidth')}</Text>
                        <View style={{ gap: Spacing.M }}>
                            <RadioButton
                                label='58mm'
                                checked={settings.paperWidth === '58mm'}
                                onPress={() => handlePaperWidthChange('58mm')}
                            />
                            <RadioButton
                                label='80mm'
                                checked={settings.paperWidth === '80mm'}
                                onPress={() => handlePaperWidthChange('80mm')}
                            />
                        </View>
                    </View>

                    <View></View>
                </View>
            </View>

            <View style={{ flexGrow: 1 }}></View>

            <Button color='accent' title={translate('refreshApp')} icon='refresh' onPress={window.location.reload.bind(window.location)} />
        </View>
    );
}