/**
 * Color spaces
 */

export class sRGBAColor {
    constructor(r, g, b, a = 1) {
        this.red = r;
        this.green = g;
        this.blue = b;
        this.alpha = a;
        errorControl(r, 1, 'red');
        errorControl(g, 1, 'green');
        errorControl(b, 1, 'blue');
        errorControl(a, 1, 'alpha')
    }

    static WHITE = new sRGBAColor(1, 1, 1);
    static BLACK = new sRGBAColor(0, 0, 0);

    toString() {
        return 'rgba(' + 100 * this.red + '%, ' + 100 * this.green + '%, ' + (100 * this.blue + '%, ' + this.alpha + ')')
    }

    get args() {
        return [this.red, this.green, this.blue, this.alpha]
    }

    /**
     * Check for sRGBAColor equality
     * @param {sRGBAColor} color 
     * @returns {Boolean} True if equal, false if not
     */
    isEqual(color) {
        return Math.abs(this.red - color.red) < minIncrement && Math.abs(this.green - color.green) < minIncrement && Math.abs(this.blue - color.blue) < minIncrement && Math.abs(this.alpha - color.alpha) < minIncrement
    }

    toHEX() {
        return toHex(Math.round(255 * this.red)) + toHex(Math.round(255 * this.green)) + toHex(Math.round(255 * this.blue)) + (1 > this.alpha ? toHex(Math.round(255 * this.alpha)) : '')
    }

    opaque() {
        return 1 - this.alpha < minIncrement ? this : new sRGBAColor(this.red, this.green, this.blue)
    }

    isOpaque() {
        return (1 - this.alpha < minIncrement);
    }

    relativeLuminance() {
        return 0.2126 * ChannelLinearCorrection(this.red) + 0.7152 * ChannelLinearCorrection(this.green) + 0.0722 * ChannelLinearCorrection(this.blue);
    }

    /**
     * 
     * @param {sRGBAColor} foreground 
     * @param {sRGBAColor} background 
     * @returns {Number} Contrast ratio between foreground and background color
     */
    static WCAGContrastRatio(foreground, background) {
        var opaqueB = background.opaque();
        // If a is not opaque
        if (!foreground.isOpaque()) {
            var alphaCorrection = opaqueB.alpha * (1 - a.alpha);
            /*
                Replaces a with the resulting opaque color of overlaying
                a on top of opaqueB
            */
            foreground = new sRGBAColor(
                foreground.red * foreground.alpha + opaqueB.red * alphaCorrection,
                foreground.green * foreground.alpha + opaqueB.green * alphaCorrection,
                foreground.blue * foreground.alpha + opaqueB.blue * alphaCorrection,
                foreground.alpha + alphaCorrection
            )
        }
        var rlA = foreground.relativeLuminance();
        var rlB = background.relativeLuminance();
        return rlA >= rlB ? (rlA + 0.05) / (rlB + 0.05) : (rlB + 0.05) / (rlA + 0.05)

    }

    /**
    * 
    * @param {sRGBAColor} foreground 
    * @param {sRGBAColor} background 
    * @returns {Number} Contrast ratio between foreground and background color
    */
    WCAGContrastRatio(background) {
        return sRGBAColor.WCAGContrastRatio(this, background);
    }

    isLight(minimumContrast = 4.5) {
        var whiteContrast = this.WCAGContrastRatio(sRGBAColor.WHITE);
        if (whiteContrast >= minimumContrast) return 0;
        var blackContrast = this.WCAGContrastRatio(sRGBAColor.BLACK);
        return blackContrast >= minimumContrast ? 1 : whiteContrast > blackContrast ? 0 : 1
    };

    static fromHEX(hexString) {
        if (!/^[a-fA-F0-9]{3,8}$/.test(hexString)) throw Error('Invalid hex color string: ' + hexString);
        if (3 === hexString.length || 4 === hexString.length) {
            var b = /^(.)(.)(.)(.)?$/.exec(hexString).slice(1, 5).map(function (e) {
                return e ?
                    e + e : 'ff'
            });
        } else if (6 === hexString.length || 8 === hexString.length) {
            b = /^(..)(..)(..)(..)?$/.exec(hexString).slice(1, 5),
                void 0 === b[3] && (b[3] = 'ff');
        } else {
            throw Error('Invalid hex color string: ' + hexString);
        }
        var red = toInt(b[0]) / 255;
        var green = toInt(b[1]) / 255;
        var blue = toInt(b[2]) / 255;
        var alpha = toInt(b[3]) / 255;
        return new sRGBAColor(red, green, blue, alpha);
    }

    toHSL() {
        var M = Math.max(this.red, this.green, this.blue),
            m = Math.min(this.red, this.green, this.blue),
            chroma = M - m,
            hue = 0,
            saturation = 0,
            lightness = clamp(0.5 * (M + m), 0, 1);

        if (M - m > minIncrement) {
            if (M === this.red) {
                hue = 60 * (this.green - this.blue) / chroma;
            } else if (M === this.green) {
                hue = 60 * (this.blue - this.red) / chroma + 120;
            } else if (M === this.blue) {
                hue = 60 * (this.red - this.green) / chroma + 240;
            } else {
                hue = 0;
            }
        }

        if (lightness === 0 || lightness === 1) {
            saturation = 0;
        } else {
            saturation = clamp(chroma / (1 - Math.abs(2 * lightness - 1)), 0, 1);
        }

        hue = Math.round(hue + 360) % 360;
        return new HSLColor(hue, saturation, lightness, this.alpha);
    }

    toHSV() {
        var M = Math.max(this.red, this.green, this.blue),
            m = Math.min(this.red, this.green, this.blue),
            chroma = M - m,
            hue = 0,
            saturation = 0;

        if (M - m > minIncrement) {
            if (M === this.red) {
                hue = 60 * (this.green - this.blue) / chroma;
            } else if (M === this.green) {
                hue = 60 * (this.blue - this.red) / chroma + 120;
            } else if (M === this.blue) {
                hue = 60 * (this.red - this.green) / chroma + 240;
            } else {
                hue = 0;
            }
        }

        if (M > minIncrement) {
            saturation = chroma / M;
        }

        hue = Math.round(hue + 360) % 360;
        return new HSVColor(hue, saturation, M, this.alpha);
    }

    toLAB() {

        var rLin = ChannelLinearCorrection(this.red),
            gLin = ChannelLinearCorrection(this.green),
            bLin = ChannelLinearCorrection(this.blue),
            luminance = 0.2126729 * rLin + 0.7151522 * gLin + 0.072175 * bLin;
        // luminance equals yD65 value. 

        // CIE XYZ Values
        var xD65 = 0.4124564 * rLin + 0.3575761 * gLin + 0.1804375 * bLin,
            yD65 = luminance,
            zD65 = (0.0193339 * rLin + 0.119192 * gLin + 0.9503041 * bLin);

        // D65 White XYZ Values
        var xWhite = .95047,
            yWhite = 1,
            zWhite = 1.08883;

        return new LABColor(
            116 * fCIELAB(luminance) - 16,
            500 * (fCIELAB((xD65) / xWhite) - fCIELAB(luminance / yWhite)),
            200 * (fCIELAB(luminance / yWhite) - fCIELAB(zD65 / zWhite)),
            this.alpha
        )
    }

    overlay(background, opacity = 1) {
        let alpha = opacity;
        if (!this.isOpaque()) {
            alpha = this.alpha;
        }

        let alphaCorrection = 1 - alpha;

        return new sRGBAColor(
            this.red * alpha + background.red * alphaCorrection,
            this.green * alpha + background.green * alphaCorrection,
            this.blue * alpha + background.blue * alphaCorrection
        )
    }
}


export class HSLColor {
    constructor(hue, saturation, lightness, alpha = 1) {
        this.hue = hue;
        this.saturation = saturation;
        this.lightness = lightness;
        this.alpha = alpha;
        errorControl(hue, 360, 'hue');
        errorControl(saturation, 1, 'saturation');
        errorControl(lightness, 1, 'lightness');
        errorControl(alpha, 1, 'alpha')
    }

    get args() {
        return [this.hue, this.saturation, this.lightness, this.alpha]
    }

    toString() {
        return 'hsla(' + this.hue + ', ' + 100 * this.saturation + '%, ' + (100 * this.lightness + '%, ' + this.alpha + ')')
    }

    /**
     * Rotates an HSL color with determined degrees
     * @param {Number} degree in range [-180, 180]
     * @returns {HSLColor} Rotated color
     */
    rotate(degree) {
        return new HSLColor((this.hue + degree + 360) % 360, this.saturation, this.lightness, this.alpha)
    }

    /**
     * Transforms a HSL to sRGBA color
     * @returns {sRGBAColor} Equivalent color
     */
    tosRGBA() {
        const chroma = (1 - Math.abs(2 * this.lightness - 1)) * this.saturation;
        return _HCLtosRGBA(this.hue, this.alpha, chroma, Math.max(0, this.lightness - chroma / 2))
    }
}

export class HSVColor {
    constructor(hue, saturation, value, alpha = 1) {
        this.hue = hue;
        this.saturation = saturation;
        this.value = value;
        this.alpha = alpha;
        errorControl(hue, 360, 'hue');
        errorControl(saturation, 1, 'saturation');
        errorControl(value, 1, 'value');
        errorControl(alpha, 1, 'alpha')
    }

    get args() {
        return [this.hue, this.saturation, this.value, this.alpha]
    }

    /**
     * Transforms a HSV to sRGBA color
     * @param {HSVColor} color Color in HSV space.
     * @returns {sRGBAColor} Equivalent color
     */
    tosRGBA() {
        var chroma = this.value * this.saturation;
        return _HCLtosRGBA(this.hue, this.alpha, chroma, Math.max(0, this.value - chroma))
    }

    toHSL() {
        var lightness = clamp((2 - this.saturation) * this.value / 2, 0, 1),
            saturation = 0;

        if (0 < lightness && 1 > lightness) {
            saturation = (this.value - lightness) / (Math.min(lightness, 1 - lightness));
        }

        saturation = clamp(saturation, 0, 1);
        return new HSLColor(this.hue, saturation, lightness, this.alpha)
    }
}

export class LABColor {
    constructor(lightness, A, B, alpha = 1) {
        this.lightness = lightness;
        this.A = A;
        this.B = B;
        this.alpha = alpha;
        errorControl(lightness, Number.MAX_VALUE, 'lightness');
        errorControl(alpha, 1, 'alpha')
    }

    get args() {
        return [this.lightness, this.A, this.B, this.alpha]
    }

    isEqual(color) {
        return 0.0001 > Math.abs(this.lightness - color.lightness) && 0.0001 > Math.abs(this.A - color.A) && 0.0001 > Math.abs(this.B - color.B) && Math.abs(this.alpha - color.alpha) < minIncrement
    };

    toLCH() {
        return new LCHColor(
            this.lightness,
            Math.sqrt(Math.pow(this.A, 2) + Math.pow(this.B, 2)),
            (180 * Math.atan2(this.B, this.A) / Math.PI + 360) % 360,
            this.alpha
        )
    }

    tosRGBA() {
        var xWhite = .95047,
            yWhite = 1,
            zWhite = 1.08883;

        var _luminance = (this.lightness + 16) / 116;
        var xD65 = xWhite * inverse_fCIELAB(_luminance + this.A / 500),
            yD65 = yWhite * inverse_fCIELAB(_luminance),
            zD65 = zWhite * inverse_fCIELAB(_luminance - this.B / 200);
        return new sRGBAColor(
            clamp(inverse_ChannelLinearCorrection(3.2404542 * xD65 + - 1.5371385 * yD65 + - 0.4985314 * zD65), 0, 1),
            clamp(inverse_ChannelLinearCorrection(- 0.969266 * xD65 + 1.8760108 * yD65 + 0.041556 * zD65), 0, 1),
            clamp(inverse_ChannelLinearCorrection(0.0556434 * xD65 + - 0.2040259 * yD65 + 1.0572252 * zD65), 0, 1),
            this.alpha)
    }
}

export class LCHColor {
    constructor(lightness, chroma, hue, alpha = 1) {
        this.lightness = lightness;
        this.chroma = chroma;
        this.hue = hue;
        this.alpha = alpha;
        errorControl(lightness, Number.MAX_VALUE, 'lightness');
        errorControl(chroma, Number.MAX_VALUE, 'chroma');
        errorControl(hue, 360, 'hue');
        errorControl(alpha, 1, 'alpha')
    };

    get args() {
        return [this.lightness, this.chroma, this.hue, this.alpha]
    }

    isEqual(color) {
        return 0.0001 > Math.abs(this.lightness - color.lightness) && 0.0001 > Math.abs(this.chroma - color.chroma) && 0.0001 > Math.abs(this.hue - color.hue) && Math.abs(this.alpha - color.alpha) < minIncrement
    }

    toLAB() {
        var hueRadians = this.hue * Math.PI / 180;
        return new LABColor(this.lightness, this.chroma * Math.cos(hueRadians), this.chroma * Math.sin(hueRadians), this.alpha);
    }

    /**
     * Rotates an LCH color with determined degrees
     * @param {Number} degree in range [-180, 180]
     * @returns {LCHColor} Rotated color
     */
    rotate(degree) {
        return new LCHColor(
            this.lightness,
            this.chroma,
            (this.hue + degree + 360) % 360,
            this.alpha)
    }
}

/**
 * CIE Spaces utilities
 */

/**
 * Calculates the pondered value of a channel in the calculation of the relative luminance.
 * Linearly gamma-corrects the value of a sRGB channel.
 * @param {Number} channelValue - Between 0 and 1
 * @returns {Number} Linearly gamma-corrected value of a channel
 */
export function ChannelLinearCorrection(channelValue) {
    return 0.04045 >= channelValue ? channelValue / 12.92 : Math.pow((channelValue + 0.055) / 1.055, 2.4)
}

export function inverse_ChannelLinearCorrection(cLin) {
    return 0.0031308 >= cLin ? 12.92 * cLin : 1.055 * Math.pow(cLin, 1 / 2.4) - 0.055
}

export function fCIELAB(t) {
    var delta = 6 / 29;
    return t > Math.pow(delta, 3) ? Math.pow(t, 1 / 3) : t / (3 * Math.pow(delta, 2)) + 4 / 29
}

export function inverse_fCIELAB(t) {
    var delta = 6 / 29;
    return t > delta ? Math.pow(t, 3) : 3 * Math.pow(delta, 2) * (t - 4 / 29)
}


/**
 * HSL/HSV utilities
 */
/**
 * Function that transforms a version of HSL/HSV color to sRGBA.
 * @param {Number} hue - Hue in range [0-360]
 * @param {Number} alpha - Alpha value [0,1]
 * @param {Number} chroma - Chroma in HCL, equals (1 - |2L-1|*S) in HSL
 * @param {Number} lightnessCorrection L - C/2 where L is lightness in HSL and C is chroma as in 3rd param.
 * @returns {sRGBAColor} Equivalent sRGBA color
 */
function _HCLtosRGBA(hue, alpha, chroma, lightnessCorrection) { // sd
    var red = lightnessCorrection,
        green = lightnessCorrection,
        blue = lightnessCorrection;
    var huePartition = hue % 360 / 60;
    var x = chroma * (1 - Math.abs(huePartition % 2 - 1));
    switch (Math.floor(huePartition)) {
        case 0:
            red += chroma;
            green += x;
            break;
        case 1:
            red += x;
            green += chroma;
            break;
        case 2:
            green += chroma;
            blue += x;
            break;
        case 3:
            green += x;
            blue += chroma;
            break;
        case 4:
            red += x;
            blue += chroma;
            break;
        case 5:
            red += chroma,
                blue += x
    }
    return new sRGBAColor(red, green, blue, alpha)
}


/**
 * Utilities
 */

function errorControl(a, b, c) {
    if (isNaN(a) || 0 > a || a > b) throw new RangeError(a + ' for ' + c + ' is not between 0 and ' + b);
}

// minIncrement in 8-bit color spaces.
var minIncrement = Math.pow(2, -8);

function toHex(number) {
    number = number.toString(16);
    return 2 <= number.length ? number : '0' + number;
}

function toInt(hexString) {
    if (!/^[a-fA-F0-9]+$/.test(hexString)) throw Error('Invalid hex string: ' + hexString);
    return parseInt(hexString, 16)
}

export function clamp(val, m, M) {
    return Math.min(Math.max(val, m), M)
}

export class Color {
    constructor({ hex, srgba, hsl, hsv, lab, lch }) {
        this.hex = hex;
        this.srgba = srgba && new sRGBAColor(...srgba);
        this.hsl = hsl && new HSLColor(...hsl);
        this.hsv = hsv && new HSVColor(...hsv);
        this.lab = lab && new LABColor(...lab);
        this.lch = lch && new LCHColor(...lch);

        this.generate()
    }

    static WHITE = new Color({ hex: 'ffffff' });

    __calculateFromLCH() {
        this.lab = this.lch.toLAB();
        this.srgba = this.lab.tosRGBA();
        this.hsv = this.srgba.toHSV();
        this.hsl = this.srgba.toHSL();
        this.hex = this.srgba.toHEX();
    }

    __calculateFromLAB() {
        this.lch = this.lab.toLCH();
        this.srgba = this.lab.tosRGBA();
        this.hsv = this.srgba.toHSV();
        this.hsl = this.srgba.toHSL();
        this.hex = this.srgba.toHEX();
    }

    __calculateFromHSL() {
        this.srgba = this.hsl.tosRGBA();
        this.hsv = this.srgba.toHSV();
        this.lab = this.srgba.toLAB();
        this.hex = this.srgba.toHEX();
        this.lch = this.lab.toLCH();
    }

    __calculateFromHSV() {
        this.srgba = this.hsl.tosRGBA();
        this.hsl = this.hsv.toHSL();
        this.lab = this.srgba.toLAB();
        this.hex = this.srgba.toHEX();
        this.lch = this.lab.toLCH();
    }

    __calculateFromsRGBA() {
        this.hsv = this.srgba.toHSV();
        this.hsl = this.srgba.toHSL();
        this.lab = this.srgba.toLAB();
        this.hex = this.srgba.toHEX();
        this.lch = this.lab.toLCH();
    }

    __calculateFromHEX() {
        this.srgba = sRGBAColor.fromHEX(this.hex);
        this.hsv = this.srgba.toHSV();
        this.hsl = this.srgba.toHSL();
        this.lab = this.srgba.toLAB();
        this.lch = this.lab.toLCH();
    }

    generate() {
        const _space = this.hsv || this.hsl || this.lab;
        if (this.hex) {
            this.srgba = sRGBAColor.fromHEX(this.hex);
        } else if (_space) {
            this.srgba = _space.tosRGBA();
        } else if (this.lch) {
            this.srgba = this.lch.toLAB().tosRGBA();
        }
        this.__calculateFromsRGBA();
    }

    opaque() {
        return this.srgba.opaque();
    }

    isOpaque() {
        return this.srgba.isOpaque();
    }

    relativeLuminance() {
        return this.srgba.relativeLuminance();
    }

    WCAGContrastRatio(background) {
        return this.srgba.WCAGContrastRatio(background)
    }

    isLight(minimumContrast) {
        return this.srgba.isLight(minimumContrast);
    }

    rotate(arg) {
        const degrees = typeof arg === 'number' ? arg : arg.d;
        const hsl = arg?.hsl;

        if (hsl) {
            return new Color({
                hsl: this.hsl.rotate(degrees).args
            })
        }
        return new Color({ lch: this.lch.rotate(degrees).args });
    }

    triadic({ hsl } = {}) {
        return [this.rotate({ d: 60, hsl }), this.rotate({ d: 120, hsl })]
    }

    complementary({ hsl } = {}) {
        return {
            secondary: this.rotate({ d: 180, hsl }),
            accents: [this.rotate({ d: 90, hsl }), this.rotate({ d: -90, hsl })]
        }
    }

    splitComplementary({ distance = 30, hsl } = {}) {
        return [
            this.rotate({ d: 180 - distance / 2, hsl }),
            this.rotate({ d: 180 + distance / 2, hsl })
        ]
    }

    analogous({ distance = 30, hsl } = {}) {
        return [
            this.rotate({ d: -distance / 2, hsl }),
            this.rotate({ d: distance / 2, hsl })
        ]
    }

    trapezoid({ distance = 30, hsl } = {}) {
        return [{
            secondary: this.rotate({ d: distance / 2, hsl }),
            accent: this.rotate({ d: distance / 2 + 180, hsl })
        }, {
            secondary: this.rotate({ d: -distance / 2, hsl }),
            accent: this.rotate({ d: -distance / 2 + 180, hsl })
        }]
    }

    overlay(background, opacity = 1) {
        return new Color({
            srgba: this.srgba.overlay(background.srgba, opacity).args
        })
    }

    static triadicHSL(color) {
        const colors = color.triadic({ hsl: true });
        colors.sort((c1, c2) => c1.hsv.v - c2.hsv.v)
        return [[
            { color: colors[0], name: 'secondary' },
            { color: colors[1], name: 'accent' },
        ]]
    }

    toRGBValues() {
        if (!/^[a-fA-F0-9]{3,8}$/.test(this.hex)) throw Error('Invalid hex color string: ' + this.hex);
        if (3 === this.hex.length || 4 === this.hex.length) {
            var b = /^(.)(.)(.)(.)?$/.exec(this.hex).slice(1, 5).map(function (e) {
                return e ?
                    e + e : 'ff'
            });
        } else if (6 === this.hex.length || 8 === this.hex.length) {
            b = /^(..)(..)(..)(..)?$/.exec(this.hex).slice(1, 5),
                void 0 === b[3] && (b[3] = 'ff');
        } else {
            throw Error('Invalid hex color string: ' + this.hex);
        }
        var red = toInt(b[0]);
        var green = toInt(b[1]);
        var blue = toInt(b[2]);
        return `${red}, ${green}, ${blue}`
    }
}