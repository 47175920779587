import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { ScrollView, Keyboard, Platform } from 'react-native';
import { flatStyle } from '@utils/helpers';

export const KeyboardSafeScrollView = forwardRef(({ children, contentContainerStyle, ...props }, ref) => {
    const flattenContentContainerStyle = flatStyle(contentContainerStyle);
    const _paddingBottom = flattenContentContainerStyle.paddingBottom ? flattenContentContainerStyle.paddingBottom
        : flattenContentContainerStyle.padding ? flattenContentContainerStyle.padding : 0;

    const _paddingTop = flattenContentContainerStyle.paddingTop ? flattenContentContainerStyle.paddingTop
        : flattenContentContainerStyle.padding ? flattenContentContainerStyle.padding : 0;

    const scrollRef = useRef();
    const scrollPosition = useRef(0);
    const scrollLayout = useRef({});
    const [padding, setPadding] = useState(_paddingBottom);

    useImperativeHandle(ref, () => ({
        ...scrollRef.current,
        scrollToInput: (inputMeasures) => {
            const y = Math.max(0, scrollPosition.current + inputMeasures.y - scrollLayout.current.height / 2 - _paddingTop / 2);
            scrollRef.current.scrollTo({ y, animated: true });
            scrollPosition.current = y;
        }
    }))

    useEffect(() => {
        const hideEvent = 'keyboardWillHide';
        const showEvent = 'keyboardWillShow';

        Keyboard.addListener(showEvent, _keyboardDidShow);
        Keyboard.addListener(hideEvent, _keyboardDidHide);

        // cleanup function
        return () => {
            Keyboard.removeListener(showEvent, _keyboardDidShow);
            Keyboard.removeListener(hideEvent, _keyboardDidHide);
        };
    }, []);

    const _keyboardDidHide = ({ startCoordinates }) => {
        if (Platform.OS === 'android') {
            setPadding(0);
        }
        if (Platform.OS === 'ios') {
            const y = Math.max(0, scrollPosition.current - startCoordinates.height);
            scrollRef.current.scrollTo({ y, animated: true });
            scrollPosition.current = y;
        }
        // if (ref) ref.current.scrollToEnd()
    };

    const _keyboardDidShow = ({ endCoordinates }) => {
        if (Platform.OS === 'android') {
            setPadding(endCoordinates.height);
        }
    }

    const onMomentumScrollEnd = ({ nativeEvent }) => {
        scrollPosition.current = nativeEvent.contentOffset.y;
        if (props.onMomentumScrollEnd) props.onMomentumScrollEnd();
    }

    const onLayout = ({ nativeEvent }) => {
        scrollLayout.current = nativeEvent.layout;
        if (props.onLayout) props.onLayout();
    }

    return (
        <ScrollView
            {...props}
            ref={scrollRef}
            onMomentumScrollEnd={onMomentumScrollEnd}
            onLayout={onLayout}
            scrollToOverflowEnabled
            contentContainerStyle={[contentContainerStyle, { paddingBottom: padding }]}
        >
            {children}
        </ScrollView>
    )
})