import React, { useCallback, useState, useRef, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Snackbar } from './modal-types';

const SnackbarContext = React.createContext({
    pushSnack: () => { },
});

// Use this interface only when necessary
const snackbarProviderRef = React.createRef();
export const _pushSnack = (args) => {
    snackbarProviderRef.current.pushSnack(args);
}

export const SnackbarProvider = (props) => {
    const [queueTimeout, setQueueTimeout] = useState([]);
    const queue = useRef([]);
    const totalSnacks = useRef(0);

    const durationByText = (text) => Math.min(Math.max(Snackbar.SHORT, text.length * 50), Snackbar.LONG);

    const defaultSnackbar = {
        pushSnack: (snack) => {
            // console.log('#0# Called pushSnack');
            let newSnack = { ...snack, id: totalSnacks.current };
            if (typeof snack === 'string' || snack instanceof String) {
                newSnack = {
                    text: snack,
                    id: totalSnacks.current
                }
            }
            setQueueTimeout([...queueTimeout, newSnack])
            queue.current.push(newSnack)
            totalSnacks.current++;
        },
    };

    const popSnack = (duration, callback) => {
        // console.log('#3# Called popSnack')
        setTimeout(() => {
            // console.log('#4# Finished duration')
            callback(() => {
                // console.log('#7# Going to unmount');
                const [first, ...rest] = queue.current;
                queue.current = rest;
                setQueueTimeout(rest);
            })
        }, duration)
    }

    useEffect(() => {
        snackbarProviderRef.current = { ...defaultSnackbar };
    }, [])

    return (
        <SnackbarContext.Provider value={defaultSnackbar}>
            {props.children}
            <View pointerEvents="box-none" style={[StyleSheet.absoluteFill, { justifyContent: 'flex-start', top: Snackbar.MARGINS }]}>
                {!!queueTimeout.length &&
                    <Snackbar
                        onDurationEnd={(callback) => { popSnack(queueTimeout[0].duration ?? durationByText(queueTimeout[0].text), callback) }}
                        icon={queueTimeout[0].icon}
                        text={queueTimeout[0].text}
                        id={queueTimeout[0].id}
                        type={queueTimeout[0].type}
                        style={{ marginHorizontal: Snackbar.MARGINS, alignSelf: 'center' }} />
                }
            </View>
        </SnackbarContext.Provider>
    );
};


export const useSnackbar = () => React.useContext(SnackbarContext);
export default SnackbarProvider;