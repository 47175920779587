import { Mixins, Spacing } from "@styles";
import { StyleSheet, Platform, Dimensions } from "react-native";
import { Button } from "@atoms";

export const { height: dHeight, width: dWidth } = Dimensions.get('window');

export const cartMenuWidth = Math.max(320, dWidth/4);

export const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
    },
    topBar: {
        height: 48,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    navBarButton: {
        height: 40,
        ...Mixins.rounding(40),
        marginLeft: Spacing.M,
        alignItems: 'center',
        justifyContent: 'center',
        ...Platform.select({
            native: {}, default: {
                userSelect: 'none'
            }
        }),
        paddingHorizontal: Spacing.M
    },
    noSelect: {
        ...Platform.select({
            native: {},
            default: {
                userSelect: 'none'
            }
        })
    },
    profilePic: {
        width: 40,
        height: 40,
        ...Mixins.rounding(40)
    },
    squaredButton: {
        width: Button.HEIGHT, paddingHorizontal: 0,
    },
    squaredCondensedButton: {
        width: Button.CONDENSED, paddingHorizontal: 0
    },
    noOutline: {
        ...Platform.select({
            native: {},
            default: {
                outlineStyle: 'none'
            }
        })
    }
})