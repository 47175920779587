import { snakeToCamel, refitKeys, applyMap, projection, mapObjectValues, objectMap, cleanObject } from "@utils/helpers";
import { isEqual } from "lodash";
const tracePrototypeChainOf = (object) => {

    var proto = object.constructor.prototype;
    var result = '';

    while (proto) {
        result += ' -> ' + proto.constructor.name;
        proto = Object.getPrototypeOf(proto)
    }

    return result;
}


export class Model {
    static get uuid() { return ''; }
    static get keymap() { return {}; }
    static get model() { return {}; }
    static get unserialMap() { return {}; }
    static get toClassMap() { return {} ; }
    static get parseMap() { return {}; }
    static get parseCallback() { return (parsed) => parsed };
    static get transform() { return snakeToCamel; }
    static parse(object) {
        // Transform the keys of the object, to adapt it to our naming conventions
        const refitted = refitKeys(object, this.keymap, this.transform, false);
        const allKeys = new Set([...Object.keys(this.model), ...Object.keys(refitted)]);

        // If the object is minified, save which fields are present and are going to
        // be changed
        cleanObject(refitted); // This cleans nulls from the object
        const _deltas = Object.keys(refitted);

        // Transform the values accordingly with the model default fields and the necessary mappings
        // defined in parseMap
        const parsed = {}
        for (const k of allKeys) {
            // If  the value is valid, parse it if necessary, if not fill it with the default value
            // The parse function supposes that the key is the same and it returns corresponding value.
            // However, it can also return a list of [key, value] to add them to the parsed
            // item.

            // If the value is already in parsed maybe it has been overwritten so we skip it.
            if (k in parsed) continue;

            // If the value is invalid use the default value
            if (refitted[k] === undefined || refitted[k] === null) {
                parsed[k] = this.model[k]
            } else {
                // If the value does not have a parser function, add it
                if (!(k in this.parseMap)) {
                    parsed[k] = refitted[k]
                } else {
                    // If the return of the parser is the value, add it
                    if (!this.parseMap[k].isTransformation) {
                        parsed[k] = this.parseMap[k](refitted[k])
                    } else {
                        // The return is a list of key, values
                        Object.assign(parsed, ...this.parseMap[k](refitted[k]))
                    }
                }
            }
        }

        const projectionKeys = Object.keys(this.model)
        if (this.uuid !== '') projectionKeys.push(this.uuid);
        // Add minified flag to projectionKeys
        projectionKeys.push('minified');
        
        const projected = projection(this.parseCallback(parsed), projectionKeys, this.model);

        // If the object is minified, save which keys have to be changed when saved.
        if (projected.minified) {
            projected._deltas = _deltas;
        }

        return projected;
    }

    static unserialize(object, config = { toClass: false }) {
        return applyMap(object, {
            ...this.parseMap,
            ...this.unserialMap,
            ...(config.toClass ? this.toClassMap : {})
        })

    }

    static _wrapParseMapFunction(fn) { fn.isTransformation = true; return fn };

    static object (instance) {
        // If instance is indeed an instance return the inside object
        if ( instance instanceof Model ) return instance.object;

        // If instance is not an object return it
        if (!instance || typeof instance !== 'object') return instance;

        // If instance is an array recurse the map
        if (Array.isArray(instance)) return instance.map(i => Model.object(i))
        
        // If instance is not plain object return it
        if (instance.constructor.name !== 'Object') return instance;

        // Recurse on object
        const ret = {};
        for (const k in instance) {
            ret[k] = Model.object(instance[k]);
        }

        return ret;

    }

    static asArray (instance) {
        // console.log('MODEEEEEEEEEEEEEEEEEEL')
        const arr = []
        // If instance is indeed an instance return the inside object
        if (instance instanceof Model) return instance.asArray;

        // If instance is not an object return it
        if (!instance || typeof instance !== 'object') return instance;

        // If instance is an array recurse the map
        if (Array.isArray(instance)) return instance.map(i => Model.asArray(i))

        // If the instance is a collection of instances transform it to a list
        if ( instance.constructor.name === 'Object'
            && !(instance instanceof Model)
            && Object.keys(instance).every(key => typeof(instance[key]) === 'object' && 'asArray' in instance[key])
            ) {
            Object.keys(instance).forEach( key => {
                if ( instance[key].constructor.uuid && instance[key].constructor.uuid !== '') {
                    arr.push({
                        ...instance[key].asArray,
                        [instance[key].constructor.uuid]: key
                    });
                } else {
                    arr.push(instance[key].asArray)
                }
            })
            return arr;

        }

        // If instance is not plain object return it
        if (instance.constructor.name !== 'Object') return instance;

        const ret = {};
        for (const k in instance) {
            // console.log('Model:', 'calling asArray with:', k);
            ret[k] = Model.asArray(instance[k])
        }
        return ret;
    }
    
    static collectionToArray(collection) {
        return Object.keys(collection).map(k => collection[k]);
    }

    get object() {
        const obj = {}

        for (const k in this) {
            if (typeof (this[k]) === 'object' && Array.isArray(this[k])) {
                obj[k] = this[k].map(content => content instanceof Model ? content.object : Model.object(content))
            } else if (typeof (this[k]) === 'object' && this[k] instanceof Model) {
                obj[k] = this[k].object
            } else {
                obj[k] = Model.object(this[k])
            }
        }

        return { ...obj }
    }

    get asArray() {
        const obj = {}
        for (const k in this) {
            // if (this[k]) console.log(this[k].constructor, k)
            if (typeof (this[k]) === 'object' && Array.isArray(this[k])) {
                obj[k] = this[k].map(content => !!content && content instanceof Model ? content.asArray : Model.asArray(content))
            } else if (typeof (this[k]) === 'object' && !!this[k] && this[k] instanceof Model) {
                obj[k] = this[k].asArray
            } else {
                // console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCc', k)
                obj[k] = Model.asArray(this[k])
            }
        }

        return { ...obj }
    }

    static parseArray(arr) {
        return objectMap(arr.map(item => this.parse(item)), this.uuid);
    }
    
    static shouldFetch({ object, ignoreCache }) {
        // console.log('111111111111',object.didInvalidate, ignoreCache, object.isFetching)
        if (!object) return true;
        if (object.isFetching) return false;
        if (object.minified) return true;
        if (ignoreCache) return object.didInvalidate;
        return object.didInvalidate && (Date.now() - object.lastUpdated > this.CACHE);
    }

    static isDefault(obj) {
        return isEqual(obj, this.model);
    }

    static rehydrate (collection, model) {
        return mapObjectValues(collection, entity => model.unserialize(entity));
    }

    static toReducer(obj) {
        if (!obj.minified) {
            return obj;
        }
        
        const minifiedObj = {};
        for (const key of obj._deltas ) {
            minifiedObj[key] = obj[key];
        }

        return minifiedObj;
    }
}