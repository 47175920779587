import React, { useState, useEffect } from 'react';
import { View } from 'react-native';

export const SmartWrapView = ({ flexDirection, crossDirectionSize, style, children, ...props}) => {
    const [layout, setLayout] = useState({});
    const [childrenLines, setChildrenLines ] = useState([]);
    const [updatedStyles, setUpdatedStyles] = useState([]);

    useEffect(() => {
        const finalLine = Math.max(...childrenLines);
        setUpdatedStyles( childrenLines.map( l =>  l === finalLine ? ({ flexGrow: 0 }) : ({ flexGrow: 1 }) ))
    }, [layout])

    const onLayout = ({nativeEvent}) => {
        setLayout(nativeEvent.layout);
    }

    const findChildLine = (i) => ({nativeEvent}) => {
        const currentLines = [...childrenLines];
        if ( flexDirection === 'row' ) {
            currentLines[i] = Math.round(nativeEvent.layout.y / crossDirectionSize)
            setChildrenLines(currentLines);
        } else {
            currentLines[i] = Math.round(nativeEvent.layout.x / crossDirectionSize)
            setChildrenLines(currentLines);
        }
    }

    const childrenArray = React.Children.toArray(children);
    const layoutedChildren = childrenArray.map((child, i) => React.cloneElement(child, { onLayout: findChildLine(i), style: [child.props.style, updatedStyles[i]] }) )
    
    return (
        <View style={[style, { flexDirection, flexWrap: 'wrap' }]} onLayout={onLayout}>
            {layoutedChildren}
        </View>
    )
}