import React, { useState, useMemo, useRef, useEffect } from 'react';
import { ScrollView, Text, View, Animated, Pressable, ActivityIndicator, StyleSheet } from 'react-native';
import { Typography, Mixins, Icon, Spacing, Colors } from '@styles';

import { TextInput, Button } from '@atoms';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { Orders } from '@services';
import Animation from '@utils/animations';
import { markOrderAsPaid, hardLogOut, setLastMenu } from '@actions';
import { parsePrice, parseDiscount, findFinalTotal } from '@utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useModal, _pushSnack } from '@components/lib';


import { Trackings } from '@services';
const modalTracker = new Trackings.Tracker('PaymentModal');


export const PaymentModal = ({ _dismissModal, mode, total, style, orderId, refreshMenu, callback=() => {}, ...props }) => {
    const { translate } = useLanguage();
    const { useElevation, useColor } = useTheme();
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const animation = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(animation, Animation.create(1, 100)).start();
    }, [])

    const [title, line, body, primary] = useColor(['title', 'line', 'body', 'primary']);
    const elevation = useElevation(12);

    const modalBody = {
        icon: mode === 'generic' ? 'cash' : mode,
        text: translate(mode + 'PaymentConfirmation', { total: parsePrice(total, '€')})
    }

    const findChange = () => {
        let numericValue = parseFloat(value.replace(',', '.')) || 0;
        return numericValue*100 - total;
    }

    const markAsPaidPress = () => {
        modalTracker.tap('MarkAsPaidButton', {
            orderId
        })
        dispatch(markOrderAsPaid(orderId, callback));
        _dismissModal();
    }

    const cancelPress = () => {
        try {
            Orders.cancel(orderId);
            modalTracker.tap('CancelButton', {
                orderId
            })
        } catch (error) {
            if (error.statusCode === 403 || error.statusCode === 401) {
                dispatch(hardLogOut);
            }
        }
        _dismissModal();
    }

    return (
        <Animated.View style={[style, {
            opacity: animation,
            padding: Spacing.M,
            transform: [{ scale: animation.interpolate(Animation.keyframes(.8, 1)) }],
            overflow: 'hidden',
            backgroundColor: elevation.backgroundColor,
            ...Mixins.matchRounding(Mixins.rounding(36), Spacing.M),
        }]}>

            <Text style={[Typography.H6, {textAlign: 'center', color: title}]}>{translate('paymentConfirmation')}</Text>
            
            <Icon size={48} style={{ alignSelf: 'center' }} color={primary} name={modalBody.icon}/>
            
            <View style={{ marginHorizontal: Spacing.H }}>
                <Text style={[Typography.TITLE, { textAlign: 'center', marginTop: Spacing.M}]}>
                    <Text style={{ color: title }}>{translate('theTotalIs')} </Text>
                    <Text style={{ color: primary }}>{parsePrice(total, '€')}</Text>
                </Text>
                <Text style={[Typography.BODY, { color: body, marginVertical: Spacing.M}]}>{modalBody.text}</Text>

                { mode === 'cash' &&
                    <>
                    
                    <TextInput
                        keyboardType="numeric"
                        label={translate('paidWith')+":"}
                        prefix="€"
                        information={translate('eg') + ': 20.50'}
                        style={{ alignSelf: 'stretch'}} value={value} setValue={setValue}/>
                    
                    { findChange() > 0 &&
                        <Text style={[Typography.TITLE, { marginTop: Spacing.XS, textAlign: 'center' }]}>
                            <Text style={{color: title }}>{translate('change')}: </Text>
                            <Text style={{color: primary }}>{parsePrice(findChange(), '€')}</Text>
                        </Text>
                    }
                    </>
                }
            </View>

            <View style={Mixins.HORIZONTAL}>
                <Button
                    color="destructive"
                    type="transparent"
                    style={{ marginTop: Spacing.XL, flex: 1, marginRight: Spacing.XS }}
                    icon="close-alt"
                    title={translate('cancel')}
                    onPress={cancelPress} />
                <Button
                    style={{ marginTop: Spacing.XL, flex: 1, marginLeft: Spacing.XS }}
                    icon="check-alt"
                    title={translate('markAsPaid')}
                    onPress={markAsPaidPress} />
            </View>
        </Animated.View>
    )

}

export const PaymentMethodModal = ({ _dismissModal, style, discount, totalPrice, serverCart, deleteOrder, printOrder, refreshMenu, callback = () => { }, ...props }) => {
    const dispatch = useDispatch();
    const orderRef = useRef();
    const createMutexRef = useRef(1);
    const { translate } = useLanguage();
    const { pushModal } = useModal();
    const { useElevation, useColor } = useTheme();
    const animation = useRef(new Animated.Value(0)).current;
    const pickupBar = useSelector(state => state.pickupBars[state.authentication.pickupBarId]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Animated.timing(animation, Animation.create(1, 100)).start();
    }, []);

    const [primary] = useColor(['primary']);
    const elevation = useElevation(12);

    const buttons = ['cash', 'credit-card'];

    const onMarkAsPaid = (order) => {
        deleteOrder();
        printOrder( order );
    }

    const onPress = async (bcode) => {

        /**
         * TODO: Service type select logic, change this when is redefined
         */
        
        try {
            if ( !createMutexRef.current ) return;

            setLoading(true);
            createMutexRef.current = 0;

            const serviceType = pickupBar.hasPreparedPickup ? 'prepared' : 'standard';
            const response = await Orders.create({ ...serverCart, amountOff: parseDiscount(discount), serviceType });

            if (response.status !== 'ok') {
                _pushSnack({
                    type: 'warning',
                    icon: 'warning',
                    text: translate('somethingWentWrong')
                });
                return;
            }

            orderRef.current = response.order;

            pushModal({
                dismissOnOverlayPress: false,
                style: {
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                children: <PaymentModal callback={onMarkAsPaid} orderId={orderRef.current.orderId} style={{ maxWidth: 500 }} mode={bcode} total={findFinalTotal(totalPrice, discount)} />
            })

            createMutexRef.current = 1;
            setLoading(false);
            _dismissModal();

        } catch (error) {
            switch (error.mainError) {
                case 'out_of_stock':
                    _pushSnack({
                        type: 'warning',
                        icon: 'warning',
                        text: translate('somethingOutOfStock')
                    })
                    refreshMenu();
                    break;
                    
                case 'menu_sales_not_available':
                    _pushSnack({
                        type: 'warning',
                        icon: 'warning',
                        text: translate('salesAreNotAvailable')
                    })
                    break;
                    
                default:
                    _pushSnack({
                        type: 'warning',
                        icon: 'warning',
                        text: translate('somethingWentWrong')
                    })
            }
            

            if (error.statusCode === 403 || error.statusCode === 401) {
                dispatch(hardLogOut);
            }
            
            createMutexRef.current = 1;
            setLoading(false);
            _dismissModal();
        }
    }

    return (
        <Animated.View style={[style, {
            opacity: animation,
            padding: Spacing.M,
            transform: [{ scale: animation.interpolate(Animation.keyframes(.8, 1)) }],
            overflow: 'hidden',
            backgroundColor: elevation.backgroundColor,
            ...Mixins.matchRounding(Mixins.rounding(36), Spacing.M),
        }]}>


            <View style={[style, Mixins.HORIZONTAL]}>
                {buttons.map((bcode, i) => (
                    <Pressable onPress={() => onPress(bcode)} key={bcode} style={[{padding: Spacing.XL}, i === 0 ? { marginLeft: 0 } : {}]}>
                        <Icon name={bcode} size={64} color={primary} />
                        <Text style={[Typography.TITLE, { color: primary }]}>{translate(bcode)}</Text>
                    </Pressable>
                ))}
            </View>

            { loading && <View style={[StyleSheet.absoluteFill, { justifyContent:'center', backgroundColor: Colors.setOpacity('#000000', .2)}]}>
                <ActivityIndicator color={primary} size="large"/>
            </View>}
        </Animated.View>
    )
}