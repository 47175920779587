const manualOrders = {
    cash: 'Cash',
    "credit-card": "Card",
    "wristband-rfid": "Wristband",
    addToCart: 'Add to cart',
    paymentMethod: 'Payment method',
    history: 'History',
    settings: 'Settings',
    currentOrder: 'Current order',
    logOut: 'Log out',
    pay: 'Pay',
    saveAsADraft: 'Save as a draft',
    deleteOrder: 'Delete order',
    order: 'Order',
    paymentConfirmation: 'Payment confirmation',
    cancel: 'Cancel',
    markAsPaid: 'Mark as paid',
    theTotalIs: 'The total is',
    cashPaymentConfirmation: 'You can use the field below to find out the change. Remember to mark the order as paid when done.',
    "wristband-rfidPaymentConfirmation": 'Tell the customer to bring their wristband close to the sensor.',
    "credit-cardPaymentConfirmation": 'Please, proceed with your POS and mark the order as paid when done.',
    "genericPaymentConfirmation": 'Please, proceed to request your customer payment. Remember to mark the order as paid when done. ',
    change: 'Change',
    eg: 'eg',
    paidWith: 'Paid with',
    selectAPaymentMethod: 'Please, select a payment method',
    somethingWentWrong: 'Something went wrong',
    markedAsPaid: 'Marked as paid',
    "applyADiscount?": 'Apply a discount?',
    discount: 'Discount',
    free: 'Free',
    draftOrders: 'Draft orders',
    loadedOrder: 'Loaded order',
    in_progress: 'Draft',
    processing: 'Processing',
    on_going: 'On going',
    completed: 'Completed',
    succeeded: 'Received',
    invalid: 'Invalid',
    refunded: 'Refunded',
    transferred: 'Transferred',
    collapse: 'Collapse',
    selectYourBar: 'Select your bar',
    readCodes: 'Validated codes',
    individualProducts: 'Individual products',
    continue: 'Continue',
    useTheseQRCodes: 'Please, read these QR Codes to set up the QR Reader.',
    refreshApp: 'Refresh app',
    soldOut: 'Sold out',
    packs: 'Packs',
    selectOneOption: 'Please, select one option',
    edit: 'Edit',
    remove: 'Remove',
}

const grill = {
    grill: 'Grill',
    pending: 'Pending',
    preparing: 'Preparing',
    ready: 'Ready',
    served: 'Served',
    moveTo: 'Move to',
    close: 'Close',
    deactivateSales: 'Turn off sales',
    activateSales: 'Turn on sales',
    salesAreActive: 'Sales are active',
    salesAreDeactive: 'Sales are not active',
    deactivateSalesExplanation: 'Sales will be turned off. The customers won\'t be able to purchase until they are active again.',
    activateSalesExplanation: 'Sales will be turned on. The customers will be able to purchase online.',
    deliverTo: 'Deliver to',
    printTicket: 'Print ticket',
    markAsServed: 'Mark as served',
    orderServed: 'Order served',
    editStock: 'Edit stock',
    finishEditingStock: 'Finish editing stock',
    inStock: 'In stock',
    stockEdited: 'Stock edited',
    printGrillTicket: 'Print grill ticket',
    printGrillTicketExplanation: 'Do you want to print a kitchen ticket?',
    printCustomerTicket: 'Print customer ticket',
    printCustomerTicketExplanation: 'Do you want to print a customer ticket?',
    print: 'Print',
    moveToPreparing: 'Move to preparing',
    summary: 'Summary',
    batchReady: 'Batch ready',
    nextBatch: 'Next batch',
    pendingOrders: 'Pending orders',
    app: 'App',
    ticket: 'Ticket',
    noPreparation: 'No preparation needed',
    noPendingOrdersExplanation: "There aren't orders to prepare"
}

const validationErrors = {
    invalid: "Invalid code",
    incorrect_place: "This code does not belong to this place",
    invalid_status: "Order processing hasn't finished yet",
    invalid_service_type: "This order is not meant to be picked up",
    incorrect_pickupbar: "This order belongs to a different bar",
    already_served: "Order already served",
    V102: 'Invalid code',
    already_verified: "Code has been used before",
    wrong_preparation_group: "This bar does not give this order",
    somethingOutOfStock: "There's something out of stock",
    salesAreNotAvailable: 'Sales are not available',
    connectionError: 'Connection error',
    invalid_token: 'Invalid token',
    already_used: 'Code already used',
    thisMenuIsOutdated: 'This menu is outdated'
}

const dates = {
    "today": "Today",
    "yesterday": "Yesterday",
    "tomorrow": "Tomorrow",
    "twoDaysAgo": "Two days ago",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "theDay": "On {{day}}",
    "thePastDay": "Last {{day}}",
    "theFutureDay": "Next {{day}}",
    "midString": "{{weekday}}, {{month}} {{date}}",
    "january": "January",
    "february": "February",
    "march": "March",
    "april": "April",
    "may": "May",
    "june": "June",
    "july": "July",
    "august": "August",
    "september": "September",
    "october": "October",
    "november": "November",
    "december": "December",
    "momentAgo": "A while ago",
    "weekAbbreviation": "w",
    "year": "year",
    "years": "years",
    "longDate": "{{date}} of {{month}} of {{fullYear}}",
    "mondayAbbreviation": "Mon",
    "tuesdayAbbreviation": "Tue",
    "wednesdayAbbreviation": "Wed",
    "thursdayAbbreviation": "Thu",
    "fridayAbbreviation": "Fri",
    "saturdayAbbreviation": "Sat",
    "sundayAbbreviation": "Sun",
    "mondayLetter": "M",
    "tuesdayLetter": "T",
    "wednesdayLetter": "W",
    "thursdayLetter": "T",
    "fridayLetter": "F",
    "saturdayLetter": "S",
    "sundayLetter": "S",
};


const promotions = {
    allVenues: 'All venues',
    spending: 'Spending',
    usesLeft: '{{uses}} left',
    promotions: 'Promotions',
    promo: 'Promo',
    discountOff: '{{discount}} off',
    couponAvailable: 'Coupon available',
    'haveACode?': 'Have a code?',
    'haveADiscount?': 'Have a discount?',
    addDiscount: 'Add discount',
    addItHere: 'Add it here',
    discountCode: 'Discount code',
    send: 'Send',
    invalidCode: 'Invalid code',
    promotionAdded: 'Promotion added',
    discounts: 'Discounts',
    subtotal: 'Subtotal'
}

const settings = {
    printSettings: 'Print settings',
    printModeLabel: 'Print mode',
    printModeSystem: 'System dialog',
    printModeESCPOS: 'Automatic',
    paperWidth: 'Paper width',
};

const englishTranslateJSON = {
    ...manualOrders,
    ...grill,
    ...dates,
    ...validationErrors,
    ...promotions,
    ...settings
};

export default englishTranslateJSON;