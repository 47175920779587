import { Platform } from 'react-native';
import { festoColors } from './colors';

export const radius = (borderRadius) => {
    if (borderRadius.length > 1) {
        return ({
            borderTopLeftRadius: borderRadius[0],
            borderTopRightRadius: borderRadius[1],
            borderBottomRightRadius: borderRadius.length > 2 ? borderRadius[2] : borderRadius[0],
            borderBottomLeftRadius: borderRadius.length > 3 ? borderRadius[3] : borderRadius[1]
        });
    }

    return { borderRadius };
};

export const rounding = (value, extended=false) => {
    let r;
    if (value < 8) r = 1;
    else if (value < 12) r = 2;
    else if (value < 20) r = 4;
    else if (value < 28) r = 8;
    else if (value < 36) r = 12;
    else if (value < 80) r = 16;
    else if (extended) {
        r = Math.min(40, 8 + Math.floor(value/4));
    }
    else r = 28;
    return { borderRadius: r };
};

export const matchRounding = (rounding, delta) => {
    if ( 'borderRadius' in rounding ) {
        return ({ borderRadius: rounding.borderRadius + delta });
    }

    for ( const property of rounding ) {
        rounding[property] += delta;
    }

    return rounding;
};

export const elevation = (elevation, iOScolor='#000') => {
    const radius = [1.00,  1.00,  1.41,  2.22,  2.62,
                    3.84,  4.65,  4.65,  4.65,  5.46,
                    6.27,  6.68,  7.49,  8.30,  9.11,
                    9.51,  10.32, 11.14, 11.95, 12.35,
                    13.16, 13.97, 14.78, 15.19, 16.00];

    return {
        shadowColor: iOScolor,
        shadowOffset: {
            width: 0,
            height: Math.max(Math.floor(elevation / 2), 1)
        },
        shadowOpacity: 0.18 + (elevation - 1) * 0.02 - (Math.floor(elevation / 4) * 0.01),
        shadowRadius: radius[elevation],
        elevation
    }
}

export const getElevationProps = (isDark) => (elevationNum=0, iOScolor='#000') => {
    if (isDark) {
        if (elevationNum in festoColors.darkElevation)
            return { backgroundColor: festoColors.darkElevation[elevationNum] }

        const minDelta = Object.keys(festoColors.darkElevation).map(n => (n - elevationNum)).sort((n, _) => n - 0)[0]
        return { backgroundColor: festoColors.darkElevation[elevationNum+minDelta]}
    }
    return {...elevation(elevationNum, iOScolor), backgroundColor: '#ffffff'};
}

export const REDFRAME = __DEV__ ? { borderWidth: 1, borderColor: 'red' } : {};

export const MODAL = {
    justifyContent: 'center', marginTop: 'auto', marginBottom: 'auto',
};

export const HORIZONTAL = { flexDirection: 'row' };

export const NO_SELECT = Platform.select({
    native: {},
    default: {
        userSelect: 'none'
    }
})