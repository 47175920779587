import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';

const pad2 = n => (n < 10 ? '0' : '') + n;

const msToString = (ms) => {
    const h = Math.floor((ms / (1000 * 60 * 60)) % 24);
    const m = Math.floor((ms / 1000 / 60) % 60);
    const s = Math.floor((ms / 1000) % 60);
    return `${pad2(h)}:${pad2(m)}:${pad2(s)}`
}

export const CountdownTimer = ({start=Date.now(), end, style,...props}) => {
    const [timeLeft, setTimeLeft] = useState(end-start);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(Math.max(0, end-start-1));
        }, 1000)

        return () => clearTimeout(timer);
    });
    return (
        <Text {...props} style={[ style, { opacity: timeLeft ? 1 : 0 }]}>{msToString(timeLeft)}</Text>
    )
}

export const CountupTimer = ({ start=Date.now(), style, ...props}) => {
    const [timePassed, setTimePassed] = useState(Date.now() - start);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimePassed(Math.max(0, Date.now() - start - 1));
        }, 1000)

        return () => clearTimeout(timer);
    });

    return (
        <Text {...props} style={[style, { opacity: timePassed ? 1 : 1 }]}>{msToString(timePassed)}</Text>
    )
}