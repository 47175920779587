import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { Typography, Spacing, Mixins, Colors, Icon } from '@styles';
import { SmartWrapView } from '@lib';



export const BaseTag = ({type='border', displayText='', icon, color='#000000', titleColor, style, dense,...props}) => {
    const { useColor, isDark } = useTheme();
    const [textOnColor] = useColor([[ 'title', {on: color }]]);

    const containerStyle = type === 'filled' ? 
        ({
            backgroundColor: color,
        }) :
        type === 'transparent' ?
        ({
            backgroundColor: Colors.setOpacity(color, isDark ? .3 : .15),
        }) :
        ({
            borderWidth: 1,
            borderColor: color,

        })

    const textStyle = type === 'filled' ?
        ({
            color: titleColor || textOnColor,
        }) :
        ({
            color,
        })

    return (
        <View style={[Mixins.HORIZONTAL, styles.baseTagContainer, containerStyle, style]} {...props}>
            {
                icon &&
                <Icon name={icon} color={textStyle.color} size={dense ? Typography.SMALL_COPY.lineHeight : Typography.BODY.lineHeight} style={{ marginRight: (icon && displayText.length > 0) ? Spacing.XS : 0 }}/>
            }
            <Text style={[ dense ? Typography.SMALL_COPY : Typography.BODY, textStyle]}>{displayText}</Text>
        </View>
    )
}

BaseTag.HEIGHT = 28;

BaseTag.propTypes = {
    /**
     * This is the text the tag will display
     */
    displayText: PropTypes.string,
    /**
     * The type of the tag
     */
    type: PropTypes.oneOf(['border', 'filled', 'transparent']),
    /**
     * The color in HEX format
     */
    color: PropTypes.string,
    /**
     * The icon name if the Tag has one
     */
    icon: PropTypes.string,
}

BaseTag.defaultProps = {
    title: '',
    type: 'border',
    color: '#000000',
}


const _codeToIcon = {
    pub: 'pub', bar: 'bar', club: 'club', festival: 'festival', music_venue: 'musical-note'
}
export const GeneralTag = ({ tag={}, dense, icon, displayText, style, color, type,...props }) => {
    const { translate } = useLanguage();
    const { useColor, isDark } = useTheme();
    const [ primary, tagColor, pub, club, bar, festival, concert, titleColor ] = useColor([
        'primary',
        tag.mainColor,
        'Crayola Blue',
        ['Shocking Pink', { reverse: isDark }],
        ['Honey Yellow', { reverse: !isDark }],
        ['Sea Green', { reverse: isDark }],
        ['Electric Purple', { reverse: isDark }],
        ['title', {reverse: true }]
    ]);

    const tagPriceProps = (tag) => {
        let icon;

        if ( tag.code === 'ticket_price_from' ) {
            icon = 'ticket';
            color = club;
        } else if (
            tag.code === 'copa_nacional_price'
            || tag.code === 'copa_importacion_price'
            || tag.code === 'drink_price'
        ) {
            icon = 'pub';
            color = pub;
        } else {
            icon = 'bar';
            color = bar;
        }
        

        return ({
            icon: icon,
            displayText: tag.numericValue == '0' ? translate('free') : tag.numericValue == '-1' ? translate('soldOut') : dense ? `${tag.numericValue}€` : `${tag.name}: ${tag.numericValue}€`,
            color,
            type
        })
    }

    const categoryColors = { pub, bar, club, festival, music_venue: concert };
    const TagProps = tag.type === 'music_genre' ?
        ({
            icon: 'musical-note',
            displayText: tag.name,
            color: tagColor,
            type: type || 'transparent'
        }) :
        tag.type === 'place_category' ?
        ({
            icon: _codeToIcon[tag.code],
            color: categoryColors[tag.code],
            titleColor: tag.code === 'festival' ? titleColor : undefined,
            type: type || 'filled',
            style: { paddingHorizontal: 0, justifyContent: 'center', width: BaseTag.HEIGHT, maxWidth: BaseTag.HEIGHT }

        }) : tag.type === 'info_tag' ?
        ({
            displayText: tag.name,
            color: color || primary,
            type: type,
        }) : tag.type === 'price' ?
            tagPriceProps(tag)
           :
        ({
            displayText: tag.name || displayText,
            color,
            icon,
            type
        })

        return <BaseTag {...TagProps} dense={dense} style={[TagProps.style, style]} {...props} />
}

GeneralTag.HEIGHT = BaseTag.HEIGHT;

export const TagTogglable = ({ style, tag, toggle, selected, type, ...props }) => {
    const { useElevation } = useTheme();
    const { backgroundColor, ...elevation} = useElevation(1);
    
    return <Pressable style={style} onPress={toggle} {...props}>
        <GeneralTag
            type={type}
            color={selected ? undefined : backgroundColor}
            key={tag.code + tag.numericValue}
            tag={tag}
            style={{ paddingHorizontal: Spacing.M, flexGrow: 1, justifyContent: 'center', ...elevation, ...Mixins.rounding(36)}} />
    </Pressable>
}

TagTogglable.HEIGHT = 36;

export const TagsSelector = ({ style, tags, selectedTags, setSelectedTags, setAvailableTags }) => {
    const { useColor } = useTheme();
    const [label, body] = useColor(['label', 'body']);
    const { translate } = useLanguage();

    const pressTag = (selected, index) => {
        let pressedTag;
        if (selected) {
            const newSelected = [...selectedTags];
            pressedTag = newSelected.splice(index, 1)[0];
            setSelectedTags(newSelected);
            setAvailableTags([...tags, { ...pressedTag, type: '' }]);
        } else {
            const newAvailable = [...tags];
            pressedTag = newAvailable.splice(index, 1)[0];
            setAvailableTags(newAvailable);
            setSelectedTags([...selectedTags, { ...pressedTag, type: 'info_tag' }]);
        }
    }

    return (
        <View style={style}>
            <Text style={[Typography.TITLE, { color: body, marginTop: Spacing.XL, marginBottom: Spacing.M }]}>{translate('selectedTags')}</Text>
            <SmartWrapView
                flexDirection='row'
                crossDirectionSize={GeneralTag.HEIGHT + Spacing.XS}>
                {selectedTags.map((tag, index) => {
                    return <Pressable onPress={() => pressTag(true, index)}>
                        <GeneralTag key={tag.code + tag.numericValue} tag={tag} style={{ marginRight: Spacing.XS, marginBottom: Spacing.XS, flexGrow: 1, justifyContent: 'center' }} />
                    </Pressable>
                })}
            </SmartWrapView>
            <Text style={[Typography.TITLE, { color: body, marginTop: Spacing.XL, marginBottom: Spacing.M }]}>{translate('availableTags')}</Text>
            <SmartWrapView
                flexDirection='row'
                crossDirectionSize={GeneralTag.HEIGHT + Spacing.XS}>
                {tags.map((tag, index) => {
                    return <Pressable onPress={() => pressTag(false, index)}>
                        <GeneralTag color={label} key={tag.code + tag.numericValue} tag={tag} style={{ marginRight: Spacing.XS, marginBottom: Spacing.XS, flexGrow: 1, justifyContent: 'center' }} />
                    </Pressable>
                })}
            </SmartWrapView>
        </View>
    )
}

const styles = StyleSheet.create({
    baseTagContainer: {
        height: BaseTag.HEIGHT,
        ...Mixins.rounding(BaseTag.HEIGHT),
        paddingHorizontal: Spacing.XS,
        alignItems: 'center',
    }
})