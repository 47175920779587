import { Purchase, Order, Parser, Model } from '@datamodel';
import { objectMap, mapObjectValues } from '@utils/helpers';
import { PurchaseGroup } from './Order';

export class OrderRecord extends Model {

    static get uuid() { return 'orderRecordId'; }

    static get model() {
        return {
            createdAt: new Date(),
            purchases: {},
            order: Order.model,
            purchaseGroup: PurchaseGroup.model
        }
    }

    static get keymap() {
        return {
            id: OrderRecord.uuid,
        }
    }

    static get unserialMap() {
        return {
            purchases: purchases => mapObjectValues(
                purchases,
                p => Purchase.unserialize(p)
            ),
            order: o => Order.unserialize(o),
        }
    }

    static get toClassMap() {
        return {
            purchases: purchases => mapObjectValues(
                purchases,
                p => new Purchase(p)
            ),
            order: o => new Order(o)
        }
    }

    static get parseMap() {
        return {
            createdAt: val => Parser.date(val),
            purchases: purchases => objectMap(
                purchases.map(p => Purchase.parse(p)),
                Purchase.uuid),
            order: o => Order.parse(o)
        }
    }

    static get parseCallback() {
        return parsed => ({
            ...parsed,
            orderRecordId: parsed.order.orderId
        })
    }
    constructor(args) {
        super(args);
        Object.assign(this, OrderRecord.unserialize({ ...OrderRecord.model, ...args }, { toClass: true }))
    }
}