/// Color spaces transformations
export const HEXtoRGB = (hexColor) => {
    const colnum = parseInt(hexColor.substr(1), 16);
    return [(colnum >> 16), ((colnum >> 8) & 0x00FF), (colnum & 0x0000FF)]
}

export const RGBtoHEX = ([r, g, b]) => {
    return '#' + ('000000' + ((b | g << 8 | r << 16).toString(16))).substr(-6)
}

export const colorInterpolation = (initial, final, p) => {
    const [r0, g0, b0] = HEXtoRGB(initial);
    const [rf, gf, bf] = HEXtoRGB(final);
    return RGBtoHEX([p * (rf - r0) + r0, p * (gf - g0) + g0, p * (bf - b0) + b0]);
}

export const HSVtoHEX = ([h, s, v]) => {
    var r, g, b, i, f, p, q, t;
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v; g = t; b = p; break;
        case 1: r = q; g = v; b = p; break;
        case 2: r = p; g = v; b = t; break;
        case 3: r = p; g = q; b = v; break;
        case 4: r = t; g = p; b = v; break;
        case 5: r = v; g = p; b = q; break;
    }
    return RGBtoHEX([Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]);
}

export const RGBtoHSV = ([r, g, b]) => {
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const delta = max - min;
    var h, s, l;

    if (delta === 0) {
        h = 0;
    } else if (r === max) {
        h = (g - b) / delta;
        if (h < 0) h += 6;
    } else if (g === max) {
        h = (b - r) / delta + 2;
    } else {
        h = (r - g) / delta + 4;
    }
    h /= 6;

    s = max === 0 ? 0 : delta / max;
    l = max / 255;

    return [h, s, l]
}

export const HEXtoHSV = (hexColor) => RGBtoHSV(HEXtoRGB(hexColor));

///


export const setOpacity = (color, opacity) => {
    return color + ('0' + parseInt(opacity * 255).toString(16)).slice(-2)
}

export const colorDelta = (color, delta) => {
    const rgb = HEXtoRGB(color);
    return RGBtoHEX(rgb.map(val => Math.max(Math.min(val + delta, 255), 0)));
}

// color: HEX Value
export const complementary = (hexColor) => {
    const [h, s, v] = HEXtoHSV(hexColor);
    return HSVtoHEX([h < 1 / 2 ? h + 1 / 2 : h - 1 / 2, s, v]);
}

export const splitComplementaries = (hexColor='#000000') => {
    const [h, s, v] = HEXtoHSV(hexColor);
    const h1 = h + 17/36
    const h2 = h + 19/36
    const d1 = Math.min(Math.abs(1 / 6 - h1), Math.abs(1 + 1 / 6 - h1))
    const d2 = Math.min(Math.abs(1 / 6 - h2), Math.abs(1 + 1 / 6 - h2))
    let c1, c2;
    if (d1 < d2) { // first is hotter
        c1 = HSVtoHEX([h1 > 1 ? h1 - 1 : h1, s, v]);
        c2 = HSVtoHEX([h2 > 1 ? h2 - 1 : h2, s, v]);
    } else {
        c1 = HSVtoHEX([h1 > 1 ? h1 - 1 : h1, s, v]);
        c2 = HSVtoHEX([h2 > 1 ? h2 - 1 : h2, s, v]);
    }
    return [c1, c2]
}

export const vivid = (hexColor, maxSat = 1) => {
    const [h, s, v] = HEXtoHSV(hexColor);
    return HSVtoHEX([h, maxSat, 1]);
}

export const alphaBlend = (topColor, bottomColor, opacity) => {
    const [tR, tG, tB] = HEXtoRGB(topColor);
    const [bR, bG, bB] = HEXtoRGB(bottomColor);
    return RGBtoHEX([
        tR * opacity + (1 - opacity) * bR,
        tG * opacity + (1 - opacity) * bG,
        tB * opacity + (1 - opacity) * bB,
    ])
}

const relativeLuminance = (rgb) => {
    const [R, G, B] = rgb.map(c => c / 255).map(Cs => Cs <= 0.03928 ? Cs / 12.92 : ((Cs + 0.055) / 1.055) ** 2.4);
    return R * 0.2126 + G * 0.7152 + B * 0.0722;
}

const WCAG2ContrastChecker = (...colors) => {
    const luminance = colors.map(c => relativeLuminance(c));
    return (Math.max(...luminance) + 0.05) / (Math.min(...luminance) + 0.05);
}

const hexContrastChecker = (...colors) => WCAG2ContrastChecker(...(colors.map(hex => HEXtoRGB(hex))))

// THEME_PROVIDER RELATED
import { applicationId } from 'expo-application';

const channel = applicationId?.split('.')[3] || '' ;

export const festoColors = {
    onLight: {
        // GRAYSCALE
        title: '#250638',
        body: '#5c4b66',
        label: '#846e91',
        placeholder: '#a996b6',
        line: '#e3d9e9',
        inputBackground: '#f4f2f4',
        background: '#faf9fa',

        // BRAND COLORS
        primary: channel === 'canary' ? '#f49300' : channel === 'dev' ? '#007dff' : '#9d00ff',
        secondary: '#ec36c5',
        accent: '#ffbf00',
        warning: '#ff950b',
        error: '#ff0040',
        success: '#00ba8f',

        //SHIMMERING
        shimmerHighlight: '#E6E3E8',
        shimmerShadow: '#F4F2F4',

        // CATEGORICAL COLORS
        'Electric Purple': '#9d00ff',
        'Psycho Purple': '#c600d6',
        'Shocking Pink': '#d6009f',
        'Deep Pink': '#db0072',
        'Torch Red': '#db004d',
        'Imperial Red': '#db0028',
        'Orange Red': '#d63100',
        'Pumpkin': '#d65f00',
        'Dark Orange': '#ad6500',
        'Honey Yellow': '#c38a00',
        'Gold': '#af9300',
        'Lemon': '#9a9900',
        'Electric Lime': '#7ea000',
        'Bright Green': '#46a900',
        'Spring Green': '#00ab55',
        'Sea Green': '#00a981',
        'Aqua': '#00a69b',
        'Electric Blue': '#00a4ae',
        'Sky Blue': '#00a2c5',
        'Cerulean': '#009edf',
        'Dodger Blue': '#007acd',
        'Crayola Blue': '#006ae0',
        'Navy Blue': '#0056ff',
        'Blue': '#0012ff',
    },
    onDark: {
        // GRAYSCALE
        title: '#fafafa',
        body: '#c0bdc1',
        label: '#9a959c',
        placeholder: '#746F76',
        line: '#383838',
        inputBackground: '#1F1F1F',
        background: '#000000',

        // BRAND COLORS
        primary: channel === 'canary' ? '#FFB500' : channel === 'dev' ? '#007dff' : '#C466FF',
        secondary: '#F05CD0',
        accent: '#FFDF7F',
        warning: '#FFB24C',
        error: '#FF4C79',
        success: '#34EABF',

        //SHIMMERING
        shimmerHighlight: '#383838',
        shimmerShadow: '#1F1F1F',
        
        // CATEGORICAL COLORS
        'Electric Purple': '#b033ff',
        'Psycho Purple': '#ec00ff',
        'Shocking Pink': '#ff00bd',
        'Deep Pink': '#ff0085',
        'Torch Red': '#ff0059',
        'Imperial Red': '#ff002f',
        'Orange Red': '#ff3a00',
        'Pumpkin': '#ff7100',
        'Dark Orange': '#ff9500',
        'Honey Yellow': '#ffb500',
        'Gold': '#ffd600',
        'Lemon': '#fffb00',
        'Electric Lime': '#c9ff00',
        'Bright Green': '#6aff00',
        'Spring Green': '#00ff7e',
        'Sea Green': '#00ffc3',
        'Aqua': '#00ffed',
        'Electric Blue': '#00f0ff',
        'Sky Blue': '#00d2ff',
        'Cerulean': '#00b5ff',
        'Dodger Blue': '#0097ff',
        'Crayola Blue': '#0079ff',
        'Navy Blue': '#3d7fff',
        'Blue': '#414fff',
    },
    darker: {
        // BRAND COLORS
        primary: channel === 'canary' ? '#C38A00' : channel === 'dev' ? '#0056B2' : '#6E00B2',
        primary: '#6E00B2',
        secondary: '#B80090',
        accent: '#B78900',
        warning: '#AB6100',
        error: '#E6003A',
        success: '#008365',
    },
    darkElevation: {
        0: '#000000',
        1: '#0D0D0D',
        2: '#121212',
        3: '#141414',
        4: '#171717',
        6: '#1C1C1C',
        8: '#1F1F1F',
        9: '#212121',
        12: '#242424',
        16: '#262626',
        24: '#292929',
    }
}

const CONTRAST_BLACK = [  0,   0,   0];
const CONTRAST_WHITE = [255, 255, 255];

const colorIsDark = (color) => {
    const RGB = HEXtoRGB(color)
    const darkContrast = WCAG2ContrastChecker(CONTRAST_BLACK, RGB);
    const lightContrast = WCAG2ContrastChecker(CONTRAST_WHITE, RGB);
    return Math.min(darkContrast, lightContrast) === darkContrast;
}

export const getColor = (isDark) => (colorName, option={}) => {
    const opts = { reverse: false, dark: false, on: undefined, ...option }
    if (opts.dark)
        return festoColors.darker[colorName]

    if (opts.on)
        return colorIsDark(opts.on) === opts.reverse ? festoColors.onLight[colorName] : festoColors.onDark[colorName];

    return isDark === opts.reverse ? festoColors.onLight[colorName] : festoColors.onDark[colorName];

}

export const gradients = {
    primary: [
        festoColors.onLight.primary,
        festoColors.onDark.secondary
    ],
    secondary: [
        '#707AFF',
        '#A738EC',
    ],
    accent: [
        festoColors.onLight.error,
        '#FFD399'
    ],
    golden: [
        festoColors.onLight.accent,
        festoColors.onLight.warning
    ]
}

import { Color, fCIELAB } from './color-spaces';
export const withMinimumWhiteContrast = (hex, contrast = 3) => {
    const c = new Color({ hex: hex.slice(1) });
    const lum = (1.05 - contrast * 0.05) / (contrast + .05);
    c.lab.lightness = 116 * fCIELAB(lum) - 16;
    return '#' + c.lab.tosRGBA().toHEX();
}