import React, { useState, useMemo, useRef, useEffect } from 'react';
import { ScrollView, Text, View, Animated, Pressable } from 'react-native';
import { Typography, Mixins, Icon, Spacing, Colors } from '@styles';

import { PurchaseInfo } from '@molecules';
import { Order, Purchase } from '@datamodel';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import Animation from '@utils/animations';
import { parsePrice, parseDiscount, findFinalTotal, mapObjectValues } from '@utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useModal, useSnackbar } from '@components/lib';
import { Button } from '@atoms';
import { clearReadingSession, getMenusIfNeeded } from '@actions';

export const ReadOrderModal = ({ _dismissModal, style, callback = () => { }, ...props }) => {
    const { translate } = useLanguage();
    const { pushSnack } = useSnackbar();
    const { pushModal } = useModal();
    const { useElevation, useColor } = useTheme();
    const dispatch = useDispatch();
    const animation = useRef(new Animated.Value(0)).current;
    const menus = useSelector(state => state.menus);
    const {order, purchases, purchaseGroup} = useSelector(state => state.readingSession);

    const purchasesByArea = useMemo(() => {
        const byArea = {};

        const sp = Purchase.stackPurchases(purchases);
        Object.keys(sp).forEach(selId => {
            const pur = sp[selId];
            const areaId = pur.selection.item.nodeId;
            if (areaId in byArea) {
                byArea[areaId].push(pur);
            } else {
                byArea[areaId] = [pur];
            }
        })
        return byArea;
    }, [purchases]);

    useEffect(() => {
        Animated.timing(animation, Animation.create(1, 100)).start();
    }, []);

    useEffect(() => {
        const menusToFecth = new Set();
        Object.keys(purchases).forEach(pId => {
            menusToFecth.add(purchases[pId].menuNodeId);
        })
        dispatch(getMenusIfNeeded([...menusToFecth]));

    }, [purchases]);

    const [primary, title, body] = useColor(['primary', 'title', 'body']);
    const elevation = useElevation(12);

    return (
        <Animated.View style={[style, {
            opacity: animation,
            padding: Spacing.M,
            paddingBottom: 0,
            transform: [{ scale: animation.interpolate(Animation.keyframes(.8, 1)) }],
            overflow: 'hidden',
            flex: 1,
            ...Mixins.matchRounding(Mixins.rounding(36), Spacing.M),
        }]}>
            <Text style={[Typography.H4, { textAlign: 'center', color: title, marginBottom: Spacing.M }]}>{purchaseGroup?.shortCode || Order.getShortCode(order).join(' ')}</Text>
            <ScrollView contentContainerStyle={{ paddingBottom: Spacing.M }}>
                <View style={{ paddingHorizontal: Spacing.M }}>
                    {Object.keys(purchasesByArea).map(areaId => {
                        const stackedPurchases = purchasesByArea[areaId];
                        if (stackedPurchases.length === 0) {
                            return <></>
                        }
                        const menuNodeId = stackedPurchases[0].menuNodeId;

                        return <View style={{ marginVertical: Spacing.XS }}>
                            <Text style={[Typography.OVERLINE, { color: title }]}>{menus[menuNodeId] ? menus[menuNodeId][areaId]?.name : ''}</Text>
                            {
                                stackedPurchases.map(({ quantity, ...purchase }) => {
                                    return <PurchaseInfo showArea={false} {...{ purchase, menu: menus[purchase.menuNodeId], quantity }} />
                                })
                            }
                        </View>
                    })}
                </View>
            </ScrollView>
        </Animated.View>
    )
}