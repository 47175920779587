import * as React from "react"
import Svg, {
  G,
  Path,
  Defs,
  LinearGradient,
  Stop,
  ClipPath,
} from "react-native-svg"

function SvgComponent({logoStyle, ...props}) {
  
  const fills = {
    light: '#FAFAFA',
    dark: '#250638',
  }

  const gradients = {
    light: ['#FF8CE6', '#9D00FF', '#8200D3'],
    dark: ['#DE24FF', '#9D00FF', '#4F0080']
  }

  return (
    <Svg
      viewBox="0 0 194 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M101 39.494c0 9.014 6.463 12.212 11.997 12.212 1.556 0 4.421-.254 6.128-1.96 0 0-2.984-5.75-2.981-5.752-1.5 1.3-4.594 1.35-6.394.65-5.087-1.697-5.2-7.852-5.2-12.3v-10.4c0-2.353-1.359-4.48-3.514-5.403L101 39.494Z"
        fill="url(#a)"
      />
      <Path
        d="M112.997 44.706c-4.429 0-4.997-2.785-4.997-5.207l.027-17.14h11.152v-7h-11.141l.016-9.865-7-.01-.018 11.057c2.155.924 3.514 3.05 3.514 5.402v10.4c0 4.45.113 10.604 5.2 12.3 1.8.7 4.894.651 6.394-.649-.129.06-1.213.712-3.147.712Z"
        fill="url(#b)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.176 35.71h25.741s.157-2.846.16-3.796c.01-2.797-.21-5.964-1.836-9.1-1.501-2.893-4.009-5.307-7.06-6.799-6.113-2.99-13.819-1.814-18.742 2.855-2.787 2.645-4.593 6.271-5.167 10.252l-.06-.009c-.196 1.267-.221 4.19-.21 4.899.271 10.252 7.961 17.69 18.29 17.688h.01c6.899-.005 12.358-3.55 14.28-5.424l-4.884-5.014c-.745.726-4.521 3.435-9.4 3.438h-.008c-5.933 0-10.205-3.551-11.114-8.99Zm18.666-7H33.49c.503-1.86 1.45-3.511 2.766-4.76 2.804-2.659 7.368-3.35 10.85-1.647 1.715.838 3.108 2.165 3.922 3.734.432.833.673 1.712.815 2.673ZM131.176 35.71h25.741s.157-2.846.16-3.796c.01-2.797-.209-5.964-1.836-9.1-1.501-2.893-4.009-5.307-7.06-6.799-6.113-2.99-13.819-1.814-18.742 2.855-2.787 2.645-4.594 6.271-5.167 10.252l-.061-.009c-.195 1.267-.22 4.19-.209 4.899.271 10.252 7.961 17.69 18.289 17.688h.012c6.898-.005 12.357-3.55 14.279-5.424l-4.884-5.014c-.745.726-4.521 3.435-9.4 3.438h-.008c-5.933 0-10.205-3.551-11.114-8.99Zm18.666-7h-18.353c.503-1.86 1.45-3.511 2.766-4.76 2.804-2.659 7.368-3.35 10.851-1.647 1.714.838 3.107 2.165 3.921 3.734.432.833.673 1.712.815 2.673Z"
        fill={fills[logoStyle]}
      />
      <Path
        d="M68.986 18.142c.047-.049.09-.102.138-.149-.046.05-.094.098-.138.149ZM69.124 17.993l.017-.018-.017.018Z"
        fill="#6B6B6B"
      />
      <Path
        d="M78.99 21.333c.95 0 1.73.09 2.22.17 2.36.353 4.665 1.266 6.37 2.97l4.85-4.94c-3.572-3.36-8.245-5.12-13.15-5.12-3.728 0-7.617 1.01-10.294 3.729-1.22 1.385-1.934 3.125-1.934 5.175 0 5.458 6.604 7.847 11.754 9.55 5.297 1.754 11.784 4.209 11.784 9.938 0 4.85-3.613 7.767-8.648 8.656 5.352-.852 10.298-4.889 10.298-10.648 0-6.752-6.546-9.453-11.96-11.25-2.393-.83-7.974-1.798-7.25-5.16.57-2.67 3.98-3.07 5.96-3.07Z"
        fill="url(#c)"
      />
      <Path
        d="M90.59 42.805c0-5.73-6.487-8.184-11.784-9.938-5.15-1.703-11.754-4.092-11.754-9.55 0-2.05.715-3.79 1.933-5.175a10.645 10.645 0 0 0-2.255 3.361c-.97 2.33-.98 4.77-.02 7.05 2.013 4.778 7.452 6.208 11.91 7.74 2.154.718 4.924 1.216 6.29 3.2 1.188 1.714-.284 3.787-1.93 4.52-1.21.56-2.61.84-4.16.84-3.646 0-7.375-1.455-9.96-4.04L64 45.673c4.605 4.605 11.515 6.81 17.942 5.788 5.035-.889 8.648-3.806 8.648-8.656Z"
        fill="url(#d)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.051 50.855h7v-3.61c0-.3.02-1.775.031-2.551l.006-.477-.115-12.31c0-.045-.023-4.275-.381-5.716h.003c.068.25.089.437.093.478l-.011-.132a8.332 8.332 0 0 0-.052-.518c-.669-7.29-6.008-11.636-14.314-11.636-6.518 0-11.854 2.901-14.445 5.386l4.846 5.051c.997-.956 4.664-3.437 9.599-3.437 6.914 0 7.232 3.903 7.352 5.372.045.46.124.915.158 1.375-3.537-.744-6.698-1.066-8.799-.887-3.304.271-6.405 1.38-8.731 3.12-3.732 2.794-5.505 6.227-5.27 10.206a11.4 11.4 0 0 0 3.7 7.762c2.375 2.18 5.641 3.37 9.234 3.37 3.156 0 6.301-1.048 8.818-2.954.447-.339.874-.707 1.278-1.1v3.208Zm-8.446-16.637c.182-.015.401-.024.655-.024 1.058 0 2.722.147 4.874.54.217 2.184.12 4.164-.068 4.765-.268.86-.952 2.481-2.519 3.668a7.656 7.656 0 0 1-4.418 1.532c-1.904.053-3.563-.505-4.675-1.525a4.376 4.376 0 0 1-1.445-3.017c-.069-1.162.184-2.473 2.476-4.189 1.267-.948 3.13-1.586 5.12-1.75Z"
        fill={fills[logoStyle]}
      />
      <Path
        d="M5.268 50.796h7V22.189h7.966v-7h-7.966V13.64c0-1.418.298-3.957 2.301-5.037 1.554-.838 3.577-1.15 5.665-.531l3.744-4.956C20.602-.263 14.5.688 11.248 2.443c-3.8 2.048-5.98 6.13-5.98 11.198v1.548H0v7h5.268v28.607Z"
        fill={fills[logoStyle]}
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={101}
          y1={16.541}
          x2={125.991}
          y2={22.395}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={gradients[logoStyle][0]} />
          <Stop offset={0.505} stopColor={gradients[logoStyle][1]} />
          <Stop offset={1} stopColor={gradients[logoStyle][2]} />
        </LinearGradient>
        <LinearGradient
          id="b"
          x1={101.036}
          y1={5.483}
          x2={126.328}
          y2={10.748}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={gradients[logoStyle][2]} />
          <Stop offset={0.484} stopColor={gradients[logoStyle][1]} />
          <Stop offset={1} stopColor={gradients[logoStyle][0]} />
        </LinearGradient>
        <LinearGradient
          id="c"
          x1={67.052}
          y1={14.413}
          x2={100.702}
          y2={24.89}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={gradients[logoStyle][2]} />
          <Stop offset={0.484} stopColor={gradients[logoStyle][1]} />
          <Stop offset={1} stopColor={gradients[logoStyle][0]} />
        </LinearGradient>
        <LinearGradient
          id="d"
          x1={64}
          y1={18.142}
          x2={98.237}
          y2={30.469}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={gradients[logoStyle][0]} />
          <Stop offset={0.505} stopColor={gradients[logoStyle][1]} />
          <Stop offset={1} stopColor={gradients[logoStyle][2]} />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

export default SvgComponent
