import { Colors } from '@styles';
import React from 'react';
import { _translate as translate } from '@languageProvider';
const _translate = translate ? translate : () => { };


export function changeKeys(obj, keys, value) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            switch (typeof (obj[prop])) {
                case 'object':
                    if (keys.indexOf(prop) > -1) {
                        obj[prop] = value;
                    } else {
                        changeKeys(obj[prop], keys);
                    }
                    break;
                default:
                    if (keys.indexOf(prop) > -1) {
                        obj[prop] = value;
                    }
                    break;
            }
        }
    }
}

export const defaultVal = (value, def, callback = null) => {
    return (value !== undefined && value !== null) ?
        callback ? callback(value) : value
        : def;
}

export const objectGetAny = (obj) => {
    const keys = Object.keys(obj);
    return keys.length ? obj[keys[0]] : ({});
}

/*
    Transforms a list of objects to a object of objects
*/
export const objectMap = (arr, key, mapFunc = (x => x), includeKey = true) => {
    const ret = {};
    arr.forEach((item) => {
        const { [key]: id, ...data } = item;
        ret[id] = mapFunc(data);
        if (includeKey) {
            ret[id][key] = id;
        }
    });
    return ret;
}

/*
    Usage example:
    obj = { a: { data: 1}, b: { data: 2} }

    result = objectFilter(obj, o => o.data === 1)

    result = { a: { data: 1} }

*/
export const objectFilter = (obj, predicate) =>
    Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => (res[key] = obj[key], res), {});

/**
 * 
 * @param {Object} obj Object to iterate
 * @param {Function} callbackFn Reduce function, receives (prev, next) where prev
 *                              is the accumulated value and next is an object value
 * @param {*} initialValue Initial value of the reduce
 * @returns Reduce value
*/
export const objectReduce = (obj, callbackFn, initialValue) =>
    Object.keys(obj)
        .reduce((prev, k) => callbackFn(prev, obj[k]), initialValue)

export const addClassMethods = ({ fromClass, toClass }) => {
    Object.defineProperties(toClass,
        objectFilter(
            Object.getOwnPropertyDescriptors(fromClass),
            d => d.writable
        )
    )
}


export const uniqueKeys = (myObj) => {
    const s = new Set();
    for (let k in myObj) {
        myObj[k].forEach(uuid => s.add(uuid))
    }
    return [...s];
}

export const cleanObject = (myObj) => {
    Object.keys(myObj).forEach((key) => (myObj[key] == null) && delete myObj[key]);
}

export const projection = (o, properties = [], defaults = {}) => {
    if (!o) return o;
    if (!('reduce' in properties)) {
        console.log('Properties is not an array', properties);
        return {};
    }
    return properties.reduce((r, k) => {
        if (defaults[k] !== null || k in o) {
            r[k] = o[k] ?? defaults[k];
        }
        return r;
    }, {});
}

export const dateParser = (string) => {
    if (!string || string === '') return null;
    if (string.slice(-1) === 'Z') {
        return new Date(string);
    }
    const [date, time] = string.split('T');
    const [y, m, d] = date.split('-');
    const [h, min, s] = time ? time.split(':') : [0, 0, 0];
    return new Date(y, m - 1, d, h, min, s);
}

export const dateToWeekday = (date, translate) => {
    const weekdays = [
        translate('sunday'),
        translate('monday'),
        translate('tuesday'),
        translate('wednesday'),
        translate('thursday'),
        translate('friday'),
        translate('saturday')
    ]
    return `${weekdays[date.getDay()]}`
}

export const getDayLetter = (number, translate) => {
    const weekdays = [
        translate('sundayLetter'),
        translate('mondayLetter'),
        translate('tuesdayLetter'),
        translate('wednesdayLetter'),
        translate('thursdayLetter'),
        translate('fridayLetter'),
        translate('saturdayLetter')
    ]
    return weekdays[number];
}

export const dateToMonth = (date, translate) => {
    const months = [
        translate('january'),
        translate('february'),
        translate('march'),
        translate('april'),
        translate('may'),
        translate('june'),
        translate('july'),
        translate('august'),
        translate('september'),
        translate('october'),
        translate('november'),
        translate('december')]
    return `${months[date.getMonth()]}`
}

export const dateToShortWeekday = (date, translate) => {
    const weekdays = [
        translate('sundayAbbreviation'),
        translate('mondayAbbreviation'),
        translate('tuesdayAbbreviation'),
        translate('wednesdayAbbreviation'),
        translate('thursdayAbbreviation'),
        translate('fridayAbbreviation'),
        translate('saturdayAbbreviation')
    ]
    return `${weekdays[date.getDay()]}`
}

export const dateToShortString = (date, translate = _translate) => {
    let d = date;
    if (!(d instanceof Date)) {
        d = new Date(date);
    }

    return `${d.getDate()} ${dateToMonth(d, translate)}, ${d.getFullYear()}`
}

export const dateToHour = (date) => {
    let d = date;
    if (!(d instanceof Date)) {
        d = new Date(date);
    }
    return `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`
}

export const dateToISO8641 = (date) => {
    return `${date.getFullYear()}-${('00' + (date.getMonth() + 1)).substr(-2)}-${('00' + (date.getDate())).substr(-2)}`
}

export const dateToMidString = (date, translate = _translate) => {
    let d = date;
    if (!(d instanceof Date)) {
        d = new Date(date);
    }
    return translate('midString', { weekday: dateToWeekday(d, translate), date: d.getDate(), month: dateToMonth(d, translate) })
}


const minute = 60 * 1000;
const hour = 60 * minute;
const day = hour * 24;
const week = day * 7;
const year = day * 365;

export const timeDistance = (date, translate) => {

    const delta = new Date() - new Date(date);
    if (delta < minute) {
        return translate('momentAgo')
    }
    if (delta < hour) {
        return `${parseInt(delta / minute)}min`
    }
    if (delta < day) {
        return `${parseInt(delta / hour)}h`
    }
    if (delta < week) {
        return `${parseInt(delta / day)}d`
    }
    if (delta < year) {
        return `${parseInt(delta / week)}${translate('weekAbbreviation')}`
    }
    const y = parseInt(delta / year);
    return `${y}${y === 1 ? translate('year') : translate('years')}`
}

export const shortTimeDistance = (date, translate) => {
    const delta = new Date() - new Date(date);
    if (delta < minute) {
        return `${parseInt(delta / 1000)}s`
    }
    return timeDistance(date, translate);
}

export const HEXtoRGB = (hexColor) => {
    const colnum = parseInt(hexColor.substr(1), 16);
    return [(colnum >> 16), ((colnum >> 8) & 0x00FF), (colnum & 0x0000FF)]
}

export const RGBtoHEX = ([r, g, b]) => {
    return '#' + ('000000' + ((b | g << 8 | r << 16).toString(16))).substr(-6)
}

export const colorInterpolation = (initial, final, p) => {
    const [r0, g0, b0] = HEXtoRGB(initial);
    const [rf, gf, bf] = HEXtoRGB(final);
    return RGBtoHEX([p * (rf - r0) + r0, p * (gf - g0) + g0, p * (bf - b0) + b0]);
}

export const HSVtoHEX = ([h, s, v]) => {
    var r, g, b, i, f, p, q, t;
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        case 5: r = v, g = p, b = q; break;
    }
    return RGBtoHEX([Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]);
}

export const RGBtoHSV = ([r, g, b]) => {
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const delta = max - min;
    var h, s, l;

    if (delta === 0) {
        h = 0;
    } else if (r === max) {
        h = (g - b) / delta;
        if (h < 0) h += 6;
    } else if (g === max) {
        h = (b - r) / delta + 2;
    } else {
        h = (r - g) / delta + 4;
    }
    h /= 6;

    s = max === 0 ? 0 : delta / max;
    l = max / 255;

    return [h, s, l]
}

export const HEXtoHSV = (hexColor) => RGBtoHSV(HEXtoRGB(hexColor));

export const haversineDistance = (coords1, coords2, isMiles) => {
    const toRad = (x) => {
        return x * Math.PI / 180;
    }

    var lon1 = coords1[0];
    var lat1 = coords1[1];

    var lon2 = coords2[0];
    var lat2 = coords2[1];

    var R = 6371; // km

    var x1 = lat2 - lat1;
    var dLat = toRad(x1);
    var x2 = lon2 - lon1;
    var dLon = toRad(x2)
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    if (isMiles) d /= 1.60934;

    return d;
}

export const calculateRadius = (lat, lng, latDelta, lngDelta) => {
    if (!lat || !lng) return 0;
    const width = haversineDistance([lat - latDelta, lng], [lat + latDelta, lng]);
    const height = haversineDistance([lat, lng - lngDelta], [lat, lng + lngDelta]);
    return Math.max(width, height) / 2;

}

export const countDecimals = (number) => {
    if (Number.isInteger(number)) return 0;
    return number.toString().split('.')[1].length || 0;
}

export const smartRound = (number, positions) => {
    const decimals = Math.min(countDecimals(number), positions);
    return number.toFixed(decimals);
}

export function Timer(_fn_callback_, _timer_freq_) {
    let RESUME_CORRECTION_RATE = 2;

    let _timer_statusCode_;
    let _timer_clockRef_;

    let _time_ellapsed_;        // will store the total time ellapsed
    let _time_pause_;           // stores the time when timer is paused
    let _time_lastCycle_;       // stores the time of the last cycle

    let _isCorrectionCycle_;

    /**
     * execute in each clock cycle
     */
    const nextCycle = function () {
        // calculate deltaTime
        let _time_delta_ = new Date() - _time_lastCycle_;
        _time_lastCycle_ = new Date();
        _time_ellapsed_ += _time_delta_;

        // if its a correction cicle (caused by a pause,
        // destroy the temporary timeout and generate a definitive interval
        if (_isCorrectionCycle_) {
            clearTimeout(_timer_clockRef_);
            clearInterval(_timer_clockRef_);
            _timer_clockRef_ = setInterval(nextCycle, _timer_freq_);
            _isCorrectionCycle_ = false;
        }
        // execute callback
        _fn_callback_.apply(timer, [timer]);
    };

    // initialize timer
    _time_ellapsed_ = 0;
    _time_lastCycle_ = new Date();
    _timer_statusCode_ = 1;
    _timer_clockRef_ = setInterval(nextCycle, _timer_freq_);


    // timer public API
    const timer = {
        get statusCode() { return _timer_statusCode_ },
        get timestamp() {
            let abstime;
            if (_timer_statusCode_ === 1) abstime = _time_ellapsed_ + (new Date() - _time_lastCycle_);
            else if (_timer_statusCode_ === 2) abstime = _time_ellapsed_ + (_time_pause_ - _time_lastCycle_);
            return abstime || 0;
        },

        pause: function () {
            if (_timer_statusCode_ !== 1) return this;
            // stop timers
            clearTimeout(_timer_clockRef_);
            clearInterval(_timer_clockRef_);
            // set new status and store current time, it will be used on
            // resume to calculate how much time is left for next cycle
            // to be triggered
            _timer_statusCode_ = 2;
            _time_pause_ = new Date();
            return this;
        },

        resume: function () {
            if (_timer_statusCode_ !== 2) return this;
            _timer_statusCode_ = 1;
            _isCorrectionCycle_ = true;
            const delayEllapsedTime = _time_pause_ - _time_lastCycle_;
            _time_lastCycle_ = new Date(new Date() - (_time_pause_ - _time_lastCycle_));

            _timer_clockRef_ = setTimeout(nextCycle, _timer_freq_ - delayEllapsedTime - RESUME_CORRECTION_RATE);

            return this;
        },

        destroy: function () {
            clearTimeout(_timer_clockRef_);
            clearInterval(_timer_clockRef_);
            _time_ellapsed_ = 0;
            _time_lastCycle_ = new Date();
            _timer_statusCode_ = 0;
            return this;
        }
    };
    return timer;
};

export const mod = (n, m) => ((n % m) + m) % m;

export const parsePrice = (price, currency = '') => {
    if (price === 0) {
        return _translate('free');
    }
    if (currency === '€') {
        return (price / 100).toFixed(2) + currency;
    }
    return '';
}

export const snakeToCamel = (str) => {
    let [first, ...words] = str.split('_');
    if (first.length === 0) {
        [first, ...words] = words;
        first = '_' + first;
    }
    return [first, ...words.map(s => s.charAt(0).toUpperCase() + s.slice(1))].join('');
}

const _transformKeys = (o, transform) => {
    const build = {}
    for (const key in o) {
        build[transform(key)] = o[key]
    }
    return build;
}

export const refitKeys = (o, mapping = {}, transform = ((s) => s), recurseMapping = true) => {
    // Only handle non-null objects
    if (o === null || typeof o !== "object" || o === undefined) {
        return o;
    }

    // Handle array just by handling their contents
    if (Array.isArray(o)) {
        return o.map(content => refitKeys(content, mapping, transform));
    }

    const build = {};
    const transformedMapping = _transformKeys(mapping, transform);
    for (const key in o) {
        // Get the destination key

        const destKey = transformedMapping[transform(key)] || transform(key);
        // Get the value
        let value = o[key];

        // If this is an object, recurse
        if (typeof value === "object") {
            value = refitKeys(value, recurseMapping ? mapping : {}, transform);
        }

        // Set it on the result using the destination key
        build[destKey] = value;
    }
    return build;
}

export const applyMap = (object, map) => {
    const ret = {}
    for (const k in object) {
        ret[k] = k in map ? map[k](object[k]) : object[k]
    }
    return ret;
}

export const mapObjectValues = (object, map) => Object.fromEntries(Object.entries(object).map(([key, value]) => [key, map(value, key)]));

export const splitArrayChunks = (arr, chunkSz) => arr.reduce((all, one, i) => {
    const ch = Math.floor(i / chunkSz);
    all[ch] = [].concat((all[ch] || []), one);
    return all
}, []);

export const flatStyle = (style) => {
    let newStyle = style;
    if (Array.isArray(style)) {
        newStyle = style.flat(Infinity);
        newStyle.reduce((prev, next) => {
            return { ...prev, ...next };
        }, {})
    }
    return newStyle;
}

export const parsePriceString = (price) => {
    let parsedPrice;
    if (typeof price === 'string' || price instanceof String) {
        parsedPrice = price.replace(',', '.');
    }
    return Math.max(0, parseFloat(parsedPrice));
}

export const simpleHash = (str, options) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    if (options.numeric)
        return hash;
    return new Uint32Array([hash])[0].toString(36);
};

export const parseDiscount = (discount) => {
    return 100 * parseFloat(discount.replace(',', '.')) || 0
}

export const findFinalTotal = (total, discount) => {
    let numericValue = parseDiscount(discount);
    return Math.max(0, total - numericValue);
}

class TimeLogger {
    constructor ( ) {
        this.lastLog = null;
    }

    log(...args) {
        console.log(this.lastLog ? Date.now() - this.lastLog : '0' + 'ms', ...args)
        this.lastLog = Date.now()
    }
}

export const tlog = new TimeLogger();

export const chunk = (arr, sz) => {
    const ret = [];
    for (let i = 0; i < arr.length; i+=sz) {
        ret.push(arr.slice(i, i+sz));
    }
    return ret;
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}