import React, { useState, useMemo, useRef, useEffect } from 'react';
import { ScrollView, Text, View, Animated, Pressable } from 'react-native';
import { Typography, Spacing, Colors, Mixins, Icon } from '@styles';

import { Stepper, Button } from '@components/atoms';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { ProductPack, ProductNode, Coupon } from '@datamodel';
import Animation from '@utils/animations';
import { AddItemModal } from '@modals';
import { objectMap, parsePrice } from '@utils/helpers';
import { SelectionTree } from '@datamodel/Cart';
import { FungibleCard, SelectedProductInfo } from '@molecules';
import { FlatGrid, useSnackbar } from '@lib';



import { Trackings } from '@services';
const modalTracker = new Trackings.Tracker('AddPackModal');

/** Areas length is the same as the number of packItems, and areas are sorted as packItems order */
export const AddPackModal = ({ _dismissModal, pack, areas, addToCartPress, offers = { products: {} }, coupons = { products: {} }, style, absolutePrice }) => {
    const { translate } = useLanguage();
    const { useElevation, useColor } = useTheme();
    const { pushSnack } = useSnackbar();

    const itemIds = useMemo(() => {
        return ProductPack.getProductIds(pack, { repetitions: true, sorted: true }).map(({ itemId }) => itemId);
    }, [pack]);

    const itemIdByProductId = useMemo(() => {
        return objectMap(ProductPack.getProductIds(pack, { repetitions: false, sorted: false }), 'productId');
    }, [pack]);

    const INITIAL_SELECTIONS = useMemo(() => itemIds.map((itemId, j) => {

        const area = areas[j];
        const nFungibles = area.fungibleProducts.length;
        if (nFungibles > 1 || nFungibles <= 0) {
            return SelectionTree.model
        }

        const selectionTree = SelectionTree.init(pack.items[itemId].product);

        const toMark = area.fungibleProducts[0].options ?
            area.fungibleProducts[0].options.length === 1 ? area.fungibleProducts[0].options[0].nodeId : null
            : area.fungibleProducts[0].nodeId;

        if (toMark) {
            SelectionTree.mark(selectionTree, { nodeId: toMark, value: true });
        }

        return selectionTree;
    }), [])

    const [quantity, setQuantity] = useState(1);
    const [selections, setSelections] = useState(INITIAL_SELECTIONS)
    const animation = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(animation, Animation.create(1, 100)).start();
    }, [])

    const [title, line, error, primary] = useColor(['title', 'line', 'error', 'primary']);
    const elevation = useElevation(12);

    const makeSelection = selIndex => (selection) => {
        modalTracker.tap('AddToPackButton', {
            fungible: selection.nodeId,
            index: selIndex,
            action: 'addToPack'
        })
        const newSelections = [...selections];
        newSelections[selIndex] = selection;
        setSelections(newSelections);
    };

    const displayItemModal = (selIndex, lastSelectionTree, showDelete) => AddItemModal.display({
        addToCartPress: makeSelection(selIndex),
        showStepper: false,
        serverSelection: false,
        lastSelectionTree,
        showDelete,
        onDeletePress: () => deleteItemSelection(selIndex),
        buttonConfig: {
            title: `${translate('continue')}`,
            icon: 'check-alt',
        }
    });

    const productCardPress = (fungibleParent, selectionIndex) => (option) => {
        const selectionTree = SelectionTree.init(fungibleParent);
        const nodeId = option.nodeId;
        SelectionTree.mark(selectionTree, { nodeId, value: true });

        let _trackConfig = {
            fungible: fungibleParent.nodeId,
            option: option.nodeId,
        }

        if (selectionTree.marked && selectionTree.solved && selectionTree.valid) {
            makeSelection(selectionIndex)(selectionTree);
            modalTracker.tap('ProductCard', {
                ..._trackConfig,
                action: 'addToPack'
            })
        } else {
            displayItemModal(selectionIndex)(fungibleParent)(option, selectionTree);
            modalTracker.tap('ProductCard', {
                ..._trackConfig,
                action: 'display.AddItemModal'
            })
        }
    }

    const selectedProductInfoPress = (fungible, selectionIndex, lastSelectionTree) => {
        const optionId = SelectionTree.findMainQuestionAnswer(lastSelectionTree, fungible) || fungible.nodeId;
        const option = ProductNode.getTreeNode(fungible, optionId);
        if (!option) return;

        const selectionTree = SelectionTree.init(fungible);
        const nodeId = option.nodeId;
        SelectionTree.mark(selectionTree, { nodeId, value: true });

        if (selectionTree.marked && selectionTree.solved && selectionTree.valid) {
            return;
        } else {
            displayItemModal(selectionIndex, lastSelectionTree, true)(fungible)(option, selectionTree);
            modalTracker.tap('ProductCard', {
                fungible: fungibleParent.nodeId,
                option: option.nodeId,
                action: 'display.AddItemModal'
            })
        }
    }

    const deleteItemSelection = (idx) => {
        const newSelections = [...selections];
        newSelections[idx] = INITIAL_SELECTIONS[idx];
        setSelections(newSelections);
    }

    const closeModal = () => {
        Animated.timing(animation, Animation.create(0, 100)).start(({ finished }) => {
            if (finished) {
                _dismissModal();
            }
        });
    }

    const selectionPackItemPrices = selections.reduce((acc, sel) => {
        const packItem = ProductPack.getPackItemByProductId(pack, sel.nodeId);
        let selPrice = SelectionTree.calculatePrice(sel);
        if (packItem.itemId in acc) {
            acc[packItem.itemId].push(selPrice);
        } else {
            acc[packItem.itemId] = [selPrice];
        }
        return acc;
    }, {});

    const selectionPrice = Object.keys(selectionPackItemPrices).reduce((acc, itemId) => {
        let itemPrice = selectionPackItemPrices[itemId].reduce((a, b) => a + b, 0);
        if (pack.items[itemId]?.coupon) {
            itemPrice = Coupon.discountedPrice(pack.items[itemId].coupon, itemPrice)
        }
        return acc + itemPrice;
    }, 0);

    return (
        <Animated.View style={[style, {
            opacity: animation,
            transform: [{ scale: animation.interpolate(Animation.keyframes(.8, 1)) }],
            overflow: 'hidden',
            backgroundColor: elevation.backgroundColor,
            borderRadius: Button.RADIUS,
        }]}>
            <Text style={[Typography.H6, { color: title, marginVertical: Spacing.M, textAlign: 'center' }]}>
                {pack.name}
            </Text>

            <Pressable onPress={closeModal} hitSlop={Spacing.XS} style={{ position: 'absolute', right: Spacing.XS, top: Spacing.XS }}>
                <Icon name="close-alt" size={36} color={error}/>
            </Pressable>

            <ScrollView
                bounces={false}
                fadingEdgeLength={96}
                overScrollMode="never"
                showsVerticalScrollIndicator={false}>
                
                { !!areas &&
                <FlatGrid 
                    data={areas}
                    renderItem={({ item: area, index: i }) => {
                        if (area.blank) return <View key={area.key} style={{ width: '50%' }}>
                            <Text style={[Typography.TITLE, {
                                color: title,
                                marginBottom: Spacing.M,
                                paddingHorizontal: Spacing.M,
                                paddingVertical: Spacing.XS,
                                backgroundColor: Colors.alphaBlend(primary, '#ffffff', .09)
                            }]}> </Text>
                        </View>

                        const initial = INITIAL_SELECTIONS[i];
                        const selection = selections[i];
                        const product = area.fungibleProducts.find(fp => fp.nodeId === selection.nodeId)?.node;
                        const isComplete = SelectionTree.isComplete(selection);
                        return (
                            <View
                                key={area.areaId + i}
                                style={[{ width: '50%' }, (i%2 ? {} : { borderRightWidth: 1, borderRightColor: line })]}
                            >
                                <Text style={[Typography.TITLE, {
                                    color: title,
                                    marginBottom: Spacing.M,
                                    paddingHorizontal: Spacing.M,
                                    paddingVertical: Spacing.XS,
                                    backgroundColor: Colors.alphaBlend(primary, '#ffffff', .09)
                                }]}>{i + 1}. {area.name}</Text>

                                {
                                    (isComplete || selection.shallowSolved)
                                        ? <SelectedProductInfo
                                            style={{ paddingBottom: Spacing.M }}
                                            hideDelete={initial.marked && initial.solved && initial.valid}
                                            hideEdit={initial.marked && initial.solved && initial.valid}
                                            onDeletePress={() => deleteItemSelection(i)}
                                            onPress={() => selectedProductInfoPress(product, i, selection)}
                                            product={product}
                                            selectionTree={SelectionTree.addMainSelection(selection, product)} />
                                        : <ScrollView
                                            contentContainerStyle={{
                                                flexWrap: 'wrap',
                                                flexDirection: 'row',
                                                paddingVertical: Spacing.XXS,
                                                paddingHorizontal: Spacing.XXS,
                                            }}
                                        >
                                            {area.fungibleProducts.map(fp => (
                                                <FungibleCard
                                                        displayModal={productCardPress(fp.node, i)}
                                                        key={fp.nodeId}
                                                        product={fp.node}
                                                        style={{ marginBottom: Spacing.M, marginHorizontal: Spacing.M }}
                                                        options={fp.options} />
                                            ))}
                                        </ScrollView>
                                }
                            </View>
                        )

                    }}
                    numColumns={2}
                />
                }



            </ScrollView>
            <View style={{ borderTopWidth: 1, borderColor: line, marginBottom: Spacing.XL }} />
            <Stepper value={quantity} setValue={(v) => {
                modalTracker.tap('QuantityStepper', {
                    action: 'updateQuantity',
                    quantity: v + ''
                })
                setQuantity(v)
            }} />

            <Button
                style={{ marginTop: Spacing.XL, borderRadius: 0 }}
                icon="cart-add"
                title={`${translate('addToCart', { quantity })} · ${parsePrice(selectionPrice, '€')}`}
                status={selections.every(s => SelectionTree.isComplete(s) || s.shallowSolved) ? 'default' : 'disabled'}
                onPress={() => {
                    if (selections.every(s => SelectionTree.isComplete(s) || s.shallowSolved)) {
                        modalTracker.tap('AddToCartButton', {
                            action: 'addToCart',
                        })
                        addToCartPress(selections.map(s => SelectionTree.toServer(s, { itemIdMap: itemIdByProductId, packId: pack.packId })), { quantity });
                        _dismissModal();
                    } else {
                        modalTracker.tap('AddToCartButton', {
                            action: 'invalid',
                        })
                        pushSnack(translate('selectOneOption'));
                    }
                }} />
        </Animated.View>
    )

}