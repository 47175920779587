import React from 'react';
import { View, Text, Pressable, StyleSheet, Dimensions } from 'react-native';
import { useTheme } from '@themeProvider';
import { Mixins, Icon, Typography, Spacing } from '@styles';

const deviceWidth = Dimensions.get('window').width;

/**
 * Action objects have the following properties:
 * 
 * {
 *  function,
 *  displayText,
 *  type, // This can be 'destructive', 'main' or none
 * }
 */

export const Alert = ({ _dismissModal, actions, title, text, style, ...props }) => {

    const { useElevation, useColor } = useTheme();
    const elevation = useElevation(24);
    const [ titleColor, body, destructive, secondary, line ] = useColor(['title', 'body', 'error', 'secondary', 'line']);

    const _wrapAction = (action) => async () => {
        if (!action) {
            _dismissModal();
            return;
        }
        const result = await action();
        if (result && _dismissModal) _dismissModal();
    }

    return (
        <View style={[styles.container, elevation ,style]} {...props}>
            <Text style={[Typography.TITLE, {color: titleColor, textAlign: 'center', marginHorizontal: Spacing.MARGINS}]}>{title}</Text>
            <Text style={[Typography.BODY, {color: body, textAlign: 'center', marginTop: Spacing.XS, marginHorizontal: Spacing.MARGINS}]}>{text}</Text>
            <View style={[Mixins.HORIZONTAL, styles.buttonContainer, { borderColor: line }]}>
            { actions.map((a, i) => {
                return <Pressable key={i} style={styles.textButton} onPress={_wrapAction(a.function)} >
                    <Text style={[ a.type === 'main' || actions.length === 1 ? Typography.TITLE : Typography.SUBTITLE, {
                        color: a.type === 'destructive' ? destructive : secondary,
                    }]}>{a.displayText}</Text>
                </Pressable>
            }) }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: Spacing.M,
        marginHorizontal: Spacing.MARGINS,
        ...Mixins.rounding(deviceWidth - 2*Spacing.MARGINS),
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    buttonContainer: {
        justifyContent: 'space-around',
        borderTopWidth: 1,
        marginTop: Spacing.M
    },
    textButton: {
        paddingVertical: Spacing.M,
        flexGrow: 1,
        alignItems: 'center',
    }
})