export const date = (val) => {
    if (!val || val === '') return null;
    if (val instanceof Date) return val;
    if (typeof val === 'number') return new Date(val);
    if (typeof val === 'string') {
        if (val.replaceAll('"', '').slice(-1) === 'Z') {
            return new Date(val.replaceAll('"', ''));
        }
        const [date, time] = val.replace('"', '').split('T');
        const [y, m, d] = date.split('-');
        const [h, min, s] = time ? time.split(':') : [0, 0, 0];
        return new Date(y, m - 1, d, h, min, s.split('.')[0]);
    }
    return null
}

export const timeKey = (date) => {
    const zeros = "0000";
    const year = (zeros + date.getFullYear().toString()).slice(-4);
    const month = (zeros + date.getMonth().toString()).slice(-2);
    const day = (zeros + date.getDate().toString()).slice(-2);
    return year + month + day;
};