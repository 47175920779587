import * as React from 'react';
import { Text, View, Pressable, StyleSheet } from 'react-native';
import {
    useNavigationBuilder,
    createNavigatorFactory,
    TabRouter,
    TabActions,
 } from '@react-navigation/native';

function TabNavigator({
    initialRouteName,
    children,
    screenOptions,
    tabBarStyle,
    tabBar,
    contentStyle,
    extraTabs=[],
    side='right',
}) {
    const { state, navigation, descriptors, NavigationContent } =
        useNavigationBuilder(TabRouter, {
            children,
            screenOptions,
            initialRouteName,
        });

    return (
        <NavigationContent>
            <View style={[StyleSheet.absoluteFill, { flexDirection: side === 'right' ? 'row' : 'row-reverse'}]}>
                { tabBar ?
                    tabBar({state, descriptors, navigation}) :
                    <View style={[tabBarStyle]}>
                        {state.routes.map((route) => {
                            return (
                            <Pressable
                                key={route.key}
                                onPress={() => {
                                    const event = navigation.emit({
                                        type: 'tabPress',
                                        target: route.key,
                                        canPreventDefault: true,
                                    });

                                    if (!event.defaultPrevented) {
                                        navigation.dispatch({
                                            ...TabActions.jumpTo(route.name),
                                            target: state.key,
                                        });
                                    }
                                }}
                                style={{ flex: 1 }}
                            >
                                {descriptors[route.key].options.tabItem}
                                <Text>{descriptors[route.key].options.title || route.name}</Text>
                            </Pressable>
                        )})}
                        { extraTabs }
                    </View>
                }
                <View style={[{ flex: 1 }, contentStyle]}>
                    {state.routes.map((route, i) => {
                        if ( i !== state.index ) {
                            return <></>
                        }
                        return (
                            <View
                                key={route.key}
                                style={[
                                    StyleSheet.absoluteFill,
                                    { display: i === state.index ? 'flex' : 'none' },
                                ]}
                            >
                                {descriptors[route.key].render()}
                            </View>
                        );
                    })}
                </View>
            </View>
        </NavigationContent>
    );
}

export const createSideTabNavigator = createNavigatorFactory(TabNavigator);