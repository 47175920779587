import * as Parser from './parsers';
import { Model } from './Model';

/**
 * Here are located some class that hasn't been determined yet where to locate them
 */

/**
 * Represents a Tag of Place or Event
 */
export class Tag extends Model {

    static get model () {
        return {
            code: '',
            name: '',
            mainColor: '#000000',
            type: '',
            hasValue: false,
            numericValue: 0
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, Tag.unserialize({ ...Tag.model, ...args }, { toClass: true }))
    }
}


export { Parser, Model };
export { Tree } from './_DataStructures';
export { ProductNode, ProductPack } from './Product';
export { Cart, SelectionTree, CartSelection } from './Cart';
export { Purchase, Order } from './Order';
export { OrderRecord } from './OrderRecord';
export { Promotion, Coupon, Discount } from './Promotion';
export { PickupBar, PreparationGroup } from './Place';
/* Bloat code to start a new Class

export class ClassName extends Model {

    static get uuid () { return ''; }
    
    static get model () {
        return {
        }
    }

    static get keymap () {
        return {
        }
    }

    static get parseMap () {
        return {            
        }
    }

    static get unserialMap () {
        return {
        }
    }

    static get toClassMap () {
        return {
        }
    }

    static get parseCallback () {
        return {
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, ClassName.unserialize({ ...ClassName.model, ...args }, {toClass: true}))
    }
}

*/