import React from 'react';
import { View, Text, ImageBackground, Pressable } from 'react-native';

import { Mixins, Typography, Spacing, Colors, Icon } from '@styles';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';

import { Button, Stepper } from '@atoms';
import { parsePrice } from '@utils/helpers';
import { Coupon, Promotion } from '@datamodel';

export const CartRow = ({ cartSelection, cartPrice, setSelectionQuantity, deleteSelection, productsInfo, maxQuantity, showStepper=true, allowEdit=true, packQuantity, promotions=[], style  }) => {

    const { useColor } = useTheme();
    const [title, body, primary, secondary, line] = useColor(['title', 'body', 'primary', 'secondary', 'line']);
    const [primaryText] = useColor([['primary', { isText: true }]]);
    const { translate } = useLanguage();
    const { nodes, leafs } = productsInfo;

    const infoNames = [nodes[cartSelection.item.nodeId].name, ...leafs.map(nodeId => nodes[nodeId].name)].filter(name => name !== nodes[cartSelection._mainSelection]?.name);

    return (
        <View style={[style, { paddingVertical: packQuantity ? Spacing.XS : Spacing.L }]}>

            <View style={[Mixins.HORIZONTAL]}>

                <View style={{ flex: 1 }}>
                    <View style={Mixins.HORIZONTAL}>
                        <View style={{ flex: 1 }}>
                            <Text style={[Typography.STRONG, { color: title, flex: 1 }]}>
                                {!!packQuantity && <Text style={{ color: primaryText }}>{cartSelection.quantity * packQuantity} </Text>}
                                {nodes[cartSelection._mainSelection]?.name}</Text>
                        </View>
                        {promotions.length > 0 &&
                            <View style={[Mixins.rounding(Typography.TITLE.lineHeight), {
                                marginTop: (Typography.TITLE.lineHeight - Typography.SMALL_COPY.lineHeight) / 2,
                                paddingHorizontal: Spacing.XXS,
                                backgroundColor: Colors.setOpacity(secondary, .25),
                                alignSelf: 'flex-start'
                            }]}>
                                <Text style={[Typography.SMALL_COPY, { color: secondary }]}>
                                    <Icon name="discount" size={Typography.SMALL_COPY} /> {promotions.length === 1 ? Promotion.getTitle(promotions[0], { short: true }) : translate('promo')}
                                </Text>
                            </View>
                        }
                        { !showStepper && allowEdit && <Button onPress={deleteSelection} icon="trash" size="condensed" color="destructive" type="transparent" style={{ width: Button.CONDENSED, paddingHorizontal: 0 }} /> }
                    </View>
                    {/* <View style={[Mixins.HORIZONTAL]}>
                    </View> */}
                        
                    <Text style={[Typography.BODY, { color: body }]}>
                        {infoNames.join(' · ')}
                    </Text>
                </View>
                
            </View>

            {
                !!showStepper && allowEdit &&
                <View style={[Mixins.HORIZONTAL, { alignItems: 'center', justifyContent: 'space-between', marginTop: Spacing.M }]}>
                    <Stepper
                        setValue={setSelectionQuantity}
                        max={maxQuantity}
                        value={cartSelection.quantity} size="x-condensed" />
                    <Text style={[Typography.TITLE, { color: primary }]}>{parsePrice(cartPrice, '€')}</Text>
                    <Button onPress={deleteSelection} icon="trash" size="x-condensed" color="destructive" type="transparent" style={{ width: Button.CONDENSED, paddingHorizontal: 0 }} />
                </View>
            }
        </View>
    )

}

export const CartPackRow = ({ pack, cartPack, style, packPrices, productsInfo, setPackQuantity }) => {
    const { useColor } = useTheme();
    const [title, body, primary, destructive, line] = useColor(['title', 'body', 'primary', 'error', 'line']);

    const selectionPackItemPrices = Object.keys(cartPack.selections).reduce((acc, _selId) => {
        let selection = cartPack.selections[_selId];
        let selectionPrice = packPrices[_selId] * selection.quantity;
        if (selection.item.packId in acc) {
            acc[selection.item.packId].push(selectionPrice);
        } else {
            acc[selection.item.packId] = [selectionPrice];
        }
        return acc;
    }, {});

    const totalPackPrice = Object.keys(selectionPackItemPrices).reduce((acc, packItemId) => {
        let itemPrice = selectionPackItemPrices[packItemId].reduce((a, b) => a + b, 0);
        if (pack.items[packItemId].coupon) {
            itemPrice = Coupon.discountedPrice(pack.items[packItemId].coupon, itemPrice)
        }
        return acc + itemPrice;
    }, 0);
    
    return (
        <View style={[style]}>
            <Text style={[Typography.H6, { color: title }]}>{pack.name}</Text>
            {
                Object.keys(cartPack.selections).map(_selId => {
                    const cartSelection = cartPack.selections[_selId];
                    return <CartRow
                        cartSelection={cartSelection}
                        key={_selId}
                        productsInfo={productsInfo[_selId]}
                        itemPrice={packPrices[_selId] * cartSelection.quantity}
                        allowEdit={false}
                        packQuantity={cartPack.quantity}
                    />
                }
                )
            }

            <View style={[Mixins.HORIZONTAL, { alignItems: 'center', justifyContent: 'space-between', marginTop: Spacing.XS }]}>
                <Stepper
                    setValue={setPackQuantity}
                    value={cartPack.quantity} size="x-condensed" />
                <Text style={[Typography.TITLE, { color: primary }]}>{parsePrice(Math.max(0, totalPackPrice * cartPack.quantity), '€')}</Text>
                <Button onPress={() => setPackQuantity(0)} icon="trash" size="x-condensed" color="destructive" type="transparent" style={{ width: Button.CONDENSED, paddingHorizontal: 0 }} />
            </View>
        </View>
    )
}