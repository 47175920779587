import React, { useEffect, useState, useRef, createRef } from 'react';
import { View, TouchableWithoutFeedback, StyleSheet, BackHandler, StatusBar, Animated } from 'react-native';
import { Alert, ActionSheet } from './modal-types';
import { useTheme } from '@themeProvider';
import { Colors } from '@styles';
import { withAnimated } from '@utils/animations';

const ModalContext = React.createContext({
    pushModal: () => { },
    pushAlert: () => { },
    pushActionSheet: () => { },
});

// EXAMPLES -- TODO: Document this better.
// pushAlert({
//     title: 'Hello',
//     text: 'Text',
//     actions: [{
//         function: () => { console.log('I close'); return true },
//         displayText: 'Cancel',
//         type: 'main'
//     }, {
//         function: () => { console.log('I delete'); return true },
//         displayText: 'Delete',
//         type: 'destructive'
//     }]
// })

// pushActionSheet({
//     children: <Text style={{borderWidth: 1}}>Hello</Text>,
//     actions: [{
//         function: () => { console.log('SEND'); return true },
//         displayText: 'Send',
//         type: 'destructive'
//     }, {
//         function: () => { console.log('SEND'); return true },
//         displayText: 'Send',
//     }]
// })

// Use this interface only when necessary
const modalProviderRef = createRef();
export const _pushModal = (args) => {
    if(modalProviderRef.current)
        modalProviderRef.current.pushModal(args);
}
export const _pushAlert = (args) => {
    if (modalProviderRef.current)
        modalProviderRef.current.pushAlert(args);
}

export const ModalProvider = (props) => {
    const [queue, setQueue] = useState([]);
    const currentQueue = useRef({});
    const index = useRef(0);

    const pushModal = (modal) => {
        //TODO: Parse modal - Sort Queue
        currentQueue.current[index.current] = {modal, id: index.current};
        index.current += 1;
        
        setQueue(Object.keys(currentQueue.current).map(i => currentQueue.current[i]))
    }

    const defaultModal = {
        pushModal,
        pushAlert: (alert, modalConfig) => {
            pushModal({
                ...modalConfig,
                children: React.createElement(Alert, alert)
            })
        },
        pushActionSheet: (actionSheet, modalConfig) => {
            pushModal({
                ...modalConfig,
                children: React.createElement(ActionSheet, actionSheet)
            })
        }
    }

    const popModal = (id) => {
        delete currentQueue.current[id];
        setQueue(Object.keys(currentQueue.current).map(i => currentQueue.current[i]))
    }

    useEffect(() => {
        // Initialise ref
        modalProviderRef.current = {...defaultModal};
    }, [])

    return (
        <ModalContext.Provider value={defaultModal}>
            {props.children}
            <View pointerEvents="box-none" style={[StyleSheet.absoluteFill, { justifyContent: 'center', overflow: 'hidden'}]}>
                {!!queue.length &&
                    queue.map( item => {
                        return <Modal
                            visible
                            key={item.id}
                            dismiss={() => popModal(item.id)}
                            onRequestClose={() => popModal(item.id)}
                            {...item.modal}
                        />
                    })
                }
            </View>
        </ModalContext.Provider>
    );
};

export const Modal = ({
    dismiss = () => { },
    children,
    backgroundColor = "#000000",
    opacity = .5,
    style,
    dismissOnOverlayPress = true,
    visible,
    onDismiss = () => { },
    onLayout = () => { },
    onRequestClose = () => { },
    ...props }) => {

    const { useColor } = useTheme();
    const background = useColor('background');

    const animation = useRef( new Animated.Value(0) ).current;

    const _dismissModal = () => {
        dismiss(); // Sets visible to false
        onDismiss(); // All other related logic
    }

    const overlayPress = () => {
        if (dismissOnOverlayPress) {
            _dismissModal();
        }
    }

    // Appends the modal close callback to every children passed to the component
    const _wrappedChildren = React.Children.map(children, child => React.cloneElement(child, { _dismissModal }));

    useEffect(() => {
        const backAction = () => {
            if (visible) {
                onRequestClose();
                onDismiss();
                return true;
            }
        };

        const backHandler = BackHandler.addEventListener(
            "hardwareBackPress",
            backAction
        );

        Animated.timing(animation, {
            duration: 100,
            toValue: 1,
            useNativeDriver: false,
        }).start();

        return () => backHandler.remove();
    }, [visible]);

    const AnimatedBar = withAnimated(StatusBar);
    const barColor = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [Colors.alphaBlend(backgroundColor, background, 0), Colors.alphaBlend(backgroundColor, background, opacity)],
    });

    return (
        <>
            {
                visible &&
                <View {...props} style={[style, StyleSheet.absoluteFill, { zIndex: 255 }]} onLayout={onLayout}>
                    {/* OVERLAY */}
                    <AnimatedBar backgroundColor={barColor}/>
                    <TouchableWithoutFeedback onPress={overlayPress} style={StyleSheet.absoluteFill}>
                        <Animated.View style={[
                            StyleSheet.absoluteFill, {
                                backgroundColor: Colors.setOpacity(backgroundColor, opacity),
                                opacity: animation,
                            }]} />
                    </TouchableWithoutFeedback>

                    {_wrappedChildren}
                </View>
            }
        </>
    )
}

export const useModal = () => React.useContext(ModalContext);
export default ModalProvider;