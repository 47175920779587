import React, { useState } from 'react';
import { useColorScheme } from 'react-native';
import { getColor, gradients } from '@styles/colors';
import { getElevationProps } from '@styles/mixins';
import { useSelector } from 'react-redux';

export const ThemeContext = React.createContext({
    isDark: false,
    useColor: () => { },
    useElevation: () => { },
    gradients,
    setScheme: () => { },
});

export const ThemeProvider = (props) => {
    const savedTheme = useSelector(state => state.settings?.theme || 'default')
    // Getting the device color theme, this will also work with react-native-web
    const colorScheme = useColorScheme(); // Can be dark | light | no-preference
    /*
    * To enable changing the app theme dynamicly in the app (run-time)
    * we're gonna use useState so we can override the default device theme
    */
    const [isDark, setIsDark] = useState(savedTheme === 'default' ? colorScheme === "dark" : savedTheme === "dark");

    // Listening to changes of device appearance while in run-time
    React.useEffect(() => {
        setIsDark(colorScheme === "dark");
    }, [colorScheme]);


    /**
     * This function overloads getColor from @styles/colors.
     * If color is a string, it's the same as getColor from @styles/color, if not,
     * it expects an array of objects with colorName and options
     * @param { String | Array } extractColor
     * @param { Object } options 
     * @returns color or color Array.
     */
    const getColorOverloaded = (forcedTheme) => (extractColor, options) => {
        if (!extractColor) return undefined;
        const themeFlag = !forcedTheme ? isDark : forcedTheme === 'dark' ? true : false;

        if (typeof (extractColor) === 'string')
            return getColor(themeFlag)(extractColor, options);

        if (Array.isArray(extractColor)) {
            return getColor(themeFlag)(...extractColor);
        }

        if (typeof extractColor === 'object' && !Array.isArray(extractColor)) {
            return getColor(themeFlag)(extractColor.name, extractColor.options);
        }

    }

    const getColorList = (colorList, options = {}) => {
        if (!Array.isArray(colorList)) {
            return getColorOverloaded()(colorList, options)
        }

        const colors = colorList.map(color => getColorOverloaded(options.forcedTheme)(color))
        return colors
    }

    const useElevation = (elevationValue, options = {}) => {
        const themeFlag = !options.forcedTheme ? isDark : options.forcedTheme === 'dark' ? true : false;
        return getElevationProps(themeFlag)(elevationValue);
    }

    const defaultTheme = {
        isDark,
        // Chaning color schemes according to theme
        useColor: getColorList,
        useElevation,
        gradients,
        // Overrides the isDark value will cause re-render inside the context.  
        setScheme: (scheme) => setIsDark(scheme === "dark"),
    };

    return (
        <ThemeContext.Provider value={defaultTheme}>
            {props.children}
        </ThemeContext.Provider>
    );
};

// Custom hook to get the theme object returns {isDark, colors, setScheme}
export const useTheme = () => React.useContext(ThemeContext);
export default ThemeProvider;