import React from 'react';
import { View, Text, Animated } from 'react-native';
import { useTheme } from '@themeProvider';
import { Mixins, Icon, Typography, Spacing } from '@styles';
import Animation from '@utils/animations';

export const Snackbar = ({ text, icon, style, onDurationEnd, id, type='message', ...props }) => {
    const animation = React.useRef( new Animated.Value(0) ).current;
    const { useColor, useElevation } = useTheme();
    const [success, error, warning] = useColor(['success', 'error', 'warning']);
    const elevation = useElevation(24);
    const backgroundColor = { success, error, warning, message: elevation.backgroundColor }[type];
    const title = useColor('title', { on: backgroundColor })

    React.useEffect(() => {
        // console.log('#1# Mounted', id);
        Animated.timing(animation, Animation.create(1, 200)).start(({ finished }) => {
            // console.log('#2# Finished animation');
            if (finished) onDurationEnd(callback);
        })
    }, [text, id])

    const callback = (unmount) => {
        // console.log('#5# Start close animation');
        Animated.timing(animation, Animation.create(0, 300)).start(({ finished }) => {
            // console.log('#6# Finish close animation');
            if (finished) unmount();
        })
    }

    return (
        <Animated.View {...props}
            style={[Mixins.rounding(40), {
                ...elevation,
                opacity: animation,
                transform: [{ scale: animation }],
                paddingHorizontal: Spacing.M,
                paddingVertical: Spacing.XS,
                backgroundColor,
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center'
            }, style]}>
            {!!icon && <Icon name={icon} size={24} style={{ marginRight: 8 }} color={title} />}
            <Text style={[Typography.BODY, { color: title }]}>{text}</Text>
        </Animated.View>
    )
}

Snackbar.SHORT = 2000;
Snackbar.MEDIUM = 4000;
Snackbar.LONG = 7000;
Snackbar.MARGINS = Spacing.MARGINS;
Snackbar.BOTTOM = Spacing.M + 48; // 48 == TABBAR HEIGHT