import { Platform } from 'react-native';
import { createIconSet } from '@expo/vector-icons';

// export const loadFonts = () => useFonts({
//     // 'Poppins-Light': require('@assets/fonts/Poppins-Light.ttf'), -- Might be not needed, add to the project if so
//     'Poppins-Regular': require('@assets/fonts/Poppins-Regular.ttf'),
//     'Poppins-Italic': require('@assets/fonts/Poppins-Italic.ttf'),
//     // 'Poppins-Medium': require('@assets/fonts/Poppins-Medium.ttf'), -- Might be not needed, add to the project if so
//     'Poppins-SemiBold': require('@assets/fonts/Poppins-SemiBold.ttf'),
//     'Flowing-Icons': require('@assets/fonts/Flowing-Icons.ttf')
// });

export const FONTS = {
    // 'Poppins-Light': require('@assets/fonts/Poppins-Light.ttf'), -- Might be not needed, add to the project if so
    'Poppins-Regular': require('@assets/fonts/Poppins-Regular.ttf'),
    'Poppins-Italic': require('@assets/fonts/Poppins-Italic.ttf'),
    // 'Poppins-Medium': require('@assets/fonts/Poppins-Medium.ttf'), -- Might be not needed, add to the project if so
    'Poppins-SemiBold': require('@assets/fonts/Poppins-SemiBold.ttf'),
    'Flowing-Icons': require('@assets/fonts/Flowing-Icons.ttf'),
}

const glyphMap = require('@assets/fonts/Flowing-Icons.json');
export const FlowingIcon = createIconSet(glyphMap, 'Flowing-Icons', '@assets/fonts/Flowing-Icons.ttf');


// export const H1 = {
//     fontFamily: 'Poppins-Light',
//     fontSize: 96,
//     lineHeight: 120,
//     letterSpacing: -1.5
// }

// export const H2 = {
//     fontFamily: 'Poppins-Light',
//     fontSize: 60,
//     lineHeight: 72,
//     letterSpacing: -0.5
// }

export const H3 = {
    fontFamily: 'Poppins-Regular',
    fontSize: 48,
    lineHeight: 64,
}

export const H4 = {
    fontFamily: 'Poppins-Regular',
    fontSize: 32,
    lineHeight: 40,
    letterSpacing: 0.25
}

// export const H5 = {
//     fontFamily: 'Poppins-Medium',
//     fontSize: 24,
//     lineHeight: 32,
//     letterSpacing: 0
// }

export const H6 = {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 20,
    lineHeight: 28,
    letterSpacing: 0.15
}

export const STRONG_TEXT_BUTTON = {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 20,
    lineHeight: 28,
    letterSpacing: 0.15
}

export const TEXT_BUTTON = {
    fontFamily: 'Poppins-Regular',
    fontSize: 20,
    lineHeight: 28,
    letterSpacing: 0.15
}

export const TITLE = {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 17,
    lineHeight: 24,
    letterSpacing: 0.15
}

export const SUBTITLE = {
    fontFamily: 'Poppins-Regular',
    fontSize: 17,
    lineHeight: 24,
    letterSpacing: 0.15
}

export const CAPTION = {
    fontFamily: 'Poppins-Italic',
    fontSize: 12,
    lineHeight: 14,
    marginTop: -2,
    letterSpacing: 0.5
}

export const OVERLINE = {
    fontFamily: 'Poppins-Regular',
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: 1.5,
    marginTop: -2,
    textTransform: 'uppercase'
}

export const XSMALL_COPY = {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 12,
    lineHeight: 12,
}

export const BODY = Platform.select({
    ios: {
        fontSize: 17,
        lineHeight: 22,
    },
    android: {
        fontSize: 16,
        lineHeight: 22,
        letterSpacing: 0.5
    },
    default: {
        fontSize: 16,
    }
})

export const STRONG = Platform.select({
    ios: {
        fontSize: 17,
        lineHeight: 22,
        fontWeight: 'bold'
    },
    android: {
        fontSize: 17,
        lineHeight: 22,
        letterSpacing: 0.5,
        fontWeight: 'bold'
    },
    default: {
        fontSize: 17,
        lineHeight: 22,
        fontWeight: 'bold'
    }
})

export const SMALL_COPY = Platform.select({
    ios: {
        fontSize: 15,
        lineHeight: 20
    },
    android: {
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: 0.25
    },
    default: {
        fontSize: 15,
        lineHeight: 20
    },
})

// Working example     src\assets\
// export default props => {
//     let [fontsLoaded, error] = useFonts({
//         'Inter-Black': require('./assets/fonts/Inter-Black.otf'),
//     });

//     if (!fontsLoaded) {
//         return <AppLoading />;
//     } else {
//         return (
//             <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
//                 <Text style={{ fontFamily: 'Inter-Black', fontSize: 40 }}>Inter Black</Text>
//                 <Text style={{ fontSize: 40 }}>Platform Default</Text>
//             </View>
//         );
//     }
// };