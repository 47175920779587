export class Tree {

    static _getLeafs(tree) {
        const leafs = [];

        const getLeafs = (tree) => {
            if (Object.keys(tree.children).length <= 0) {
                leafs.push(tree);
            }
        }

        this._tranverseTree(tree, getLeafs);
        return leafs;
    }

    static _addChildren(tree, children) {
        tree.children = { ...tree.children, ...children }
        return tree;
    }

    static _tranverseTree(tree, func) {
        func(tree);

        Object.keys(tree.children).forEach(k => {
            this._tranverseTree(tree.children[k], func);
        })
    }

    static _tranverseReverseTree(tree, func) {
        Object.keys(tree.children).forEach(k => {
            this._tranverseReverseTree(tree.children[k], func);
        })

        func(tree);
    }

    static flat(tree, { keepStructure = true } = {}) {
        const flatten = {};
        const func = (node) => {
            if (!keepStructure) {
                const { children, ...rest } = node;
                flatten[node.nodeId] = rest;
            } else {
                flatten[node.nodeId] = node;
            }
        }
        try {
            this._tranverseTree(tree, func);
        } catch (error) {
            console.error(JSON.stringify(tree))
        }
        return flatten;
    }

    static getTreeNode(tree, nodeId) {
        const nodes = this.getTreeNodes(tree, [nodeId]);
        if (nodeId in nodes) {
            return nodes[nodeId];
        }
    }

    static getTreeNodes(tree, nodes = []) {

        const nodeSet = new Set(nodes);
        const result = {};
        const func = (node) => {

            if (nodeSet.has(node.nodeId)) {
                result[node.nodeId] = node;
            }
        }

        this._tranverseTree(tree, func);
        return result;
    }

    static findAndReplace(tree, nodeId, config = {}) {
        const { transform, hard } = { transform: n => n, hard: false, ...config };

        if (tree.nodeId === nodeId) {
            if (hard) {
                tree = transform(tree);
            } else {
                tree = { ...tree, ...transform(tree) }
            }
        }

        Object.keys(tree.children).forEach(id => {
            tree.children[id] = this.findAndReplace(tree.children[id], nodeId, config)
        })

        return tree;
    }
}