import React, { useEffect, useRef, useMemo } from 'react';
import { View, Text, StyleSheet, Pressable, ImageBackground, Image, Dimensions, Animated } from 'react-native';
import { Spacing, Mixins, Typography, Colors, Commons } from '@styles';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { Button, GeneralTag, BaseAvatar } from '@atoms';
import { parsePrice } from '@utils/helpers';
import { SmartWrapView } from '@lib';
import { Promotion, ProductNode, SelectionTree, Coupon } from '@datamodel';
import Animation from '@utils/animations';
import { shuffle } from '@utils/helpers';

const { width: dWidth, height: dHeight } = Dimensions.get('window');

export const ProductNodeCard = ({ product, offers={}, couponAvailable, style, numberInCart=0, pressThrough, ...props }) => {
    const { useColor, useElevation } = useTheme();
    const { translate } = useLanguage();
    const [title, label, primary, body, backgroundColor, secondary, inputBackground] = useColor(['title', 'label', 'primary', 'body', 'background', 'secondary', 'inputBackground']);
    const [ numberColor ] = useColor([
        [ 'title', { on: primary } ]
    ])

    const soldOut = ProductNode.isSoldOut(product);
    const elevation = useElevation(4);

    return (
        <Pressable {...props} disabled={soldOut && !pressThrough} style={[style, styles.productCard, elevation, Mixins.rounding(ProductNodeCard.HEIGHT)]}>

            <ImageBackground 
                style={{
                    width: ProductNodeCard.HEIGHT,
                    height: ProductNodeCard.HEIGHT,
                    ...Mixins.matchRounding(Mixins.rounding(ProductNodeCard.HEIGHT), -Spacing.XS),
                    overflow: 'hidden',
                }}
                source={{ uri: product.photo }}
                resizeMode='contain'
            />

            {
                !!soldOut &&
                <View style={{
                    position: 'absolute',
                    width: ProductNodeCard.HEIGHT,
                    height: ProductNodeCard.HEIGHT,
                    backgroundColor: Colors.setOpacity(label, .5),
                    ...Mixins.matchRounding(Mixins.rounding(ProductNodeCard.HEIGHT), -Spacing.XS),
                }} />
            }

            <View style={{ flex: 1, position: 'absolute', width: ProductNodeCard.HEIGHT, bottom: Spacing.XS}}>
                
                <View style={{ backgroundColor: Colors.setOpacity(elevation.backgroundColor, .7)}}>

                    <Text numberOfLines={2} textBreakStrategy="simple" style={[Typography.TITLE, { color: soldOut ? label : title, marginTop: Spacing.XS, textAlign: 'center', fontSize: 14, lineHeight: 16 }]}>{product.name}</Text>
                </View>
                
                {/* {
                    !!product.description &&
                    <Text style={[Typography.BODY, { color: body }]}>{ product.description }</Text>
                } */}

            </View>

            { numberInCart >= 1 &&
                <View 
                    style={{
                        borderWidth: 2,
                        borderColor: elevation.backgroundColor,
                        width: Typography.SMALL_COPY.lineHeight * 1.3,
                        height: Typography.SMALL_COPY.lineHeight * 1.3,
                        ...Mixins.rounding(Typography.SMALL_COPY.lineHeight * 1.3),
                        backgroundColor: primary,
                        justifyContent: 'center',
                        position: 'absolute',
                        left: ProductNodeCard.HEIGHT - 1/4 * 1.3 * Typography.SMALL_COPY.lineHeight,
                        top: ProductNodeCard.HEIGHT - 1/4 * 1.3 * Typography.SMALL_COPY.lineHeight,
                        // top: - Typography.SMALL_COPY.lineHeight * 1.3 / 4,
                    }}
                >
                <Text style={[Typography.STRONG, { textAlign: 'center', color: numberColor, fontSize: Typography.SMALL_COPY.fontSize }]}>{numberInCart}</Text>
                </View>
            }

        </Pressable>
    )

}

const _tabBarSize = 250;
const _nItems = 4;
const _L = dWidth - _tabBarSize;
const _row = _L - Math.max(1/4 * _L, Commons.cartMenuWidth) - 2*Spacing.MARGINS;
ProductNodeCard.HEIGHT = Math.min(100, Math.floor(_row/_nItems - Spacing.M - 2*Spacing.XS));


export const FungibleCard = ({ wiggle, style, product, options, offers = {}, coupons = {}, cartCount = {}, displayModal, pressThrough, ...props }) => {
    const animation = useRef(new Animated.Value(0)).current;

    const getKeyframes = () => {
        const KFS = ['0deg', '-1.5deg', '2.5deg', '2.5deg', '-1.5deg'];
        const newKeyframes = shuffle([...KFS]);
        return ['0deg', ...newKeyframes, '0deg'];
    }

    useEffect(() => {
        if ( wiggle ) {
            Animation.loop(animation, 5, 500, 0, 'pBall', { p: 1.5 })();
        }
        return () => {
            Animated.timing(animation, Animation.medium(0)).start();
        };
    }, [wiggle])

    return (<>
        {!options || options.length === 0 ?
            <Animated.View
                style={[style, {
                    transform: [{ rotate: animation.interpolate(Animation.keyframes(...getKeyframes()))}]
                }]}>
                <ProductNodeCard
                    offers={offers}
                    couponAvailable={Object.keys(coupons).length > 0}
                    numberInCart={cartCount[product.nodeId]}
                    onPress={() => displayModal(product)}
                    pressThrough={pressThrough}
                    product={product}
                    {...props} />
            </Animated.View>
            :
            <>
                {options.map(option => (
                    <Animated.View
                        key={option.nodeId} style={[style, {
                            transform: [{ rotate: animation.interpolate(Animation.keyframes(...getKeyframes())) }]
                    }]}>
                        <ProductNodeCard
                            offers={offers}
                            couponAvailable={Object.keys(coupons).length > 0}
                            numberInCart={cartCount[option.nodeId]}
                            onPress={() => displayModal(option)}
                            pressThrough={pressThrough}
                            product={option}/>
                    </Animated.View>
                ))}
            </>
        }
    </>)
}


const PackItem = ({ packItem, style, photoStyle, ...props }) => {
    const { useColor } = useTheme();
    const [body, primaryText, primary] = useColor(['body', ['primary', { isText: true }], 'primary']);

    const contrastedPrimary = Colors.withMinimumWhiteContrast(primary, 18);

    return <View style={[Mixins.HORIZONTAL, { alignItems: 'center' }, style]} {...props}>
        <ImageBackground source={{ uri: packItem.product.photo }} style={[photoStyle, { height: 40, width: 40, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }]}>
            <View style={[StyleSheet.absoluteFill, { backgroundColor: Colors.setOpacity(contrastedPrimary, .35) }]} />
            <Text style={[Typography.TITLE, { color: 'white', textAlign: 'center', zIndex: 1 }]}>{packItem.quantity}</Text>
        </ImageBackground>
        <Text style={[Typography.SMALL_COPY, { color: body, marginLeft: Spacing.XS }]}>{packItem.product.name}</Text>
    </View>
}

export const ProductPackCard = ({ pack, style, cartCount, ...props }) => {
    const { useColor, useElevation } = useTheme();
    const elevation = useElevation(4);
    const rounding = Mixins.rounding(ProductNodeCard.HEIGHT);
    const [title, body, primaryText, line, label, backgroundColor] = useColor([
        'title',
        'body',
        ['primary', { isText: true }],
        'line',
        'label',
        'background',
    ])

    const [numberColor] = useColor([
        ['title', { on: primaryText }]
    ]);

    const packItems = Object.keys(pack.items).map(id => pack.items[id]);

    const [price, originalPrice] = useMemo(() => {
        return Object.keys(pack.items).reduce((acc, itemId) => {
            const item = pack.items[itemId];
            const fromPrice = ProductNode.calculateTreeFromPrices(item.product);

            // TODO: Check this
            let unitPrice = item.product.maxChildren === 0 ? item.product.price.amount : fromPrice[item.product.nodeId] + item.product.price.amount;
            let itemPrice = unitPrice * item.quantity;
            let ogItemPrice = itemPrice;
            if (item.coupon) {
                itemPrice = Coupon.discountedPrice(item.coupon, ogItemPrice);
            }
            return [acc[0] + itemPrice, acc[1] + ogItemPrice];
        }, [0, 0]);
    });

    return <Pressable style={[elevation, rounding, style]} {...props}>
        <View style={[Mixins.HORIZONTAL, { borderBottomWidth: 1, borderColor: line }]}>
            <Image style={{ width: ProductNodeCard.HEIGHT, borderTopLeftRadius: rounding.borderRadius }} source={{ uri: pack.photo }} />
            <View style={{ marginLeft: Spacing.M, paddingVertical: Spacing.XS, flex: 1 }}>
                <Text style={[Typography.TITLE, { color: title }]}>{pack.name}</Text>
                <Text style={{ textAlignVertical: 'bottom' }}>
                    <Text style={[Typography.TITLE, { color: false && soldOut ? label : primaryText }]}>{parsePrice(price, '€')}  </Text>
                    {price < originalPrice &&
                        <Text style={[Typography.TITLE, { color: label, textDecorationLine: 'line-through' }]}>{parsePrice(originalPrice, '€')}</Text>
                    }
                </Text>

            </View>
        </View>

        <Text numberOfLines={2} style={[Typography.SUBTITLE, { color: body, marginHorizontal: Spacing.S, marginTop: Spacing.XS }]}>{pack.description}</Text>
        <View style={{ flex: 1 }}/>
        <SmartWrapView flexDirection='row' crossDirectionSize={40 + Spacing.XS} style={{ marginBottom: Spacing.S }}>
            {packItems.map(packItem => <PackItem key={packItem.itemId} style={{ marginLeft: Spacing.S, marginTop: Spacing.XS }} packItem={packItem} photoStyle={Mixins.matchRounding(rounding, -Spacing.S)} />)}
        </SmartWrapView>

        {cartCount >= 1 &&
            <View
                style={{
                    borderWidth: 2,
                    borderColor: backgroundColor,
                    width: Typography.SMALL_COPY.lineHeight * 1.3,
                    height: Typography.SMALL_COPY.lineHeight * 1.3,
                    ...Mixins.rounding(Typography.SMALL_COPY.lineHeight * 1.3),
                    backgroundColor: primaryText,
                    justifyContent: 'center',
                    position: 'absolute',
                    bottom: -1 / 4 * 1.3 * Typography.SMALL_COPY.lineHeight,
                    right: -1 / 4 * 1.3 * Typography.SMALL_COPY.lineHeight,
                }}
            >
                <Text style={[Typography.STRONG, { textAlign: 'center', color: numberColor, fontSize: Typography.SMALL_COPY.fontSize }]}>{cartCount}</Text>
            </View>
        }

    </Pressable>
}

export const SelectedProductInfo = ({ product, selectionTree, style, hideDelete, hideEdit, onDeletePress, ...props }) => {
    const { translate } = useLanguage();
    const { useColor, useElevation } = useTheme();
    const [line, body, title, primary] = useColor(['line', 'body', 'title', ['primary', { isText: true }]]);
    const mainNode = ProductNode.getTreeNodes(product, [selectionTree._mainSelection])[selectionTree._mainSelection];
    const leafs = SelectionTree.getMarkedLeafs(selectionTree).map(l => l.name).filter(n => n !== mainNode.name);
    return <Pressable style={[Mixins.HORIZONTAL, { alignItems: leafs.length > 0 ? 'flex-start' : 'center', paddingHorizontal: Spacing.M }, style]} {...props}>
        <View style={[styles.productCard, Mixins.rounding(ProductNodeCard.HEIGHT + Spacing.M), {
            borderWidth: 1,
            padding: Spacing.M,
            borderColor: line,
        }]}>
            <ImageBackground
                style={{
                    width: ProductNodeCard.HEIGHT,
                    height: ProductNodeCard.HEIGHT,
                    ...Mixins.matchRounding(Mixins.rounding(ProductNodeCard.HEIGHT + Spacing.M), -Spacing.XS),
                    overflow: 'hidden',
                }}
                source={{ uri: mainNode?.photo }}
                resizeMode='contain'
            />
        </View>

        <View style={{ marginHorizontal: Spacing.XS, flex: 1 }}>
            <View style={[{ alignItems: 'center', marginLeft: Spacing.XS }]}>
                <Text style={[Typography.SUBTITLE, { color: title, flex: 1, overflow: 'hidden' }]}>{mainNode.name}</Text>
                <Text style={[Typography.TITLE, { color: primary }]}>{parsePrice(SelectionTree.calculatePrice(selectionTree), '€')}</Text>
            </View>
            {leafs.length > 0 &&
                <Text style={[Typography.BODY, { color: body, textAlign: 'center' }]}>{leafs.join(' · ')}</Text>
            }
            <View style={[Mixins.HORIZONTAL, { gap: Spacing.M, marginTop: Spacing.M }]}>
                {!hideEdit && 
                    <Button onPress={props.onPress} type="transparent" color="secondary" icon="edit" title={translate('edit')} style={{ flexGrow: 1}}/>
                }
                {!hideDelete &&
                    <Button onPress={onDeletePress} type="transparent" icon="trash" title={translate('remove')} color="destructive" style={{ flexGrow: 1}}/>
                }
            </View>
        </View>
    </Pressable>
}


const styles = StyleSheet.create({
    bundleContainer: {
        paddingLeft: Spacing.M
    },
    productCard: {
        padding: Spacing.XS,
        justifyContent: 'center',
        alignItems: 'center'
    },
    decoration: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: 2
    },
    cardContainer: {
        ...Mixins.matchRounding(Mixins.rounding(Button.HEIGHT), Spacing.M)
    },
    topCardContainer: {
        paddingVertical: Spacing.XL,
        paddingHorizontal: Spacing.XXL,
        ...Mixins.matchRounding(Mixins.rounding(Button.HEIGHT), 0),
    },
    bottomCardContainer: {
        overflow: 'hidden',
        paddingHorizontal: Spacing.M,
        paddingBottom: Spacing.M,
        ...Mixins.matchRounding(Mixins.rounding(Button.HEIGHT), 0),
    },
})