export class PrintService {
    constructor(mode = 'escpos') {
        this.printURL = 'print://escpos.org/escpos/usb/print';
        this.mode = mode;
    }

    print({ html, copies }) {
        console.log(this.mode);
        if (this.mode === 'escpos') {
            this._escPosPrint({ html, copies });
        }
        else if (this.mode === 'system') {
            this._systemPrint({ html, copies });
        };
    }

    _escPosPrint({ html, copies }) {
        if (copies === undefined) {
            copies = 1;
        }
        const queryParams = new URLSearchParams({
            srcTp: 'uri',
            srcObj: 'html',
            numCopies: copies,
            src: `data:text/html,${encodeURIComponent(html)}`,
        });
        window.location.href = 'print://escpos.org/escpos/usb/print?' + queryParams.toString();
    }

    _systemPrint({ html, copies }) {
        if (copies !== undefined || copies !== 1) {
            console.warn('Copies are not supported with iframe printing');
            copies = 1;
        }

        const iframe = document.querySelector('#printf');
        const iwindow = iframe.contentWindow;

        iwindow.document.write(html);
        iwindow.document.close();

        return this._printIframeAsync(iframe);
    }

    _printIframeAsync(iframe) {
        return new Promise((resolve, reject) => {
            if (!iframe || !iframe.contentWindow) {
                reject();
                return;
            }

            const iwindow = iframe.contentWindow;
            const endPrint = () => {
                console.log('endPrint');
                resolve();
            }

            window.addEventListener('focus', endPrint, { once: true });
            iwindow.print();
            window.focus();
        })
    }

}

export const printService = new PrintService();