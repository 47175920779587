import Constants from 'expo-constants';
import Sinkhole from '@negative-epsilon/sinkhole';

const MANIFEST = Constants.expoConfig;

export const TRACKING_CONFIG = {
    path: '',
    hostname: '',
    pickupBar: ''
}

const REALM_ID = MANIFEST.extra?.SINKHOLE_REALM_ID;
const SINKHOLE_API_KEY = MANIFEST.extra?.SINKHOLE_API_KEY;


const sinkhole = new Sinkhole({
    api_key: SINKHOLE_API_KEY,
    realm: REALM_ID,
})

const HOW = {
    TAP: 'click',
    SCROLL: 'scroll',
    RETRIEVE: 'retrieve',
    VISIT: 'visit',
    CANCEL: 'cancel',
    SEARCH: 'search',
    START: 'start',
    COMPLETE: 'complete',
    PRINT_TICKET: 'print_ticket'
}


const record = async (event_type, metadata) => {
    // Build event name with how, what and where if available.
    // Where is screen, what is what, how is event_type
    const { what, screen, ...properties } = metadata;
    const eventName = `${event_type}:${metadata.what}:${metadata.screen}`;

    // Remove undefined value
    Object.keys(properties).forEach(key => properties[key] === undefined && delete properties[key]);

    sinkhole.track({
        name: eventName,
        properties,
        user_id: TRACKING_CONFIG.userId
    });
};

const tap = (what, metadata) => {
    record(HOW.TAP, { what, ...metadata });
}

const scroll = (what, metadata) => {
    record(HOW.SCROLL, { what, ...metadata });
}

const printTicket = (what, metadata) => {
    record(HOW.PRINT_TICKET, { what, ...metadata });
}

export class Tracker {
    constructor(screen, component = 'RootView', placeId) {
        this.screen = screen;
        this.component = component;
        this.placeId = placeId;
    }

    static from(tracker) {
        return new this(tracker.screen, tracker.component, tracker.placeId);
    }

    setComponent(component) {
        this.component = component;
        return this;
    }

    setParams(params) {
        Object.assign(this, params);
        return this;
    }

    scroll(what, metadata) {
        if (this.contentObjectId) {
            scroll(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
                content_object_id: this.contentObjectId,
                content_object_type: this.contentObjectType
            });
        } else {
            scroll(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
            });
        }
    }

    tap(what, metadata) {
        if (this.contentObjectId) {
            tap(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
                content_object_id: this.contentObjectId,
                content_object_type: this.contentObjectType
            });
        } else {
            tap(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
            });
        }
    }

    printTicket(what, metadata) {
        if (this.contentObjectId) {
            printTicket(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
                content_object_id: this.contentObjectId,
                content_object_type: this.contentObjectType
            });
        } else {
            printTicket(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
            });
        }
    }
}