import service from '@services';
import { Order, Purchase, OrderRecord } from '@datamodel';

const PREFIX = '/validation/2.0';

export const validate = async (token) => {
    const data = JSON.parse(token);
    const response = await service.post(`/pickupbars/${service.bar_id}${PREFIX}/validate/`, { tokens: data });
    if (response.status === 'ok') {
        return {
            status: 'ok',
            orderRecord: OrderRecord.parse(response.response)
        }
    }

    return service.error(response);
}