if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (str, newStr) {

        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }

        // If a string
        return this.replace(new RegExp(str, 'g'), newStr);

    };
}

// React && Own-lib
import { useState } from 'react';
import { Platform } from 'react-native';
import Navigator from '@navigations';
import { Typography } from '@styles';
import service from '@services';

// Providers
import ThemeProvider from '@themeProvider';
import LanguageProvider from '@languageProvider';

// Native libs
import * as Font from 'expo-font';
import * as Updates from 'expo-updates';
import AppLoading from 'expo-app-loading';
import Constants from 'expo-constants';
import * as Sentry from 'sentry-expo';

// Redux related
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '@redux/store';
import { ModalProvider, SnackbarProvider } from '@lib';

// (async () => {
//     await new Promise(r => setTimeout(r, 1000));
// })()


Sentry.init({
    dsn: 'https://e79067d910734693b97f035360d21d71@glitchtip.corp.negativeepsilon.com/17',
    enableInExpoDevelopment: true,
    environment: Constants.manifest.extra.SENTRY_ENVIRONMENT,
    debug: __DEV__, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});


export default function App() {

    const [appReady, setAppReady] = useState(false);
    const startAsync = async () => {
        if (!__DEV__ && Platform.OS !== 'web') {
            const { isAvailable } = await Updates.checkForUpdateAsync();
            if (isAvailable) {
                const { isNew } = await Updates.fetchUpdateAsync();
                if (isNew) {
                    await Updates.reloadAsync();
                }
            }
        }

        return Font.loadAsync({ ...Typography.FONTS });
    }


    if (!appReady) {
        return (<AppLoading
            style={{ flexGrow: 1 }}
            startAsync={startAsync}
            onFinish={() => { setAppReady(true) }}
            onError={console.warn}
        />)
    }

    return (
        <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
        <LanguageProvider>
        <ThemeProvider>
        <SnackbarProvider>
        <ModalProvider>
            <Navigator />
            {/* Only in web browsers */}
            {Platform.OS === "web" &&
                <iframe id="printf" key="printf" style={{ height: 0, width: 0, border: 0 }}/>
            }
        </ModalProvider>
        </SnackbarProvider>
        </ThemeProvider>
        </LanguageProvider>
        </PersistGate>
        </Provider>
    );
}
