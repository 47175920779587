// This files includes all smaller components that displays an Avatar of any kind
import React from 'react';
import { View, Image, StyleSheet, ImageBackground } from 'react-native';
import { Mixins } from '@styles';
import { useTheme } from '@themeProvider';
import { LinearGradient } from 'expo-linear-gradient';

export const BaseAvatar = ({ size, border, gradientColors, gradientBorder, borderColor, source, style, ...props}) => {
    const { useColor } = useTheme();
    const [ backgroundColor, ] = useColor(['background']);

    const sizes = {
        XS: { size: 20, border: 1 },
        S: { size: 32, border: 2 },
        M: { size: 48, border: 2 },
        L: { size: 80, border: 2 },
    }

    const getStyle = (size, border) => {
        const baseRadius = Mixins.rounding(sizes[size].size, true);
        if (border) {
            const innerRadius = Mixins.matchRounding(baseRadius, 2*sizes[size].border);
            return {
                width: sizes[size].size + 2 * sizes[size].border,
                height: sizes[size].size + 2 * sizes[size].border,
                ...innerRadius,
                borderColor: backgroundColor,
                borderWidth: sizes[size].border,
                overflow: 'hidden'
            }
        } else {
            return { width: sizes[size].size+sizes[size].border*2, height: sizes[size].size+sizes[size].border*2, ...baseRadius, borderColor: backgroundColor}
        }
    }

    return (
        <>
            { border &&
                <View {...props} style={[style, {
                    width: sizes[size].size + 4 * sizes[size].border,
                    height: sizes[size].size + 4 * sizes[size].border,
                    ...Mixins.matchRounding(Mixins.rounding(sizes[size].size, true), 3*sizes[size].border),
                    backgroundColor: borderColor,
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden'
                }]}>
                    { gradientBorder &&
                        <LinearGradient style={StyleSheet.absoluteFill}
                            start={[0, 0]}
                            end={[1, 1]}
                            colors={gradientColors}
                        />
                    }
                    <ImageBackground source={source} style={getStyle(size, border)} source={source}/>
                </View>
            }
            { !border &&
                <Image source={source} style={[style, getStyle(size, border)]} source={source} {...props}/>
            }
        </>
    )
}


BaseAvatar.SIZES = {
    XS: { size: 20, border: 1 },
    S: { size: 32, border: 2 },
    M: { size: 48, border: 2 },
    L: { size: 80, border: 2 },
}