import Constants from 'expo-constants';

// By sizing
export const XXS = 4;
export const XS = 8;
export const S = 12;
export const M = 16;
export const L = 20;
export const XL = 24;
export const XXL = 32;
export const H = 48;
export const XH = 64;

// SEMANTICS.
// These sizings are named by usage, use these when possible.
export const STATUSBAR = Constants.statusBarHeight;
export const NAVBAR = 56;
export const TABBAR = 64;
export const MARGINS = XL;