import React from 'react';
import { Easing, Animated } from 'react-native';
/**
 * A collection of mappings
 *
 * These functions are defined on [0, 1], which represents the fraction of time
 * consumed by the animation.
 * The output represents the fraction of the animation value distance.
 *
 * Simple example:
 *    animation = new Animated.Value(3);
 *
 *    Animated.timing(animation, {
 *      easing: pBall(),
 *      toValue: 5,
 *      duration: 250,
 *    }).start()
 *
 *   At t=0ms, animation value is 3
 *
 *   At t=125ms, a 50% of the animation has been completed, so
 *   pBall()(0.5) is called, which returns: (1 - (1-0.5)**2 ) ** (1/2) --> sqrt(1 - 1/4) --> 0.866
 *   so the animation.value will be updated with a 86.6% of de increment, so we get:
 *   increment = (toValue - fromValue) = (5 - 3) = 2 ---> animation.value = fromValue + 0.866*increment = 4.372
 *
 *   At t=250ms we call pBall(0)(1) which return 1, so animation.value will be updated with fromValue + 1*increment = 5.
 *
 *
 *  Interpolated example:
 *  TODO: Write this
 */

/**
    Linear animation.
 */
const linear = () => t => t;

/**
    5th grade polinomial animation.

    Input: v0 -> Speed value in the middle of the animation. A negative value
           makes it go backwards.

    A representation can be seen here:
    https://www.desmos.com/calculator/dh0yffymdk
 */
const pol5 = ({v0=0}) => t => 240/7 * (1 - v0) * ((t-.5)**3 / 6 - (t-.5)**5/5) + v0*(t-.5) + 1/2;

/**
    A quarter of a ball in p-norm. Can be used as easeIn (p<1) or easeOut (p>1).

    Input: p -> Norm of the quarter of the ball in p-norm.
    A representation can be seen here:
    https://www.desmos.com/calculator/utmwbzks9b
 */
const pBall = ({p=2}) => t => (1 - (1-t)**p)**(1/p)

/**
    Position followed a spring motion with friction.

    Input:
        a -> amplitude.
        m -> mass.
        k -> Hooke spring's constant.
        c -> friction coefficient.

    A representation can be seen here:
    https://www.desmos.com/calculator/gfnv1ssg97
 */
const frictionSpring = ({a=1.4, m=0.05, k=4, c=0.2}) => t => {
    if (t === 1) return 1;
    const z = 1/(1-t) - 1;
    if (c**2 > 4*m*k) {
        const D = (c**2-4*m*k)**(1/2);
        return a * ( Math.exp( z*(-c + D)/(2*m) ) + Math.exp( z*(-c - D)/(2*m) ) )
    } else if ( c**2 < 4*m*k ) {
        const w = (4*m*k - c**2)**(1/2) / (2*m);
        return a * Math.exp( -c*z/(2*m) ) * Math.sin(w*z);
    } else {
        return a * z * Math.exp( -c*z/(2*m) );
    }
}


export const withAnimated = (
    WrappedComponent
) => {
    const displayName =
        WrappedComponent.displayName || WrappedComponent.name || 'Component';

    class WithAnimated extends React.Component {
        static displayName = `WithAnimated(${displayName})`;
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return Animated.createAnimatedComponent(WithAnimated);
}


/**
 * A class with helpers for Animated from react-native.
 *
 * This class implements functions such as object-makers, easings and shorthands
 * to make Animated library less verbose to use.
 */
class Animation {

    /**
        This serves as a dictionary to already defined mappings.
     */
    static mappings = {
        'linear': linear,
        'pol5': pol5,
        'pBall': pBall,
        'frictionSpring': frictionSpring,
    }

    /**
        Returns an object to feed .interpolate from Animated module.
     */
    static keyframes = (...keyframes) => ({
        inputRange: keyframes.map( (frame, i) => i),
        outputRange: keyframes
    })

    /**
        Returns a function that returns an object to feed .interpolate from
        Animated module. This let us parametrize the extrapolate option.

        Input: extrapolate Value

        Output: Funtion similar to this.keyframes that returns an object to feed
        .iterpolate with a value given to extrapolate.
     */
    static extrapolatedKeyframes = (extrapolate) => (...keyframes) => ({
        extrapolate,
        inputRange: keyframes.map( (frame, i) => i),
        outputRange: keyframes
    })

    /**
        Returns an object to feed .timing second argument from Animated module.
        This object serves as configuration for the animation, specifing easing,
        duration, and final value.

        Input:
            toValue: final value of the animation
            duration: duration of the animation
            mapping: Two possible types.
                If mapping is a function, then this will be used as easing.
                If mapping is a string, we use the correspondent function of
                this.mappings and call it with mappingConfig to parametrize it.
                It callbacks to Easing.easeInOut if not especified.
            mappingConfig: object to parametrize a mapping function.
     */
    static create = (toValue, duration, mapping, mappingConfig={}, delay=0, useNativeDriver=false) => ({
            toValue,
            duration,
            useNativeDriver,
            delay,
            easing:
                typeof mapping === 'function'
                    ? mapping
                    : this.mappings[mapping]
                        ? this.mappings[mapping](mappingConfig)
                        : Easing.easeInOut
        });

    /**
        These functions simply call create with predefined common values for
        duration, giving a semantic meaning to the animation.
     */
    static short = (toValue, mapping, mappingConfig) => this.create(toValue, 250, mapping, mappingConfig)
    static medium = (toValue, mapping, mappingConfig) => this.create(toValue, 500, mapping, mappingConfig)
    static long = (toValue, mapping, mappingConfig) => this.create(toValue, 750, mapping, mappingConfig)

    /**
     * Starts an animation referenced by ref, it will transition to toValue indefinitely setting a duration and delay between iterations.
     * The mapping and mappingConfing are the same as with the Animation.create function.
     * @param {*} ref Animated value reference
     * @param {*} toValue Value to which the animation will transition
     * @param {*} duration Duration of the animation
     * @param {*} delay Delay between iterations
     * @param {*} mapping Easing function
     * @param {*} mappingConfig Easing function configuration
     */
    static loop = (ref, toValue, duration, delay, mapping, mappingConfig) => () => {
        Animated.timing(ref, Animation.create(toValue, duration, mapping, mappingConfig, delay, false)).start( ({finished}) => {
            if (finished) {
                ref.setValue(0);
                this.loop(ref, toValue, duration, delay, mapping, mappingConfig)();
            }
        });
    }
}

export default Animation;
