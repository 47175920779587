import React, { useImperativeHandle, forwardRef, useRef, useState } from 'react';
import { Pressable, StyleSheet, Text, Animated, Dimensions, ActivityIndicator, Platform, PlatformColor } from 'react-native';
import Animation from '@utils/animations';
import PropTypes from 'prop-types';
import { useTheme } from '@themeProvider';
import { Mixins, Colors, Spacing, Typography, Icon } from '@styles';
import { LinearGradient } from 'expo-linear-gradient';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

export const Button = ({style, icon, title, type, elevation=2, color, status, loadOnPress, avoidLoadingPress=true, forcedTheme, size, ...props}) => {
    const { useColor, useElevation, isDark } = useTheme();
    const elevationProps = useElevation(elevation, { forcedTheme });
    const animation = useRef(new Animated.Value(0)).current;
    const [ placeholder, body, bgColor, destructiveColor ] = useColor([
        'placeholder',
        'body',
        [color, {dark: status === 'pressed'}],
        [color === 'destructive' ? 'error' : color, { dark: status === 'pressed', reverse: isDark }],
    ], { forcedTheme });

    const [ titleColor, destructiveText, lightThemeAccent ] = useColor([
        ['title', { on: bgColor, reverse: color === 'secondary' && !isDark }],
        ['title', { on: bgColor, reverse: status !== 'pressed' }],
        ['accent', { reverse: isDark }]
    ], {forcedTheme})

    const elevatedBackground = elevationProps.backgroundColor;

    const [isLoading, setIsLoading] = useState(false);

    const handleOnPress = () => {
        if (loadOnPress) {

            if ( avoidLoadingPress && isLoading ) {
                return;
            }

            Animated.timing(animation, Animation.short(1)).start();
            setIsLoading(true);
            (async () => {
                await props.onPress();
                setIsLoading(false);
                Animated.timing(animation, Animation.short(0)).start();
            }) ();
        } else {
            props.onPress();
        }
        
        if (Platform.OS === 'web') {
            document.activeElement.blur();
        }
    }


    let backgroundColor;
    let textColor;
    let buttonStyle = {};

    // Set color from states
    if ( status === 'disabled' ) {
        backgroundColor = placeholder;
        textColor = body;
    } else {
        backgroundColor = color === 'accent' ? lightThemeAccent : bgColor;
        textColor = titleColor;

        if ( color === 'destructive') {
            backgroundColor = destructiveColor;
            textColor = destructiveText;
        }
        if (type === 'transparent' || type === 'secondary') {
            textColor = backgroundColor;
            backgroundColor = color !== 'destructive'
                ? Colors.setOpacity(Colors.festoColors.onLight[color], 0.25)
                : Colors.setOpacity(destructiveColor, 0.25);
        }
        if (type === 'elevated') {
            textColor = backgroundColor;
            backgroundColor = elevatedBackground;
        }
        if (type === 'border') {
            textColor = backgroundColor;
        }
    }

    if (type === 'border') {
        buttonStyle = { borderColor: backgroundColor, borderWidth: 2 }
    } else {
        buttonStyle = { backgroundColor }
        if (status === 'default' && (type === 'filled' || type === 'elevated')) buttonStyle = { ...elevationProps, ...buttonStyle }
    }

    if (size === 'condensed') {
        buttonStyle = { ...buttonStyle, height: 36, ...Mixins.rounding(36)}
    }

    if (size === 'x-condensed') {
        buttonStyle = { ...buttonStyle, height: 28, ...Mixins.rounding(28) }
    }
    
    return (
        <Pressable {...props} onPress={handleOnPress}  style={[styles.baseButtonContainer, Mixins.rounding(40), buttonStyle, style]}>
            { !!icon && <Icon size={24} name={icon} color={textColor} /> }
            <Text numberOfLines={1} style={[
                size === 'condensed' ? Typography.SUBTITLE : Typography.TITLE,
                { color: style?.color || textColor, marginLeft: (icon && title.length > 0) ? Spacing.XS : 0}]}>{title}</Text>
            {  loadOnPress &&
                <Animated.View style={{maxWidth: animation.interpolate(Animation.keyframes(0, 24)), overflow: 'hidden'}}>
                    <ActivityIndicator color={textColor} animating={true} style={{marginLeft: Spacing.XS}}/>
                </Animated.View>
            }
        </Pressable>
    )
}

Button.propTypes = {
    /**
     * This is the text the button will display
     */
    title: PropTypes.string,
    /**
     * The type of the button to mantain hierarchy
     */
    type: PropTypes.oneOf(['filled', 'primary', 'transparent', 'secondary', 'border', 'elevated']),
    color: PropTypes.oneOf(['primary', 'secondary', 'destructive', 'accent', 'warning']),
    status: PropTypes.oneOf(['default', 'pressed', 'disabled']),
    loadOnPress: PropTypes.bool
}

Button.defaultProps = {
    title: '',
    type: 'filled',
    color: 'primary',
    status: 'default',
    loadOnPress: false
}

Button.HEIGHT = 44;
Button.CONDENSED = 36;
Button.RADIUS = Mixins.rounding(Button.HEIGHT).borderRadius;

export const FAB = forwardRef(({ style, icon, title, color, text, ...props }, ref) => {

    const animation = useRef(new Animated.Value(2)).current;

    useImperativeHandle(ref, () => ({
        hide: () => {
            if (text) {
                Animated.timing(animation, Animation.medium(0, 'pol5', { v0: 0 })).start();
            } else {
                Animated.timing(animation, Animation.short(0)).start();
            }
        },
        show: () => {
            if (text) {
                Animated.timing(animation, Animation.medium(2, 'pol5', { v0: 0 })).start();
            } else {
                Animated.timing(animation, Animation.short(2)).start();
            }
        }
    }));

    return (
        <AnimatedPressable {...props} style={[Mixins.HORIZONTAL, styles.FAB, Mixins.rounding(48), style, {
            transform: [{scale: animation.interpolate(Animation.keyframes(0,1,1))}],
            opacity: animation
        }]}>
            <LinearGradient colors={Colors.gradients.golden} start={[0, 0]} end={[1,1]} style={[StyleSheet.absoluteFill]}/>
            <Icon size={24} name={icon} color={Colors.festoColors.onLight.title} />
            {
                !!text && <Animated.Text numberOfLines={1} ellipsizeMode="clip" style={[Typography.TITLE, {
                    marginLeft: animation.interpolate(Animation.keyframes(0, 0, Spacing.XS)),
                    maxWidth: animation.interpolate(Animation.keyframes(0, 0, Dimensions.get('window').width))
                }]}>{text}</Animated.Text>
            }
        </AnimatedPressable>
    )
});




const styles = StyleSheet.create({
    baseButtonContainer: {
        height: Button.HEIGHT,
        paddingHorizontal: 32,
        alignItems: 'center',
        justifyContent: 'center',
        ...Mixins.HORIZONTAL,
        ...Platform.select({ native: {}, default: {
            userSelect: 'none'
        }})
    },
    FAB: {
        height: 48,
        minWidth: 48,
        paddingHorizontal: Spacing.S,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
    }
})
