import service from '@services';
import { ProductNode } from '@datamodel';
import { objectMap } from '@utils/helpers';

export const get = async (nodeId) => {
    const response = await service.request(`/products/${nodeId}/`);
    if (response.status === 'ok') {
        const product = ProductNode.parse(response.response)
        return {
            status: 'ok',
            product,
        }
    }
    return service.error(response);
}

export const getCollection = async (nodeIds) => {
    const products = [];

    for (const nodeId of nodeIds) {
        const response = await get(nodeId);
        if (response.status !== 'ok') {
            throw service.error(response);
        }
        products.push(response.product);
    }

    return {
        status: 'ok',
        products: objectMap(products, ProductNode.uuid)
    }
}