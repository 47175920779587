import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Pressable, Text, View, StyleSheet, Platform, Animated, Linking, Dimensions, AppState } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { CommonActions, NavigationContainer } from '@react-navigation/native';

import { AuthNavigator } from './auth-navigator';
import { createSideTabNavigator } from './side-tab-navigator';
import { createStackNavigator } from '@react-navigation/stack';
import { OrderScreen, HistoryScreen, GrillBatchScreen, SettingsScreen } from '@scenes';
import { logOut, removeDraft } from '@actions';
import { useTheme } from '@themeProvider';
import { Typography, Mixins, Icon, Spacing } from '@styles';
import { useLanguage } from '@languageProvider';
import { CountupTimer, useModal, useSnackbar } from '@lib';
import { Parser, PickupBar } from '@datamodel';
import { authenticate, clearReadingSession, getPickupBars, hardLogOut, saveDraft, scanCode, setReadOrderModalVisible, writeSettings } from '@redux/actions';
import { Cart } from '@datamodel';
import { parsePrice } from '@utils/helpers';
import Animation from '@utils/animations';
import service, { OAUTH, Trackings } from '@services';
import { ReadOrderModal } from '@modals';
import { exchangeCodeAsync, useAuthRequest } from 'expo-auth-session';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';

const screenTracker = new Trackings.Tracker('Navigator');

const { width: dWidth, height: dHeight } = Dimensions.get('window');

const Tab = createSideTabNavigator();

const Stack = createStackNavigator();

const styles = StyleSheet.create({
    pressable: {
        paddingHorizontal: Spacing.MARGINS,
        justifyContent: 'center',
        height: 64, overflow: 'hidden', ...Platform.select({
            native: {}, default: {
                userSelect: 'none'
            }
        }) },
    userSelectOff: Platform.select({ native: {}, default: { userSelect: 'none' }})
})

const StackNavigator = () => {
    const { translate } = useLanguage();
    const { pushActionSheet } = useModal();
    const { purchases, visible } = useSelector(state => state.readingSession );

    const dispatch = useDispatch();

    const code = useRef('');
    const handleKeyPress = React.useCallback((event) => {
        if (event.key == 'Enter') {
            if (code.current) {
                dispatch(scanCode(code.current));
                code.current = '';
            }
        } else {
            code.current += event.key;
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);
        return () => document.removeEventListener('keypress', handleKeyPress);
    }, [])


    useEffect(() => {
        const totalCodes = Object.keys(purchases).length;

        if (totalCodes > 0 && !visible  ) {
            dispatch(setReadOrderModalVisible(true));
            pushActionSheet({
                children: <ReadOrderModal style={{ marginHorizontal: Spacing.MARGINS }}/>,
                actions: [],
                dismissText: translate('continue'),
                style: {
                    flexShrink: 1
                }
            }, {
                onDismiss: () => dispatch(clearReadingSession),
                style: {
                    paddingHorizontal: dWidth * .3,
                }
            })
        }
    }, [purchases])

    return <Stack.Navigator
        initialRouteName={'Tab'}
        header={null}
        headerMode='none'
    >
        <Stack.Screen
            name="Tab"
            component={TabNavigator}
        />
    </Stack.Navigator>
}

const LogOut = ({isCollapsed, ...props}) => {
    const dispatch = useDispatch();
    const pickupBars = useSelector(state => state.pickupBars);

    const onPress = () => {
        if (Object.keys(pickupBars).length === 1) {
            dispatch(hardLogOut);
        } else {
            dispatch(logOut);
        }
    }
    return <Pressable onPress={onPress} {...props}><TabItem isCollapsed={isCollapsed} options={{ icon: 'log-out', title: 'logOut', translate: true }} focused={false} /></Pressable>
}

const TabItem = ({ options, focused, isCollapsed }) => {
    const { translate } = useLanguage();
    const { useColor } = useTheme();
    const [ title, body ] = useColor(['title', 'body']);
    return <View style={[Mixins.HORIZONTAL, { alignItems: 'center' }]}>
        <Icon size={24} name={options.icon} color={focused ? title : body} style={{ marginRight: Spacing.XS }}/>
        {!isCollapsed && <Text numberOfLines={1} ellipsizeMode="clip" style={[Typography.TITLE, { color: focused ? title : body}]}>{options.translate ? translate(options.title) : options.title}</Text>}
    </View>
}

// const DraftRow = ({ serverCart, createdAt, totalPrice, isCollapsed }) => {
//     const { useColor } = useTheme();
//     const [body] = useColor(['body']);

//     if (isCollapsed) {
//         return <Icon size={24} name="clock" color={body} style={{marginRight: Spacing.XS}}/>
//     }

//     return <View style={[Mixins.HORIZONTAL, { alignItems: 'center' }]}>
//         {/* <Icon size={24} name="clock" color={body} style={{marginRight: Spacing.XS}}/> */}
//         <Text numberOfLines={1} ellipsizeMode="clip"  style={[Typography.TITLE, { color: body}]}>{parsePrice(totalPrice, '€')} - {serverCart.selections.length} - </Text>
//         <CountupTimer numberOfLines={1} ellipsizeMode="clip"  start={Parser.date(createdAt)} style={[Typography.TITLE, { color: body, flexGrow: 1 }]} />

//     </View>
// }

const TabBar = ({ state, descriptors, navigation }) => {
    const {isCollapsed, setIsCollapsed} = useTabBarIsCollapsed();
    const animation = useRef( new Animated.Value(isCollapsed ? 0 : 1) ).current;
    const { translate } = useLanguage();
    const { useElevation, useColor } = useTheme();
    const [label, title, line] = useColor(['label', 'title', 'line']);
    const elevation = useElevation(24);
    const { placeName, pickupBarName } = useSelector(state => state.authentication);

    const toggleNavigation = () => {
        if (!isCollapsed) {
            Animated.timing(animation, Animation.short(0)).start(() => {
                setIsCollapsed(true);
            });
        } else {
            Animated.timing(animation, Animation.short(1)).start(() => {
                setIsCollapsed(false);
            });
        }
    }

    return (
        <Animated.View style={[elevation, { paddingVertical: Spacing.MARGINS, width: animation.interpolate(Animation.keyframes(80, 250)) }]}>
            <Pressable
                accessibilityRole="button"
                hitSlop={Spacing.XS} 
                onPress={toggleNavigation}
                style={[Mixins.HORIZONTAL, styles.userSelectOff, { paddingHorizontal: Spacing.MARGINS, alignItems: 'center', marginBottom: Spacing.M, borderBottomWidth: 1, paddingBottom: Spacing.M, borderColor: line }]}>
                <Animated.View style={{ transform:[{ rotate: animation.interpolate(Animation.keyframes('180deg', '0deg')) }]}}>
                    <Icon size={24} name="back-arrow-ios" color={title} />
                </Animated.View>
                {!isCollapsed && <Text numberOfLines={1} ellipsizeMode='clip' style={[Typography.SUBTITLE, { color: title, marginLeft: Spacing.XS }]}>{translate('collapse')}</Text>}

            </Pressable>
            {/* Routes */}
            {state.routes.map((route, index) => {
                const focused = index === state.index;
                const { options } = descriptors[route.key];

                const onPress = () => {
                    
                    const event = navigation.emit({
                        type: 'tabPress',
                        target: route.key,
                        canPreventDefault: true,
                    });

                    screenTracker.tap('TabButton', {
                        action: `navigate.${route.name}`
                    });
                    
                    if (!focused && !event.defaultPrevented) {
                        navigation.dispatch({
                            ...CommonActions.navigate({ name: route.name, merge: true, params: options.params }),
                            target: state.key,
                        });
                    }
                };

                const onLongPress = () => {
                    navigation.emit({
                        type: 'tabLongPress',
                        target: route.key
                    })
                };

                if (options.hidden) return <></>
                return (
                    <Pressable
                        accessibilityRole="button"
                        accessibilityStates={focused}
                        accessibilityLabel={options.tabBarAccessibilityLabel}
                        testID={options.tabBarTestID}
                        onPress={onPress}
                        onLongPress={onLongPress}
                        key={options.title}
                        pointerEvents="box-only"
                        style={[styles.pressable]}
                    >
                        <TabItem options={options} focused={focused} isCollapsed={isCollapsed} />
                    </Pressable>
                )
            })}

            {/* Drafts */}
            {/* <View style={{ flexGrow: 1, alignSelf: 'stretch' }}>
                {
                    drafts.length > 0 &&
                    <>
                    <Text numberOfLines={1} style={[Typography.OVERLINE, { color: label, marginTop: Spacing.XXS, marginHorizontal: Spacing.MARGINS }]}>{ isCollapsed ? ' ' : translate('draftOrders')}</Text>
                    {drafts.sort((d1, d2) => d2.createdAt - d1.createdAt).map((params, i) => (<Pressable
                        key={i}
                        accessibilityRole="button"
                        onPress={() => {
                            // Get current OrderScreen state
                            const orderScreen = state.routes.filter(r => r.name === 'Order')[0];
                            if (orderScreen.params?.serverCart?.selections.length) {
                                // Save draft
                                const totalPrice = Cart.calculatePrice(Cart.parse(orderScreen.params.serverCart), orderScreen.params.menu);

                                dispatch(saveDraft(orderScreen.params.serverCart, orderScreen.params.id, orderScreen.params.menu, totalPrice));
                            }

                            dispatch(removeDraft(params.id));
                            navigation.navigate('Order', { ...params });
                            pushSnack(translate('loadedOrder'));
                        }}
                        pointerEvents="box-only"
                        style={[styles.pressable]}>
                        <DraftRow {...params} isCollapsed={isCollapsed}/>
                    </Pressable>))}
                    </>
                }
            </View> */}
            
            <View style={{ marginHorizontal: Spacing.MARGINS, borderTopWidth: 1, borderColor: line  }}>
                { !isCollapsed &&
                <View style={{ paddingVertical: Spacing.M, paddingLeft: 24 }}>
                <Text numberOfLines={1} ellipsizeMode="clip" style={[Typography.OVERLINE, { color: title, fontSize: 14, lineHeight: 24 }]}>
                    {placeName}</Text>
                <Text numberOfLines={1} ellipsizeMode="clip" style={[Typography.CAPTION, { color: title, fontSize: 16, lineHeight: 24 }]}>
                    {pickupBarName}</Text>
                </View>
                }
                <LogOut isCollapsed={isCollapsed} style={[styles.pressable, { paddingHorizontal: 0 }]}/>
            </View>
            
        </Animated.View>
    )
}


const TabNavigator = () => {
    const { translate } = useLanguage();
    const pickupBars = useSelector(state => state.pickupBars);
    const showGrill = React.useMemo(() => {
        if (service.bar_id) {
            const { hasOrderGrill } = pickupBars[service.bar_id]
            return hasOrderGrill;
        }
        return false;
    }, [service.bar_id]);

    const { useColor } = useTheme();
    const [ backgroundColor ] = useColor(['background']);
    return (
        <Tab.Navigator
            initialRouteName={showGrill ? 'Grill' : 'Order'}
            tabBarStyle={{
                paddingTop: 64,
                backgroundColor
            }}
            tabBar={props => <TabBar {...props} />}
        >
            { !!showGrill &&
            <Tab.Screen name="Grill" options={{
                title: translate('grill'),
                icon: 'planner',
                translate: false,
            }} component={GrillBatchScreen} />
            }
            <Tab.Screen name="Order" options={{
                title: translate('currentOrder'),
                icon: 'paper',
                translate: false,
            }} component={OrderScreen} />
            <Tab.Screen name="History" options={{
                title: translate('history'),
                icon: 'receipt',
                translate: false,
            }} component={HistoryScreen} />
            <Tab.Screen name="Settings" options={{
                title: translate('settings'),
                icon: 'settings',
                translate: false
            }} component={SettingsScreen}/>
        </Tab.Navigator>
    );
}

const defaultTabBarContext = {
    isCollapsed: false,
    setIsCollapsed: () => { }
};
const TabBarContext = React.createContext(defaultTabBarContext);
export const useTabBarIsCollapsed = () => React.useContext(TabBarContext);


export const RootNavigator = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const {isAuth, token, manualLogOut} = useSelector(state => state.authentication);
    const collapsedNavigation = useSelector(state => state.settings.collapsedNavigation);
    const [isCollapsed, setIsCollapsed] = useState(collapsedNavigation);
    const contextValue={isCollapsed, setIsCollapsed: (v) => {
        setIsCollapsed(v);
        dispatch(writeSettings({ collapsedNavigation: v }));
    }};

    useEffect(() => {
        onMount();
    }, [])

    const onMount = async () => {
        if (token && !isAuth && !manualLogOut) {
            dispatch(authenticate(token));
        }
    }

    return (
        <TabBarContext.Provider value={contextValue}>
            <NavigationContainer ref={ref} {...props}>
                {isAuth && <StackNavigator />}
                {!isAuth && <AuthNavigator />}
            </NavigationContainer>
        </TabBarContext.Provider>
    )
})

export default RootNavigator;