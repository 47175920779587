import React, { useState, useMemo, useRef, useEffect } from 'react';
import { ScrollView, StyleSheet, Text, View, Animated, Dimensions, Pressable } from 'react-native';
import { Typography, Mixins, Icon, Spacing, Colors } from '@styles';

import { Stepper, RadioButton, Checkbox, Button } from '@atoms';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { SelectionTree, Promotion, ProductNode } from '@datamodel';
import Animation from '@utils/animations';
import { mapObjectValues, parsePrice } from '@utils/helpers';
import { _pushModal, _pushSnack, useSnackbar } from '@lib';
import { ProductNodeCard, PromotionSelectInput } from '@molecules';

const { height: dHeight, width: dWidth } = Dimensions.get('window');

import { Trackings } from '@services';
const modalTracker = new Trackings.Tracker('AddItemModal');

const Question = ({ markNode, selectionState, flatTree, absolutePrice, style }) => {
    const tracker = useRef((Trackings.Tracker.from(modalTracker)).setComponent('Question')).current;
    const { translate } = useLanguage();
    const { useColor } = useTheme();
    const [title, body, label, primary, error] = useColor(['title', 'body', 'label', 'primary', 'error']);
    const [titleOnError] = useColor([['title', { on: error }]]);
    const isRadio = selectionState.maxChildren === 1 && selectionState.minChildren === 1;
    const InputComponent = useMemo(() => isRadio ? RadioButton : Checkbox, []);
    const markedChildren = Object.keys(selectionState.children)
        .filter(nodeId => selectionState.children[nodeId].marked);

    const inputPress = (nodeId, value) => {
        if (isRadio && value) {
            // If is a radio button and I'm selecting
            // deselect previus one

            if (markedChildren.length) {
                markNode(markedChildren[0], false);
            }
        }

        markNode(nodeId, value)
    }

    // TODO: Check absolutePrice flag
    // const priceString = (amount) => absolutePrice ? parsePrice(amount, '€')
    //     : `+${parsePrice(amount, '€')}`;

    const priceString = (amount) => '+' + parsePrice(amount, '€');

    const answerPress = (child) => {
        if (child.soldOut) {
            _pushSnack(translate('soldOut'));
        } else {
            inputPress(child.nodeId, !child.marked)
        }
    }

    return (
        <View style={style}>
            <View style={[Mixins.HORIZONTAL, {
                justifyContent: 'space-between',
                paddingHorizontal: Spacing.MARGINS,
                paddingVertical: Spacing.XS,
                backgroundColor: Colors.setOpacity(title, .05)
            }]}>
                <Text style={[Typography.TITLE, {
                    color: title,
                }]}>{selectionState.name}</Text>
                {
                    !isRadio &&
                    <Text style={[Typography.SUBTITLE, {
                        color: label
                    }]}>{markedChildren.length}/{selectionState.maxChildren}</Text>
                }
            </View>
            {
                !!selectionState.description &&
                <Text style={[Typography.BODY, { paddingHorizontal: Spacing.MARGINS, color: body, marginVertical: Spacing.XS }]}>
                    {selectionState.description}
                </Text>
            }
            <View style={[Mixins.HORIZONTAL, { flexWrap: 'wrap', paddingHorizontal: Spacing.M }]}>
                {
                    Object.keys(selectionState.children).sort((n1, n2) => {
                        const o1 = selectionState.children[n1].soldOut ? Infinity : flatTree[n1].order;
                        const o2 = selectionState.children[n2].soldOut ? Infinity : flatTree[n2].order;
                        return o1 - o2;
                    }).map(nodeId => {
                        const child = selectionState.children[nodeId];
                        const trackPress = (child) => {
                            tracker.tap('Input', {
                                action: !child.marked ? 'select' : 'unselect',
                                type: isRadio ? 'RadioButton' : 'Checkbox',
                                question: selectionState.nodeId,
                                answer: child.nodeId,
                            })
                        }
                        const product = flatTree[nodeId];

                        // TODO: Add extra price {priceString(child.amount)} to card??
                        return <Pressable
                            key={nodeId}
                            onPress={() => { trackPress(child); answerPress(child);}}
                            pointerEvents="box-only"
                            style={{
                                marginRight: Spacing.M,
                                marginTop: Spacing.M,
                                ...Mixins.rounding(ProductNodeCard.HEIGHT),
                                borderWidth: 2,
                                borderColor: child.marked ? primary : 'transparent',
                            }}>
                            <ProductNodeCard product={product} />
                            {!!child.soldOut && <View style={[StyleSheet.absoluteFill, Mixins.rounding(ProductNodeCard.HEIGHT), { backgroundColor: Colors.setOpacity(error, .15), justifyContent: 'center' }]}>
                                <Text style={[Typography.TITLE, { textAlign: 'center', color: titleOnError, paddingVertical: Spacing.XS, backgroundColor: error }]}>{translate('soldOut')}</Text>
                            </View>}
                        </Pressable>
                    })
                }
            </View>
        </View>
    )
}

export const AddItemModal = ({ _dismissModal, coupons = {}, offers = {}, selectionTree, productTree, mainSelection, serverSelection = true, addToCartPress, style, absolutePrice, showStepper = true, showDelete=false, onDeletePress, buttonConfig = {}, lastSelectionTree }) => {
    const { translate } = useLanguage();
    const { useElevation, useColor } = useTheme();
    const { pushSnack } = useSnackbar();
    const animation = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(animation, Animation.create(1, 100)).start();
    }, [])

    const [title, line, error, secondary] = useColor(['title', 'line', 'error', 'secondary']);
    const elevation = useElevation(12);

    const questions = SelectionTree.calculateQuestions(selectionTree);
    const { initialSolvables, markedNames } = useMemo(() => {
        return ({
            initialSolvables: SelectionTree.numberOfSolvables(selectionTree),
            markedNames: SelectionTree.findMarkedNames(selectionTree)
        })
    }, []);

    const actualSelectionTree = lastSelectionTree || selectionTree;

    const flatTree = useMemo(() => ProductNode.flat(productTree), []);

    const [quantity, setQuantity] = useState(1);
    const [selectionState, setSelectionState] = useState(
        SelectionTree.getTreeNodes(actualSelectionTree, questions)
    );


    const price = SelectionTree.calculatePrice(actualSelectionTree);
    const {
        promotionId: appliedOffer,
    } = Promotion.calculateBestOffer(offers, price);

    const [selectedPromotions, setSelectedPromotions] = useState(appliedOffer ? { [appliedOffer]: { isOffer: true, quantity } } : {});

    const updateQuantity = (qty) => {
        // Side effects, update every selectedPromotion with the new quantity
        const newSelected = mapObjectValues(selectedPromotions, (sp, pId) => {
            const promo = sp.isOffer ? offers[pId] : coupons[pId];
            return ({
                ...sp,
                quantity: Math.min(Promotion.maxUses(promo), qty)
            })
        });
        setSelectedPromotions(newSelected);
        setQuantity(qty);
    }

    const findTotal = (qty) => {
        let total = 0;

        let info = Object.keys(selectedPromotions).map(pId => ({
            promotion: selectedPromotions[pId].isOffer ? offers[pId] : coupons[pId],
            quantity: selectedPromotions[pId].quantity
        }));


        for (let j = 0; j < qty; j++) {
            total += Promotion.applyCollection(info.filter(i => i.quantity > 0).map(i => i.promotion), price);
            info.forEach(i => i.quantity--)
        }

        return total;
    }

    const finalPrice = findTotal(quantity);
    const priceString = finalPrice ? ` · ${parsePrice(finalPrice, '€')}` : '';


    const promotionPress = ({ isOffer, promotionId }) => selPromoQty => {
        modalTracker.tap('PromotionSelectInput', {
            action: 'select',
            type: isOffer ? 'offer' : 'coupon',
            promotion: promotionId,
            quantity: selPromoQty + ''
        })
        const newSelected = { ...selectedPromotions };

        // If pressed is already selected, delete it
        if (promotionId in selectedPromotions && !isOffer) {
            delete newSelected[promotionId];
            if (appliedOffer) {
                newSelected[appliedOffer] = { isOffer: true, quantity }
            }
            setSelectedPromotions({ ...newSelected });
            return;
        }

        // If pressed is non-stackable, set it alone
        if (!isOffer && !coupons[promotionId].stackable) {
            setSelectedPromotions({ [promotionId]: { isOffer, quantity: selPromoQty } })
            return;
        }

        // Remove non-stackables
        const selPromoKeys = Object.keys(selectedPromotions);
        if (selPromoKeys.length === 1) {
            const selectedIsOffer = selectedPromotions[selPromoKeys[0]].isOffer;
            if (!selectedIsOffer && !coupons[selPromoKeys[0]].stackable) {
                delete newSelected[selPromoKeys[0]];
            }
        }


        if (appliedOffer) {
            newSelected[appliedOffer] = { isOffer: true, quantity }
        }

        setSelectedPromotions({ ...newSelected, [promotionId]: isOffer });
    }

    const markNode = (nodeId, value) => {
        SelectionTree.mark(actualSelectionTree, { nodeId, value })
        setSelectionState(SelectionTree.getTreeNodes(actualSelectionTree, questions));
    }


    const closeModal = () => {
        Animated.timing(animation, Animation.create(0, 100)).start(({ finished }) => {
            if (finished) {
                _dismissModal();
            }
        });
    }

    return (
        <Animated.View style={[style, {
            opacity: animation,
            transform: [{ scale: animation.interpolate(Animation.keyframes(.8, 1)) }],
            overflow: 'hidden',
            backgroundColor: elevation.backgroundColor,
            borderRadius: Button.RADIUS,
        }]}>
            <Text style={[Typography.H6, { color: title, marginVertical: Spacing.M, textAlign: 'center' }]}>
                {initialSolvables !== 0 ? productTree.name : markedNames[markedNames.length - 1]}
            </Text>

            <Pressable onPress={closeModal} hitSlop={Spacing.XS} style={{ position: 'absolute', right: Spacing.XS, top: Spacing.XS }}>
                <Icon name="close-alt" size={36} color={error} />
            </Pressable>

            <ScrollView
                bounces={false}
                fadingEdgeLength={96}
                overScrollMode="never"
                showsVerticalScrollIndicator={false}>

                {
                    (Object.keys(coupons).length > 0 || appliedOffer) &&
                    <View>
                        <View style={[Mixins.HORIZONTAL, {
                            justifyContent: 'space-between',
                            paddingHorizontal: Spacing.MARGINS,
                            paddingVertical: Spacing.XS,
                            marginBottom: Spacing.XS,
                            backgroundColor: Colors.setOpacity(secondary, .05)
                        }]}>
                            <Text style={[Typography.TITLE, {
                                color: title
                            }]}>{translate('promotions')}</Text>
                        </View>
                    </View>
                }

                {!!appliedOffer &&
                    <PromotionSelectInput
                        style={{ marginBottom: Spacing.XS }}
                        quantity={quantity}
                        checked={appliedOffer in selectedPromotions}
                        onPress={promotionPress({ isOffer: true, promotionId: appliedOffer })}
                        checkbox
                        disabled
                        promotion={offers[appliedOffer]}
                    />
                }

                {initialSolvables !== 0 &&
                    Array.from(questions).sort((a, b) => {
                        if (a === mainSelection) return -Infinity;
                        if (b === mainSelection) return +Infinity;
                        return 0;
                    }).map(k =>
                        <Question
                            key={k}
                            flatTree={flatTree}
                            absolutePrice={absolutePrice}
                            selectionState={selectionState[k]}
                            markNode={markNode}
                            style={{ marginBottom: Spacing.XL }}
                        />
                    )
                }


            </ScrollView>
            {
                !!showDelete &&
                <>
                    <View style={{ borderTopWidth: 1, borderColor: line, marginBottom: Spacing.XL }} />
                    <View style={{ alignItems: 'center' }}>
                        <Button onPress={() => {
                            onDeletePress();
                            _dismissModal();
                        }} type="secondary" color="error" size="condensed" icon="trash" />
                    </View>
                </>
            }
            {
                !!showStepper &&
                <>
                    <View style={{ borderTopWidth: 1, borderColor: line, marginBottom: Spacing.XL }} />
                    <Stepper value={quantity} setValue={(v) => {
                        modalTracker.tap('QuantityStepper', {
                            action: 'updateQuantity',
                            quantity: v + ''
                        })
                        updateQuantity(v)
                    }} />
                </>
            }

            <Button
                style={{ marginTop: Spacing.XL, borderRadius: 0 }}
                icon={buttonConfig.icon || "cart-add"}
                title={`${buttonConfig.title || translate('addToCart', { quantity })}${priceString}`}
                status={!actualSelectionTree.valid ? 'disabled' : 'default'}
                onPress={() => {
                    if (!actualSelectionTree.valid) {
                        modalTracker.tap('AddToCartButton', {
                            action: 'invalid',
                        })
                        pushSnack(translate('selectOneOption'));
                        return;
                    };
                    modalTracker.tap('AddToCartButton', {
                        action: 'addToCart',
                    })
                    addToCartPress(serverSelection ? SelectionTree.toServer(SelectionTree.addMainSelection(actualSelectionTree, productTree)) : actualSelectionTree, { quantity, selectedPromotions });
                    _dismissModal();
                }}
            />
        </Animated.View>
    )

}


AddItemModal.display = ({
    addToCartPress,
    showStepper,
    showDelete,
    onDeletePress,
    serverSelection,
    buttonConfig,
    lastSelectionTree,
    offers={},
    coupons={}
}) => fungibleNode => (option, selectionTree) => {
    const nodeId = option.nodeId;
    const pOffers = offers.products || {};
    const pCoupons = coupons.products || {};

    SelectionTree.mark(selectionTree, { nodeId, value: true });

    _pushModal({
        style: {
            justifyContent: 'center',
            alignItems: 'center'
        },
        children: <AddItemModal
            style={{ maxHeight: dHeight - Spacing.NAVBAR * 2, width: dWidth - Spacing.MARGINS*2 }} 
            selectionTree={selectionTree}
            lastSelectionTree={lastSelectionTree}
            addToCartPress={addToCartPress}
            productTree={fungibleNode}
            mainSelection={nodeId}
            absolutePrice={option.fromFlag}
            showStepper={showStepper}
            showDelete={showDelete}
            onDeletePress={onDeletePress}
            serverSelection={serverSelection}
            buttonConfig={buttonConfig}
            offers={pOffers[fungibleNode.nodeId]}
            coupons={pCoupons[fungibleNode.nodeId]}
        />
    })

}