import { Model, Parser } from '@datamodel';
import { _translate } from '@languageProvider';
import { parsePrice, objectFilter } from '@utils/helpers';

export class Coupon extends Model {

    static get uuid() { return 'couponId'; }

    static get model() {
        return {
            amountOff: 0,
            percentOff: 0,
            placeId: null,
            name: '',
            appliesTo: [],
            restrictTo: null,
        }
    }

    static get keymap() {
        return {
            id: Coupon.uuid,
            place: 'placeId',
        }
    }

    static get parseMap() {
        return ({
            appliesTo: (list) => list.map(o => o.id)
        })
    }

    static get unserialMap() {
        return ({
            appliesTo: l => l
        })
    }

    constructor(args) {
        super(args);
        Object.assign(this, Coupon.unserialize({ ...Coupon.model, ...args }, { toClass: true }))
    }

    static getTitle(coupon, config = {}) {
        const def = { short: false };
        const _config = { ...def, ...config };

        if (coupon.amountOff) {
            return _config.short ? `-${parsePrice(coupon.amountOff, '€')}` : _translate('discountOff', { discount: parsePrice(coupon.amountOff, '€') })
        } else {
            return _config.short ? `-${+coupon.percentOff.toFixed(2)}%` : _translate('discountOff', { discount: `${+coupon.percentOff.toFixed(2)}%` })
        }
    }

    static getPlaceId(coupon) {
        return coupon.placeId;
    }

    static getValidProducts(coupon) {
        return coupon.appliesTo;
    }

    static appliesToOrder(coupon) {
        return !coupon.appliesTo.length;
    }

    static discountedPrice(coupon, price) {
        if (coupon.amountOff)
            return price - coupon.amountOff;

        return price * (1 - coupon.percentOff / 100)
    }

    static applyCollection(coupons, price) {
        let total = price;

        // Apply absolute promotions
        coupons.forEach(c => {
            if (c && c.amountOff) {
                total = this.discountedPrice(c, total);
            }
        })

        // Apply percentage promotions
        coupons.forEach(c => {
            if (c && c.percentOff) {
                total = this.discountedPrice(c, total);
            }
        })

        return total;
    }
}

export class Promotion extends Model {

    static get uuid() { return 'promotionId'; }

    static get model() {
        return {
            coupon: Coupon.model,
            userId: null,
            code: null,
            expiredAt: null,
            maxRedemptions: 0,
            minAmount: 0,
            stackable: false,
            firstTimeTransaction: false,
            maxRedemptionsPerUser: null,
            remainingRedemptionsPerUser: null,
            remainingRedemptions: null,
            automatic: false
        }
    }

    static get keymap() {
        return {
            id: Promotion.uuid,
            user: 'userId',
            minimum_amount: 'minAmount',
            remaining_redemptions_by_user: 'remainingRedemptionsPerUser'
        }
    }

    static get parseMap() {
        return {
            expiredAt: d => Parser.date(d),
            coupon: c => Coupon.parse(c)
        }
    }

    static get unserialMap() {
        return {
            coupon: c => Coupon.unserial(c)
        }
    }

    static get toClassMap() {
        return {
            coupon: c => new Coupon(c)
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, Promotion.unserialize({ ...Promotion.model, ...args }, { toClass: true }))
    }

    static getTitle(promotion) {
        return Coupon.getTitle(promotion.coupon);
    }

    static getPlaceId(promotion) {
        return Coupon.getPlaceId(promotion.coupon);
    }

    static getValidProducts(promotion) {
        if (Coupon.appliesToOrder(promotion.coupon)) {
            return null;
        }
        return Coupon.getValidProducts(promotion.coupon);
    }

    static discountedPrice(promotion, price) {
        return Coupon.discountedPrice(promotion.coupon, price);
    }

    static collectionSplitByType(promotions) {
        const coupons = { order: {}, products: {} };
        const offers = { order: {}, products: {} };
        let promoGroup;

        for (const pId in promotions) {
            const promotion = promotions[pId];
            promoGroup = promotion.automatic ? offers : coupons;

            if (Coupon.appliesToOrder(promotion.coupon)) {
                promoGroup.order[pId] = promotion;
            } else {
                promotion.coupon.appliesTo.forEach(productNodeId => {
                    if (productNodeId in promoGroup.products) {
                        promoGroup.products[productNodeId][pId] = promotion;
                    } else {
                        promoGroup.products[productNodeId] = {
                            [pId]: promotion
                        }
                    }
                });
            }
        }

        return { coupons, offers };
    }

    static collectionFilterByPlaceId(promotions, placeId) {
        return objectFilter(promotions, p => p.coupon.placeId === placeId || !p.coupon.placeId)
    }

    static collectionFilterByProductId(promotions, productId) {
        return objectFilter(promotions, p => p.coupon.appliesTo.indexOf(productId) >= 0)
    }

    static collectionSplitByAutomatic(promotions) {
        const coupons = {};
        const offers = {};
        let promoGroup;

        for (const pId in promotions) {
            const promotion = promotions[pId];
            promoGroup = promotion.automatic ? offers : coupons;
            promoGroup[pId] = promotion;
        }
        return { coupons, offers };
    }

    static calculateBestOffer(offersCollection, price) {
        const appliedOffers = Object.keys(offersCollection).map(oId => ({
            price: Promotion.discountedPrice(offersCollection[oId], price),
            promotionId: oId
        })).sort((p1, p2) => p1.price - p2.price);

        return appliedOffers.length > 0 ? appliedOffers[0] : ({ promotionId: null, price })
    }

    static applyCollection(promotions, price) {
        return Coupon.applyCollection(promotions.map(p => p.coupon), price);
    }

    static findMaxQuantity(promotions) {
        return Math.min(...(promotions.filter(p => p.remainingRedemptionsPerUser !== null).map(p => p.remainingRedemptionsPerUser)));
    }

    static maxUses(promotion) {
        if (!promotion) return 0;
        const remaining = Number.isFinite(promotion.remainingRedemptionsPerUser) ? promotion.remainingRedemptionsPerUser : promotion.remainingRedemptions;
        return remaining === null ? Infinity : remaining;
    }

    static updatePromotionUses(collection, usedPromotions) {
        const newCollection = { ...collection };

        Object.keys(usedPromotions).forEach(pId => {
            if (Number.isFinite(newCollection[pId].remainingRedemptionsPerUser)) {
                newCollection[pId].remainingRedemptionsPerUser -= usedPromotions[pId];
            }
            if (Number.isFinite(newCollection[pId].remainingRedemptions)) {
                newCollection[pId].remainingRedemptions -= usedPromotions[pId];
            }
        })


        // return objectFilter(newCollection, p => p.maxRedemptionsPerUser > 0 || p.maxRedemptionsPerUser === null );
        return newCollection;
    }
}


export class Discount extends Model {

    static get uuid() { return 'discountId'; }

    static get model() {
        return {
            promotionCode: Promotion.model,
            coupon: Coupon.model,
        }
    }

    static get keymap() {
        return {
            id: 'discountId',
        }
    }


    static get parseMap() {
        return {
            promotionCode: pc => Promotion.parse(pc),
            coupon: c => Coupon.parse(c)
        }
    }

    static get unserialMap() {
        return {
            promotionCode: pc => Promotion.unserialize(pc),
            coupon: c => Coupon.unserialize(c)
        }
    }

    static get toClassMap() {
        return {
            promotionCode: pc => new Promotion(pc),
            coupon: c => new Coupon(c)
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, Discount.unserialize({ ...Discount.model, ...args }, { toClass: true }))
    }

    static discountedPrice(discount, price) {
        return Coupon.discountedPrice(discount.coupon, price);
    }

    static applyCollection(discounts, price) {
        return Coupon.applyCollection(discounts.map(d => d.coupon), price);
    }
}