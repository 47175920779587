import React, { useState, createRef } from 'react';
import { useSelector } from 'react-redux';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

import english from './english';
import spanish from './spanish';

export const LanguageContext = React.createContext({
    language: 'en',
    translate: () => { },
    setLanguageScheme: () => { },
});

// Use this interface only when necessary
const translateRef = createRef();
export const _translate = (...args) => {
    if ( translateRef.current)
        return translateRef.current.translate(...args);
    return ''
}

export const LanguageProvider = (props) => {
    const saveLanguage = useSelector(state => state.settings?.language || 'default')
    const [language, setLanguage] = saveLanguage === 'default' ? useState(Localization.locale) : useState(saveLanguage);


    // Set the key-value pairs for the different languages you want to support.
    i18n.translations = {
        en: english,
        es: spanish,
    };
    // Set the locale once at the beginning of your app.
    i18n.locale = language;
    // When a value is missing from a language it'll fallback to another language with the key present.
    i18n.fallbacks = true;

    const defaultLanguage = {
        language: language,
        translate: (keyword, variables = {}) => i18n.t(keyword, variables),
        setLanguageScheme: (scheme) => setLanguage(scheme),
    };

    // Listening to changes of device language while in run-time
    React.useEffect(() => {
        setLanguage(language);
        translateRef.current = {...defaultLanguage};
    }, [language]);

    React.useEffect(() => {
        translateRef.current = { ...defaultLanguage };
    }, [])

    return (
        <LanguageContext.Provider value={defaultLanguage}>
            {props.children}
        </LanguageContext.Provider>
    );
};

// Custom hook to get the language object returns {translate, setScheme}
export const useLanguage = () => React.useContext(LanguageContext);

export default LanguageProvider;