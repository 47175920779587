import { Tree, Model, ProductNode, Promotion, ProductPack, Coupon } from '@datamodel';
import { mapObjectValues, addClassMethods } from '@utils/helpers';

class CartSelectionItem extends Model {

    static get model() {
        return {
            itemId: '',
            nodeId: '',
            promotionCodes: [],
            packId: '',
        }
    }

    static get keymap() {
        return {
            id: 'itemId',
            product_definition: 'nodeId',
            pack_item: 'packId'
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, CartSelectionItem.unserialize({ ...CartSelectionItem.model, ...args }, { toClass: true }))
    }

    static toServer(item, { ignoreId } = { ignoreId: false }) {
        return {
            id: ignoreId ? undefined : item.itemId,
            product_definition: item.nodeId,
            pack_item: item.packId,
            promotion_codes: item.promotionCodes
        }
    }
}

export class CartSelection extends Model {

    static get model() {
        return {
            _mainSelection: '', // Refers to whichis the main selection
            _packIndex: null, // Absolute number of the pack added, to group those added at the same time
            _packId: null, // Refers to the packId to which this selection has been made
            _packLength: null, // Refers to the number of items to be selected in the pack
            item: {},
            children: {}
        }
    }

    static get keymap() {
        return {
            _main_selection: '_mainSelection',
            _pack_index: '_packIndex',
            _pack_id: '_packId',
            _pack_length: '_packLength'
        }
    }

    static get parseMap() {
        return {
            item: item => CartSelectionItem.parse(item),
            children: children => children.map(cs => CartSelection.parse(cs))
        }
    }

    static get unserialMap() {
        return {
            item: item => CartSelectionItem.unserialize(item),
            children: children => children.map(cs => CartSelection.unserialize(cs))
        }
    }

    static get toClassMap() {
        return {
            item: item => new CartSelectionItem(item),
            children: children => children.map(cs => new CartSelection(cs))
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, CartSelection.unserialize({ ...CartSelection.model, ...args }, { toClass: true }))
    }

    static getId(cartSelectionTree) {
        let selectedIds = [];

        const appendToId = (tree) => {
            const nodeSelectionId = tree.item.nodeId + '|' + tree.item.packId + '|' + tree.item.promotionCode;
            selectedIds.push(nodeSelectionId)
        }

        this._tranverseTree(cartSelectionTree, appendToId);

        return selectedIds.sort().join(';')
    }

    static getLeafs(cartSelection) {
        const leafs = [];

        const _getLeafs = (tree) => {
            if (Object.keys(tree.children).length <= 0) {
                leafs.push(tree.item.nodeId);
            }
        }

        this._tranverseTree(cartSelection, _getLeafs);
        return leafs;
    }

    static getProductInfo(cartSelectionRoot, productTree) {

        const leafs = this.getLeafs(cartSelectionRoot);

        return {
            nodes: ProductNode.getTreeNodes(productTree, [...leafs, cartSelectionRoot._mainSelection, cartSelectionRoot.item.nodeId]),
            leafs
        };
    }

    static getSelectionInfo(cartSelection, productTree, opts={}) {
        const { nodes, leafs } = this.getProductInfo(cartSelection, productTree);

        const getName = (o={}) => {
            return opts.useDisplayName ?
                o?.displayName || o?.name
                : o?.name;
        }

        return {
            title: getName(nodes[cartSelection._mainSelection]),
            info: [getName(nodes[cartSelection.item.nodeId]),
                ...leafs.map(nodeId => getName(nodes[nodeId]))]
                .filter(name => name !== getName(nodes[cartSelection._mainSelection]))
        }
    }

    static getFungibleNode(cartSelection, productTree) {
        const nodes = ProductNode.flat(productTree);
        let ret;

        const findFungible = (node) => {
            // console.log('>>>', JSON.stringify(productTree), node.item.nodeId);
            if (nodes[node.item.nodeId]?.fungible) {
                ret = nodes[node.item.nodeId];
            }
        }

        this._tranverseTree(cartSelection, findFungible);
        return ret;
    }

    static calculatePrice(cartSelectionRoot, productTree, promotions = {}, packs = {}) {
        const nodes = ProductNode.flat(productTree);
        let price = 0;
        const sumPrice = (node) => {
            price += nodes[node.item.nodeId].price.amount;
        }
        this._tranverseTree(cartSelectionRoot, sumPrice);
        if (!cartSelectionRoot._packId) {
            price = Promotion.applyCollection(cartSelectionRoot.item.promotionCodes.map(pId => promotions[pId]), price)
        } /*else if (cartSelectionRoot._packId in packs) {
            const packItem = ProductPack.getPackItemByProductId(packs[cartSelectionRoot._packId], cartSelectionRoot.item.nodeId)
            price = packItem.coupon ? Coupon.discountedPrice(packItem.coupon, price) : price;
        }*/
        return price;
    }

    static collectionCalculatePrices(collection, productTree) {
        return mapObjectValues(collection, sel => this.calculatePrice(sel, productTree));
    }

    static toServer(cartSelection, { ignoreId } = { ignoreId: false }) {
        return {
            _main_selection: cartSelection._mainSelection,
            _pack_index: cartSelection._packIndex,
            _pack_id: cartSelection._packId,
            _pack_length: cartSelection._packLength,
            item: CartSelectionItem.toServer(cartSelection.item, { ignoreId }),
            children: cartSelection.children.map(c => CartSelection.toServer(c, { ignoreId }))
        }
    }

    // static collectionToServer(cartSelection) {
    //     return Object.keys(cartSelection).map(csKey => )
    //     return Object.keys(cartSelection).flatMap(
    //         csKey => Array(cartSelection[csKey].quantity ?? 1).fill(CartSelection.toServer(cartSelection[csKey])))
    // }

    static addSelectionId(cartSelection) {
        const selId = this.getId(cartSelection);
        cartSelection._selectionId = selId;
        return cartSelection;
    }

    static findPromotionUses(cartSelection) {
        const usedPromotions = {};

        const addPromotion = (node) => {
            node.item.promotionCodes.forEach(pId => {
                if (pId in usedPromotions) {
                    usedPromotions[pId]++;
                } else {
                    usedPromotions[pId] = 1;
                }
            })
        }

        this._tranverseTree(cartSelection, addPromotion);
        return usedPromotions;
    }

    static stackCartSelections(cartSelections) {
        // Split between packs and standalone items

        const packs = {};
        const standalone = {};

        for (const cartSel of cartSelections) {

            const _selId = cartSel._selectionId;

            if (cartSel._packIndex !== null) {
                // Pack
                if (cartSel._packIndex in packs) {
                    const packSel = packs[cartSel._packIndex];

                    if (_selId in packSel.selections) {
                        packSel.selections[_selId].quantity++;
                    } else {
                        packSel.selections[_selId] = { ...cartSel, quantity: 1 }
                    }
                    packSel.quantity++;
                } else {
                    packs[cartSel._packIndex] = {
                        quantity: 1,
                        length: cartSel._packLength,
                        packId: cartSel._packId,
                        selections: {
                            [_selId]: { ...cartSel, quantity: 1 }
                        }
                    }
                }

            } else {
                // Standalone
                if (_selId in standalone) {
                    standalone[_selId].quantity++;
                } else {
                    standalone[_selId] = { ...cartSel, quantity: 1 }
                }
            }
        }

        // Readjust pack quantities
        for (const _packIndex in packs) {
            const packSel = packs[_packIndex];
            const realQty = packSel.quantity / packSel.length;
            packSel.quantity = realQty;
            for (const _selId in packSel.selections) {
                packSel.selections[_selId].quantity /= realQty;
            }
        }

        return { packs, standalone };
    }

    static unstackCartSelections(stackedCartSelections, stackedPacks = {}) {
        const cartSelections = [];
        for (const _selId in stackedCartSelections) {
            const { quantity: qty, ...cartSel } = stackedCartSelections[_selId];
            for (let index = 0; index < qty; index++) {
                cartSelections.push(cartSel);
            }
        }

        for (const _packIndex in stackedPacks) {

            const { quantity: packQty, ...pack } = stackedPacks[_packIndex];
            Object.keys(pack.selections).forEach(_selId => {
                const { quantity: qty, ...cartSel } = pack.selections[_selId];
                for (let i = 0; i < qty; i++) {
                    for (let j = 0; j < packQty; j++) {
                        cartSelections.push(cartSel);
                    }
                }
            });

        }
        //TODO: unstack Packs
        return cartSelections;
    }

    static addMainSelection(cartSelection, fungible) {
        if (!fungible) {
            return cartSelection;
        }

        // Get the true main options of the fungible
        const mainOptions = ProductNode.findOptions(fungible).filter(o => !o._optional);

        if (!mainOptions.length) {
            cartSelection._mainSelection = cartSelection.item.nodeId;
            return cartSelection;
        }

        this._tranverseTree(cartSelection, (node) => {
            const option = mainOptions.find(o => o.nodeId === node.item.nodeId);
            if (option) {
                cartSelection._mainSelection = option.nodeId;
            }
        })

        return cartSelection;
    }

    static getAreaMainSelection(cartSelection, areas) {
        const mainSelection = cartSelection._mainSelection;
        for (const area of areas) {
            for (const fp of area.fungibleProducts) {
                if (cartSelection.item.nodeId === fp.nodeId) {
                    if (fp.options) {
                        const optionIds = fp.options.map(o => o.nodeId)
                        const idx = optionIds.indexOf(mainSelection);
                        if (idx >= 0) {
                            return optionIds[idx];
                        }
                    }
                    return fp.nodeId;
                }
            }
        }
    }

    /**
     * @param {CartSelection} cartSelection Cart selection to inspect
     * @param {String[]} productIds Product ids to search
     * @returns True if any productId in productIds is found within cartSelection tree
     */
    static containsProductIds(cartSelection, productIds) {
        let found = false;
        const productIdSet = new Set(productIds);

        this._tranverseTree(cartSelection, (node) => {
            if (productIdSet.has(node.item.nodeId)) {
                found = true;
            }
        })

        return found;
    }

    static hasStock(cartSelection, menu) {
        // The selection is repeatable if there's stock of everything
        return !this.containsProductIds(cartSelection, ProductNode.getOutOfStock(menu));
    }

    static hasRequiredProduct(cartSelection, requiredId) {
        let found = false;

        this._tranverseTree(cartSelection, (node) => {
            if (node.item.nodeId === requiredId) {
                found = true;
            }
        })

        return found;
    }

    /**
     * @param {*} cartSelection Product selection with Object representation
     * @param {*} requiringMap Map of {[productId]: boolean} that represents if the product is required or not.
     * @returns {Boolean} True if the cartSelection contains a product that requires another one.
     */
    static hasRequiringProduct(cartSelection, requiringMap) {
        let requires = false;

        this._tranverseTree(cartSelection, (node) => {
            if (node.item.nodeId in requiringMap) {
                requires = true;
            }
        })

        return requires;
    }
}

addClassMethods({
    fromClass: Tree,
    toClass: CartSelection
})

export class Cart extends Model {

    // static get uuid() { return ''; }

    static get model() {
        return {
            selections: [],
            placeId: '',
            eventId: '',
            promotionCodes: []
        }
    }

    static get keymap() {
        return {
            place: 'placeId',
            event: 'eventId'
        }
    }

    static get unserialMap() {
        return {
            selections: selections => selections.map(s => CartSelection.unserialize(s))
        }
    }

    static get toClassMap() {
        return {
            selections: selections => selections.map(s => new CartSelection(s))
        }
    }

    static get parseMap() {
        return {
            selections: selections => selections.map(s => CartSelection.parse(s))
        }
    }

    // static get parseCallback() {
    //     return (parsedCart) => {
    //         // Group same products

    //         parsedCart.selections = parsedCart.selections.reduce((prev, next) => {
    //             const selId = next._selectionId;

    //             if (selId in prev) {
    //                 prev[selId].quantity++;
    //             } else {
    //                 prev[selId] = { ...next, quantity: 1 };
    //             }

    //             return prev;
    //         }, {});

    //         return parsedCart;
    //     }
    // }

    constructor(args) {
        super(args);
        Object.assign(this, Cart.unserialize({ ...Cart.model, ...args }, { toClass: true }))
    }

    static toServer(cart) {
        return {
            place: cart.placeId,
            event: cart.eventId,
            promotion_codes: cart.promotionCodes,
            selections: cart.selections.map(s => CartSelection.toServer(s))
        }
    }

    static calculatePrice(cart, productTree, promotions, packs) {
        let total = 0;
        cart.selections.forEach(s => {
            total += CartSelection.calculatePrice(s, productTree, promotions, packs)
        });
        return total;
    }

    static calculateStandalonePrices(cart, productTree, promotions, packs) {
        let total = 0;
        cart.selections.forEach(s => {
            if (!s._packId) {
                total += CartSelection.calculatePrice(s, productTree, promotions, packs)
            }
        });
        return total;
    }

    static addSelectionIds(cart) {
        cart.selections = cart.selections.map(cs => CartSelection.addSelectionId(cs));
        return cart;
    }

    static stackSelections(cart) {
        const { packs, standalone } = CartSelection.stackCartSelections(cart.selections);
        return { ...cart, selections: standalone, packs };
    }

    static unstackSelection(cart) {
        const selections = CartSelection.unstackCartSelections(cart.selections, cart.packs);
        return { ...cart, selections };
    }

    static findPromotionUses(cart) {
        const ret = {};
        cart.selections.forEach(s => {
            const usedPromotions = CartSelection.findPromotionUses(s);
            Object.keys(usedPromotions).forEach(pId => {
                if (pId in ret) {
                    ret[pId] += usedPromotions[pId];
                } else {
                    ret[pId] = usedPromotions[pId];
                }
            })

        })

        return ret;
    }

    static removeSelectionWithProductIds(cart, productIds) {
        cart.selections = cart.selections.filter(sel => !CartSelection.containsProductIds(sel, productIds));
        return cart;
    }


    static showRequiredUpselling(cart, requiredId, requiringMap) {
        let requiredCount = 0, requiringCount = 0;

        cart.selections.forEach(sel => {
            if (CartSelection.hasRequiredProduct(sel, requiredId)) {
                requiredCount++;
            }
            if (CartSelection.hasRequiringProduct(sel, requiringMap)) {
                requiringCount++;
            }
        });

        if (requiringCount === 0) {
            return 0;
        }

        if (requiredCount > 0) {
            return 0;
        }

        return Math.max(0, requiringCount - requiredCount);
    }

}

export class SelectionTree extends Tree {

    // Just for the purpose of documenting as the other classes
    // but this is not a "server" entity

    static get model() {
        return {
            nodeId: '',
            marked: false,
            name: '',
            description: '',
            minChildren: 0,
            maxChildren: 0,
            amount: 0,
            soldOut: false,
            solved: false, // More children cannot be selected
            valid: false,
            impossible: false, // The node will be cut from the tree
            children: {},
            _mainSelection: null,
        }
    }

    static init = (productTree) => {

        const fillSelectionTree = (tree) => {

            const node = {
                nodeId: tree.nodeId,
                marked: false,
                name: tree.name,
                description: tree.description,
                minChildren: tree.minChildren,
                maxChildren: tree.maxChildren,
                amount: tree.price.amount,
                soldOut: ProductNode.isSoldOut(tree),
                solved: tree.maxChildren === 0, // More children cannot be selected
                valid: tree.minChildren === 0,
                impossible: false, // The node will be cut from the tree
                children: mapObjectValues(
                    tree.children,
                    child => fillSelectionTree(child)
                ),
                _mainSelection: null,
            }

            return node;
        }

        return fillSelectionTree(productTree);

    }


    // mark is an object with shape
    // { nodeId, value }
    // nodeId is a String, value is a boolean indicating
    // is it's being marked or unmarked
    static mark = (tree, toMark) => {

        // First, mark/unmark the node
        if (tree.nodeId === toMark.nodeId) {
            tree.marked = toMark.value;

            // If we mark the node and all it's children are needed, mark them as shallow
            if (toMark.value) {
                if (tree.minChildren === Object.keys(tree.children).length) {
                    Object.keys(tree.children).forEach(k => {
                        const child = tree.children[k];
                        this.mark(child, { nodeId: child.nodeId, value: 'shallow' });
                    })
                }
            } else {
                // If we unmark the node, unmark all shallows
                this.unmarkShallows(tree)
            }

        } else {
            // Or it's children - Recurse
            Object.keys(tree.children).forEach(k => {
                this.mark(tree.children[k], toMark);
            })
        }


        // Count marked direct children.
        const markedChildrenNumber = Object.keys(tree.children).reduce(
            (count, key) => {
                if (tree.children[key].marked) return count + 1;
                return count;
            }, 0);

        // Check if this node has been solved with that selection
        const solvedStatus = markedChildrenNumber === tree.maxChildren;

        if (solvedStatus !== tree.solved) {
            // Update solved status
            tree.solved = solvedStatus;

            // Mark impossibles direct children in case there's any now
            Object.keys(tree.children).forEach(k => {
                const child = tree.children[k];
                child.impossible = solvedStatus && !child.marked;
            });

        }

        // Mark node as valid/invalid with that selection
        // A node is valid if it has a valid marked number of children
        // And those who are marked are valid as well.
        const validChildren = Object.keys(tree.children).every(k => {
            const child = tree.children[k]
            return !child.marked || child.valid
        });

        tree.valid = (markedChildrenNumber <= tree.maxChildren
            && markedChildrenNumber >= tree.minChildren) && validChildren;

        tree.marked = tree.marked || (markedChildrenNumber >= 1);

        return tree;
    }

    static unmarkShallows = (tree) => {
        if (tree.marked === 'shallow') {
            tree.marked = false;
        }

        Object.keys(tree.children).forEach(k => {
            this.unmarkShallows(tree.children[k]);
        })

        return tree;
    }

    static calculatePrice = (tree) => {
        let price = 0;
        const sumPrice = (node) => {
            if (node.marked) {
                price += node.amount;
            }
        }
        this._tranverseTree(tree, sumPrice);
        return price;
    }

    // Transforms a selectionTree to a CartSelection server representation
    static toServer = (tree, { itemIdMap, packId } = { itemIdMap: null, packId: null }) => {
        const serverFormat = (tree) => {
            return {
                item: {
                    product_definition: tree.nodeId,
                    pack_item: itemIdMap ? itemIdMap[tree.nodeId]?.itemId : null,
                    promotion_codes: [],
                },
                children: Object.keys(tree.children).flatMap(nodeId => {
                    const child = tree.children[nodeId];
                    return child.impossible || !child.marked ? [] : [serverFormat(child)]
                })
            }
        }

        const ret = serverFormat(tree);
        ret._main_selection = tree._mainSelection;
        ret._pack_id = packId;
        return ret;

    }

    // This function gets the questions that have yet to be answered.
    // The leaf nodes of non-impossible parents.
    static calculateQuestions = (selectionTree) => {

        const questions = new Set();

        const getQuestions = (tree) => {
            // If the node is not accessible ignore it.
            if (tree.impossible) return;

            const hasLeafChild = Object.keys(tree.children).reduce((isLeaf, key) => {
                const child = tree.children[key];
                return isLeaf || (Object.keys(child.children).length === 0);
            }, false);


            // If a children is a leaf node and the question is still solvable or it's marked
            if (hasLeafChild && (!tree.solved || tree.marked)) {
                questions.add(tree.nodeId);
            }
        }

        this._tranverseTree(selectionTree, getQuestions);

        return questions;
    }

    static numberOfSolvables = (selectionTree) => {
        let total = 0;
        const countSolvables = (tree) => {
            // If the node is not accessible ignore it.
            if (tree.impossible) return;

            const hasLeafChild = Object.keys(tree.children).reduce((isLeaf, key) => {
                const child = tree.children[key];
                return isLeaf || (Object.keys(child.children).length === 0);
            }, false);


            // If a children is a leaf node and the question is still solvable or it's marked
            if (hasLeafChild && !tree.solved) {
                total++;
            }
        }
        this._tranverseTree(selectionTree, countSolvables);
        return total;
    }

    static findMarkedNames = (selectionTree) => {
        const markedNames = [];

        const fillMarked = (tree) => {
            if (tree.marked) markedNames.push(tree.name);
        }

        this._tranverseTree(selectionTree, fillMarked);

        return markedNames;
    }

    /**
     * Add _mainSelection to SelectionTree within it's area
     * @param {SelectionTree} selectionTree 
     * @param {ProductNode} fungible A fungible ProductNode
     */
    static addMainSelection(selectionTree, fungible) {
        if (!fungible) {
            return selectionTree;
        }

        // Get the true main options of the fungible
        const mainOptions = ProductNode.findOptions(fungible).filter(o => !o._optional);

        console.log(mainOptions);
        if (!mainOptions.length) {
            selectionTree._mainSelection = selectionTree.nodeId;
            return selectionTree;
        }

        this._tranverseTree(selectionTree, (node) => {
            if (!node.marked) return;
            const option = mainOptions.find(o => o.nodeId === node.nodeId);
            if (option) {
                selectionTree._mainSelection = option.nodeId;
            }
        })

        return selectionTree;
    }

    static findMainQuestionAnswer(selectionTree, fungible) {
        if (!fungible || !fungible.mainQuestion) {
            return;
        }

        const mainQuestionNode = this.getTreeNode(selectionTree, fungible.mainQuestion);
        if (!mainQuestionNode) return;

        let mainAnswer;
        Object.keys(mainQuestionNode.children).forEach(cId => {
            const child = mainQuestionNode.children[cId];
            if (child.marked) {
                return mainAnswer = cId;
            }
        })

        return mainAnswer;
    }

    static isComplete(tree) {
        return tree.marked && tree.solved && tree.valid;
    }

    static isValid(tree) {
        return tree.marked && tree.valid;
    }

    static getMarkedLeafs(tree) {
        return this._getLeafs(tree).filter(n => n.marked);
    }

    constructor(tree) {
        return this.init(tree);
    }

}