import { useEffect } from 'react';
import { Text, View, ScrollView, Dimensions, StyleSheet } from 'react-native';
import { Spacing, Mixins, Typography } from '@styles';
import { useTheme } from '@themeProvider';
import { useModal } from '@lib';
import { Order, PickupBar } from '@datamodel';
import { useLanguage } from '@languageProvider';
import { Button } from '@atoms';
import { useDispatch, useSelector } from 'react-redux';
import { activateSales, deactivateSales, getGrillOrders } from '@redux/actions';
import { GrillCard } from '@molecules';

const { width: dWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
    grillCard: {
        marginHorizontal: Spacing.XS, marginTop: Spacing.XS
    }
});

const GrillColumn = ({ orders, style, type, showNextAction,...props}) => {
    const { translate } = useLanguage();
    const { useColor } = useTheme();
    const [ inputBackground, title] = useColor(['inputBackground', 'title']);
    const rounding = Mixins.matchRounding(Mixins.rounding(64), Spacing.XS)
    return <View style={[style]}>
        <Text style={[ Typography.TITLE, { color: title, marginLeft: Spacing.M, marginBottom: Spacing.XS }]}>{translate(type)} ({Object.keys(orders).length})</Text>
        <ScrollView style={[rounding, { flex: 1, backgroundColor: inputBackground, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }]}>
            {
                Object.keys(orders).sort((k1,k2) => orders[k1].succeededAt - orders[k2].succeededAt ).map(oId => <GrillCard style={styles.grillCard} key={oId} order={orders[oId]} showNextAction={showNextAction}/>)
            }
        </ScrollView>
    </View>
}

export const GrillScreen = ({ navigation, route }) => {
    const { translate } = useLanguage();
    const { useColor, isDark } = useTheme();
    const dispatch = useDispatch();
    const pickupBarId = useSelector(state => state.authentication.pickupBarId);
    const pickupBar = useSelector(state => state.pickupBars[pickupBarId]) || PickupBar.model;
    const grillOrders = useSelector(state => state.grillOrders);
    const { pushAlert } = useModal();
    const [background, title, body, label, line, inputBackground] = useColor(['background', 'title', 'body', 'label', 'line', 'inputBackground']);

    const salesActive = pickupBar.place.menuSalesAvailable;

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getGrillOrders(pickupBarId));
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    const toggleSales = () => {
        const alertConfig = {
            style: {
                paddingHorizontal: dWidth * .3
            }
        }
        if (salesActive) {
            pushAlert({
                title: translate('deactivateSales'),
                text: translate('deactivateSalesExplanation'),
                actions: [{
                    function: () => {
                        dispatch(deactivateSales(pickupBarId));
                        return true;
                    },
                    type: 'main',
                    displayText: translate('deactivateSales')
                }]
            }, alertConfig)
        } else {
            pushAlert({
                title: translate('activateSales'),
                text: translate('activateSalesExplanation'),
                actions: [{
                    function: () => {
                        dispatch(activateSales(pickupBarId));
                        return true;
                    },
                    type: 'main',
                    displayText: translate('activateSales')
                }]
            }, alertConfig)
        }
    }
    return (
        <View style={{ flex: 1, backgroundColor: background, paddingHorizontal: Spacing.MARGINS }}>
            {/* <View style={[Mixins.HORIZONTAL, { justifyContent: 'flex-end', alignItems: 'center' }]}>
                <Text style={[Typography.TITLE, { color: body, marginRight: Spacing.M }]}>{ salesActive ? translate('salesAreActive') : translate('salesAreDeactive')} </Text>
                <Button onPress={toggleSales} type="secondary" color="warning" icon="warning" title={ salesActive ? translate('deactivateSales') : translate('activateSales') } size="condensed"/>
            </View> */}
            <View style={[{ flex: 1, justifyContent: 'center', padding: Spacing.MARGINS, paddingBottom: 0 }, Mixins.HORIZONTAL]}>
                <GrillColumn showNextAction={true} orders={grillOrders[Order.SERVING_PENDING]} style={{ flex: 1 }} type={Order.SERVING_PENDING}/>
                <GrillColumn showNextAction={true} orders={grillOrders[Order.SERVING_PREPARING]} type={Order.SERVING_PREPARING} style={{ flex: 1, marginHorizontal: Spacing.MARGINS }} />
                <GrillColumn showNextAction={true} orders={grillOrders[Order.SERVING_READY]} type={Order.SERVING_READY} style={{ flex: 1 }} />
            </View>
        </View>
    );
}