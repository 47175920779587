import React from 'react';
import { LoginScreen } from '@scenes';
import { createStackNavigator } from '@react-navigation/stack';

const Stack = createStackNavigator();

export const AuthNavigator = ({ }) => {

    const navigatorOptions = {
        gestureDirection: 'horizontal',
        presentation: 'card',
        headerShown: false,
        cardStyleInterpolator: ({ current, layouts }) => {
            return {
                cardStyle: {
                    transform: [
                        {
                            translateX: current.progress.interpolate({
                                inputRange: [0, 1],
                                outputRange: [layouts.screen.width, 0],
                            }),
                        },
                    ],
                    opacity: current.progress.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 1],
                    })
                },
            };
        },
    };

    return (
        <Stack.Navigator
            initialRouteName={'Login'}
            screenOptions={navigatorOptions}
        >
            <Stack.Screen
                name='Login'
                component={LoginScreen}
            />
        </Stack.Navigator>
    )
}