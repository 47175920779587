import { Text, View, ScrollView, Pressable, FlatList, Platform, StyleSheet } from "react-native";
import { useLanguage } from "@languageProvider";
import { useDispatch, useSelector } from "react-redux";
import { useModal, useSnackbar } from "@lib";
import { useTheme } from "@themeProvider";
import { Order, PickupBar } from "@datamodel";
import { Icon, Mixins, Spacing, Typography, Commons } from "@styles";
import { GrillCard, OrderBatch, PurchasesSummary } from "@molecules";
import { Button } from "@atoms";
import { objectMap } from "@utils/helpers";
import { addGrillBatch, getGrillOrders, setGrillOrderServingStatus } from "@redux/actions";
import { useEffect, useMemo } from "react";
import { printService } from '@utils/printing';
import { playNotification } from "@utils/audio";

const ORDERS_PER_BATCH = 3;

const printOrder = async (order, preparationGroups, menus) => {
    try {
        // screenTracker.printTicket('GrillTicket', {
        //     order: order.orderId,
        // })

        const ticket = Order.asGrillTicketHTML(order, preparationGroups, menus);


        if (!ticket) return;

        printService.print({ html: ticket });
    } catch (error) {
        console.log('PRINT ERROR:', error);
    }
    return true;
}

const PendingOrdersModal = ({ _dismissModal }) => {
    const dispatch = useDispatch();
    const pendingOrders = useSelector(state => state.grillOrders.pending);
    const pickupBarId = useSelector(state => state.authentication.pickupBarId);
    const menus = useSelector(state => state.menus);
    const preparationGroups = useSelector(state => state.preparationGroups);

    const { translate } = useLanguage();
    const { useColor, useElevation } = useTheme();
    const title = useColor('title');
    const grillCardElevation = useElevation(GrillCard.ELEVATION);

    const moveToPreparing = (orderId) => ({
        function: () => {
            dispatch(addGrillBatch({
                pickupBarId,
                batch: { [orderId]: Order.SERVING_PREPARING }
            }))

            dispatch(setGrillOrderServingStatus(pickupBarId, {
                orderId,
                servingStatus: Order.SERVING_PREPARING,
                currentStatus: Order.SERVING_PENDING
            }));

            // TODO: Parametrize print order.
            // printOrder(pendingOrders[orderId], preparationGroups, menus);

            return true;
        },
        displayText: `${translate('moveToPreparing')}`
    })

    return <View style={{ flex: 1 }}>
        <FlatList
            numColumns={3}
            contentContainerStyle={{ margin: Spacing.XS, paddingTop: Spacing.XL * 3 }}
            data={Object.keys(pendingOrders)}
            renderItem={({ item: orderId }) => <GrillCard
                style={{ flex: 1, margin: Spacing.XS }}
                extraActions={[moveToPreparing(orderId)]}
                order={pendingOrders[orderId]}
                key={orderId} />}
        />

        <View style={[Mixins.HORIZONTAL, GrillCard.ROUNDING, grillCardElevation, { marginHorizontal: Spacing.M, paddingVertical: Spacing.M, position: 'absolute', top: Spacing.M, left: 0, right: 0, justifyContent: 'space-between' }]}>
            <Text style={[Typography.H6, { color: title, marginHorizontal: Spacing.M }]}>{translate('pendingOrders')}</Text>
            <Pressable onPress={_dismissModal} hitSlop={Spacing.XS} style={{ paddingHorizontal: Spacing.M }}>
                <Icon name="close-alt" size={24} color={title} />
            </Pressable>
        </View>
    </View>
}

export const GrillBatchScreen = () => {
    const { translate } = useLanguage();
    const { useColor, useElevation } = useTheme();
    const dispatch = useDispatch();
    const pickupBarId = useSelector(state => state.authentication.pickupBarId);
    const pickupBar = useSelector(state => state.pickupBars[pickupBarId]) || PickupBar.model;
    const grillOrders = useSelector(state => state.grillOrders);
    const grillBatches = useSelector(state => state.grillBatches);
    const menus = useSelector(state => state.menus);
    const preparationGroups = useSelector(state => state.preparationGroups);

    const { pushModal } = useModal();
    const { pushSnack } = useSnackbar();
    const [background, title, body, label, line, inputBackground, badgeBgColor, automaticBgColor] = useColor(['background', 'title', 'body', 'label', 'line', 'inputBackground', 'primary', 'success']);
    const [badgeColor, automaticColor, automaticTextColor] = useColor([
        ['title', { on: badgeBgColor }],
        ['title', { on: automaticBgColor }],
        ['success', { isText: true }]
    ]);
    const buttonElevation = useElevation(2);

    // Calculate Preparing orders currently out of a batch:
    // console.log(grillOrders.preparing, grillBatches.orderIds);

    const singleOrders = useMemo(() => {
        const singleOrders = [];
        for (const orderId in grillOrders.preparing) {
            if (!(orderId in grillBatches.orderIds)) {
                singleOrders.push(grillOrders.preparing[orderId]);
            }
        }
        return singleOrders;
    }, [JSON.stringify(grillOrders.preparing), grillBatches.orderIds])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getGrillOrders(pickupBarId));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [])

    const activeBatches = grillBatches.batches.filter(batch => Object.keys(batch).some(orderId => orderId in grillOrders.preparing));

    useEffect(() => {
        console.log('!!', Object.keys(grillOrders.pending).length, activeBatches.length, singleOrders.length)
        if (Object.keys(grillOrders.pending).length && (activeBatches.length + singleOrders.length <= 0)) {
            playNotification();
        }
    }, [Object.keys(grillOrders.pending).length, activeBatches.length, singleOrders.length])

    const nextBatchPress = async (pendingOrders = {}) => {
        if (Object.keys(grillOrders.pending).length <= 0) {
            pushSnack(translate('noPendingOrdersExplanation'));
            return;
        }
        // Logic of next batch orders
        let sortedPendingKeys = Object.keys(pendingOrders)
            .sort((id1, id2) => {
                return grillOrders.pending[id1].succeededAt - grillOrders.pending[id2].succeededAt;
            }).slice(0, ORDERS_PER_BATCH);
        // .map(orderId => ({ orderId, succeededAt: grillOrders.pending[orderId].succeededAt })).sort((o1, o2) => o1.succeededAt - o2.succeededAt);
        sortedPendingKeys = sortedPendingKeys.slice(0, ORDERS_PER_BATCH);

        const batch = {};
        sortedPendingKeys.slice(0, ORDERS_PER_BATCH).forEach(orderId => {
            batch[orderId] = Order.SERVING_PREPARING;
        })

        // MOVE EACH ORDER TO PREPARING
        // TODO: DO THIS MORE CAREFULLY
        Object.keys(batch).forEach(orderId => {
            dispatch(setGrillOrderServingStatus(pickupBarId, {
                orderId,
                servingStatus: Order.SERVING_PREPARING,
                currentStatus: Order.SERVING_PENDING
            }));
        });

        dispatch(addGrillBatch({
            pickupBarId,
            batch
        }))

        for (const orderId in batch) {
            const order = grillOrders.pending[orderId];
            // TODO: Parametrize print order
            // await printOrder(order, preparationGroups, menus);
        }
    }

    const markOrdersAsReady = preparingOrders => {
        Object.keys(preparingOrders).forEach(orderId => {
            dispatch(setGrillOrderServingStatus(pickupBarId, {
                orderId,
                servingStatus: Order.SERVING_READY,
                currentStatus: Order.SERVING_PREPARING
            }));
        });
    };

    const showPendingOrders = () => {
        if (Object.keys(grillOrders.pending).length <= 0) {
            pushSnack(translate('noPendingOrdersExplanation'));
            return;
        }
        pushModal({
            style: { paddingHorizontal: Spacing.H },
            children: <PendingOrdersModal pendingOrders={grillOrders.pending} />
        })
    }

    return <View style={[Mixins.HORIZONTAL, { flex: 1, backgroundColor: background }]}>
        <View style={{ flex: 1 }}>

            {/* ACTION BAR */}
            <View style={[Mixins.HORIZONTAL, { height: 68, borderBottomWidth: 1, borderColor: line, alignItems: 'center', paddingHorizontal: Spacing.MARGINS }]}>
                <Pressable
                    onPress={showPendingOrders}
                    hitSlop={Spacing.XS}
                    style={[buttonElevation, Mixins.rounding(Button.HEIGHT), Mixins.NO_SELECT, {
                        height: Button.HEIGHT,
                        width: Button.HEIGHT,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }]}
                >
                    <Icon name="event-card" size={24} color={title} />
                    <View style={[[Mixins.rounding(16), {
                        position: 'absolute',
                        right: -2,
                        top: -6,
                        height: 16,
                        minWidth: 16,
                        backgroundColor: badgeBgColor,
                        padding: 2,
                        justifyContent: 'center',
                    }]]}>
                        <Text style={[Typography.XSMALL_COPY, {
                            color: badgeColor,
                            textAlign: 'center'
                        }]}>{Object.keys(grillOrders.pending).length}</Text>
                    </View>
                </Pressable>

                <View style={{ flex: 1 }} />
                <Pressable
                    onPress={() => {
                        if (Platform.OS === 'web') {
                            location.reload();
                        }
                    }}
                    hitSlop={Spacing.XS}
                    style={[buttonElevation, Mixins.rounding(Button.HEIGHT), Mixins.NO_SELECT, {
                        height: Button.HEIGHT,
                        width: Button.HEIGHT,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }]}
                >
                    <Icon name="refresh" size={24} color={title} />
                </Pressable>

                {/* <Pressable hitSlop={Spacing.XS}
                    style={[buttonElevation, Mixins.HORIZONTAL, Mixins.NO_SELECT, Mixins.rounding(Button.HEIGHT), {
                        height: Button.HEIGHT,
                        overflow: 'hidden',
                        marginLeft: Spacing.M
                    }]}>
                    <View style={[{
                        width: Button.HEIGHT,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: automaticBgColor,
                    }]}>
                        <Icon name="event-card" size={24} color={automaticColor} />
                    </View>
                    <View style={[{
                        justifyContent: 'center',
                        flex: 1,
                        paddingHorizontal: Spacing.M
                    }]}>
                        <Text style={[Typography.TITLE, {
                            color: automaticTextColor,
                        }]}>Automático</Text>
                    </View>

                </Pressable> */}

                {/* <View  style={{ flex: 1 }}/>
                <Button title="Parar ventas" type="border" color="destructive"/> */}

                <View style={{ position: 'absolute', bottom: -2, left: 0, right: 0, height: 1, backgroundColor: buttonElevation.backgroundColor }} />
            </View>

            {/* PREPARING MAIN VIEW */}
            <View style={[Mixins.HORIZONTAL, { paddingHorizontal: Spacing.MARGINS }]}>
                <View style={{ paddingVertical: Spacing.M, marginRight: Spacing.XS, width: '60%' }}>
                    <Text style={[Typography.TITLE, { color: title, paddingLeft: Spacing.M }]}>{translate('preparing')}</Text>
                </View>
                <View style={{ flex: 1, paddingVertical: Spacing.M }}>
                    <Text style={[Typography.TITLE, { color: title, paddingLeft: Spacing.M }]}>{translate('summary')}</Text>
                </View>
            </View>

            <ScrollView
                style={[
                    Mixins.matchRounding(GrillCard.ROUNDING, Spacing.XS),
                    {
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        backgroundColor: inputBackground,
                        marginHorizontal: Spacing.MARGINS
                    }
                ]}
                contentContainerStyle={[{
                    padding: Spacing.XS
                }]}
            >
                {/* BATCH */}
                {
                    activeBatches
                        .map((batch, i) => {
                            const batchOrders = objectMap(Object.keys(batch).map(orderId => grillOrders.preparing[orderId]).filter(x => !!x), 'orderId');
                            return <OrderBatch key={i} {...{ batchOrders, markOrdersAsReady, preparationGroups, menus }} />
                        })
                }

                {/* ORDERS THAT ARE NOT PRESENT IN A BATCH */}
                {singleOrders?.length > 0 &&
                    <OrderBatch key={'singleOrders'} {...{ batchOrders: objectMap(singleOrders, 'orderId'), markOrdersAsReady, preparationGroups, menus }} />
                }

                <Button type="primary" icon="add-alt" color="primary" title={translate('nextBatch')} style={{ alignSelf: 'center' }} onPress={() => nextBatchPress(grillOrders.pending)} status={Object.keys(grillOrders.pending).length <= 0 ? 'disabled' : 'default'} />

            </ScrollView>

        </View>
        <View style={{ borderLeftWidth: 1, borderColor: line, padding: Spacing.MARGINS, paddingBottom: 0 }}>
            <Text style={[Typography.TITLE, { color: title, marginLeft: GrillCard.ROUNDING.borderRadius + Spacing.XS }]}>{translate('ready')}</Text>
            <ScrollView
                style={[
                    Mixins.matchRounding(GrillCard.ROUNDING, Spacing.XS),
                    {
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        backgroundColor: inputBackground,
                        marginTop: Spacing.M
                    }
                ]}
                contentContainerStyle={[{
                    padding: Spacing.XS,
                    paddingBottom: 0,
                }]}
            >

                {Object.keys(grillOrders.ready).map(orderId => {
                    return <GrillCard
                        collapsed
                        key={orderId}
                        order={grillOrders.ready[orderId]}
                        style={{ marginBottom: Spacing.XS }}
                        showNextAction
                    />
                })}
            </ScrollView>
        </View>
    </View>
}