import { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Image, Platform, Linking, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Spacing, Mixins, Typography} from '@styles';
import { TextInput, Button } from '@atoms';
import FestoLogo from '@assets/images/FestoLogo';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { authenticate } from '@redux/actions';
import service from '@services';

const styles = StyleSheet.create({
    input: {
        alignSelf: 'stretch'
    },
})

export const LoginScreen = () => {
    const { translate } = useLanguage();
    const { useColor, isDark } = useTheme();
    const dispatch = useDispatch();    
    const { token } = useSelector(state => state.authentication);
    const [value, setValue] = useState(token ?? '');
    const [ backgroundColor, title, primary ] = useColor(['background', 'title', 'primary']);

    const loginPress = async () => {
        await dispatch(authenticate(value));
    }

    return (
        <View style={{ backgroundColor, flex: 1}}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', }}>
                <View style={[Mixins.HORIZONTAL, { alignItems: 'center' }]}>
                    <Image source={require('@assets/images/FesteaPOS.webp')} style={{ width: 150, height: 150, marginBottom: Spacing.M }} />
                    <FestoLogo
                        style={{ width: 350, marginBottom: Spacing.MARGINS, height: 100 }}
                        logoStyle={isDark ? 'light' : 'dark'} />
                </View>
                <TextInput
                    value={value}
                    label="Token"
                    setValue={setValue}
                    style={{ marginBottom: Spacing.M,  alignSelf: 'stretch'}}
                    centered
                    autoCapitalize='characters'
                    keyboardType='visible-password'
                />
                <Button loadOnPress onPress={loginPress} title="Login" style={{ alignSelf: 'stretch', marginTop: Spacing.M }} />

                {/* {
                    Object.keys(bars).length > 1 ?
                    <View style={{ marginTop: Spacing.M }}>
                        <Text style={[Typography.H6, { color: title, textAlign: 'center' }]}>{translate('selectYourBar')}</Text>
                        { Object.keys(bars).map(bId => {
                            const name = bars[bId].name;
                            return <Button onPress={() => barLogIn(bId)} type="secondary" title={name} size="x-condensed" style={{ marginTop: Spacing.M}}/>
                        })}
                    </View>
                    :
                } */}
            </View>
        </View>
    );
}