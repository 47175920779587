import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { Spacing, Mixins, Icon, Typography } from '@styles';
import { Promotion } from '@datamodel';
import { Checkbox, RadioButton } from '@atoms';
import { parsePrice } from '@utils/helpers';

export const PromotionCard = ({ promotion, place, onPress = () => { }, style }) => {
    const { translate } = useLanguage();
    const { useColor, useElevation } = useTheme();
    const [primary, body, label] = useColor(['primary', 'body', 'label']);
    const elevation = useElevation(4);

    return (
        <Pressable onPress={onPress} style={[elevation, { padding: 16 }, Mixins.rounding(108), style]}>
            <Text style={[Typography.TITLE, { color: primary }]}>{Promotion.getTitle(promotion)}</Text>
            {
                promotion.minAmount > 0 &&
                <Text style={[Typography.CAPTION, { color: body, marginTop: Spacing.XXS }]}>{translate('spending')} {parsePrice(promotion.minAmount, '€')}</Text>
            }
            {
                promotion.remainingRedemptionsPerUser > 0 &&
                <Text style={[Typography.CAPTION, { color: body, marginTop: Spacing.XXS }]}>{translate('usesLeft', { uses: promotion.remainingRedemptionsPerUser })}</Text>
            }
            <Text style={[Typography.BODY, { color: body, marginTop: Spacing.XXS }]}>{promotion.coupon.name}</Text>
            {/* <View style={{ flex: 1 }} />
            <View style={[Mixins.HORIZONTAL, { marginTop: Spacing.XS, alignItems: 'flex-start' }]}>
                {
                    !place &&
                    <>
                        <Icon name="festo" size={16} color={label} style={{ marginRight: Spacing.XXS }} />
                        <Text style={[Typography.CAPTION, { color: label, marginTop: 2 }]}>{translate('allVenues')}</Text>
                    </>
                }
                {
                    !!place &&
                    <>
                        <Icon name="location" size={16} color={label} style={{ marginRight: Spacing.XXS }} />
                        <Text style={[Typography.CAPTION, { color: label, marginTop: 2 }]}>{place.name}</Text>
                    </>
                }
            </View> */}
        </Pressable>
    )
}


export const PromotionSelectInput = ({ promotion, checked, onPress, style, checkbox = false, disabled, quantity, disabledPress }) => {
    const { useColor } = useTheme();
    const [title, body] = useColor(['title', 'body']);
    const SelectComponent = checkbox ? Checkbox : RadioButton;
    const _disabled = disabled || promotion.remainingRedemptionsPerUser <= 0;
    const textDecorationLine = (_disabled && !checked) ? 'line-through' : 'none';

    const remainings = promotion.remainingRedemptionsPerUser || promotion.remainingRedemptions;
    const promoQuantity = Math.min(quantity, remainings);

    const selectPress = () => onPress(promoQuantity);

    return (
        <SelectComponent
            disabled={_disabled}
            disabledPress={disabledPress}
            style={[{
                alignItems: 'flex-start',
                paddingHorizontal: Spacing.MARGINS,
                // backgroundColor: checked ? Colors.setOpacity(title, .05) : null,
                paddingVertical: Spacing.XS
            }, style]}
            checked={checked}
            onPress={selectPress}>

            <View style={{ flex: 1 }}>
                <View style={[Mixins.HORIZONTAL, { justifyContent: 'space-between' }]}>
                    <Text style={[Typography.TITLE, { color: checked ? title : body, flexShrink: 1, textDecorationLine }]}>{Promotion.getTitle(promotion)}</Text>
                    {remainings !== null &&
                        remainings > 0 &&
                        <Text style={[Typography.SUBTITLE, { color: body, flex: 0, marginLeft: Spacing.M, textDecorationLine }]}>{checked ? promoQuantity : 0}/{remainings}</Text>
                    }
                </View>
                <Text style={[Typography.BODY, { color: body, textDecorationLine }]}>{promotion.coupon.name}</Text>
            </View>
        </SelectComponent>
    )
}