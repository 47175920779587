import { StyleSheet, View, Text } from "react-native";
import { ProductNode, Purchase } from "@datamodel";
import { useLanguage } from "@languageProvider";
import { useTheme } from "@themeProvider";
import { Typography, Spacing, Mixins } from "@styles";

const styles = StyleSheet.create({
    purchaseCardInfo: {
        paddingVertical: Spacing.M - Spacing.S,
    }
})

export const PurchaseInfo = ({showArea=true, quantity=1, purchase=Purchase.model, menu=ProductNode.model, style, ...props}) => {
    const { useColor, useElevation } = useTheme();
    const { translate } = useLanguage();
    const [body, title, statusColor, inputBackground, label] = useColor([
        'body',
        'title',
        Purchase.getColor(purchase),
        'inputBackground',
        'label'
    ]);

    const { title: purchaseTitle, info } = Purchase.formatInfo(purchase, menu);
        
    return (<View style={[styles.purchaseCardInfo, style]}>
        { !!showArea && <Text numberOfLines={1} style={[Typography.OVERLINE, { color: title }]}>{purchaseTitle}</Text> }

        <View style={[ Mixins.HORIZONTAL, { flex: 1, }]}>
            <View style={[Mixins.rounding(20), { height: 20, width: 20, marginRight: Spacing.XXS, backgroundColor: inputBackground }]}>
                <Text style={[Typography.SMALL_COPY, { fontWeight: 'bold', color: title, textAlign: 'center'}]}>{quantity}</Text>
            </View>
            <View style={{ flex: 1 }}>
                <Text style={[Typography.SMALL_COPY, { color: title, flex: 1, fontWeight: 'bold', }]}>{purchaseTitle}</Text>
                { info.length > 0 &&
                    <Text style={[Typography.SMALL_COPY, { color: title, flex: 1 }]}>{info.join(' · ')}</Text>
                }
            </View>
        </View>

    </View>)
}