import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Text, View, ScrollView, Animated, Pressable, StyleSheet, Platform, StatusBar } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Orders, PickupBars, Trackings } from '@services';
import { AddItemModal, AddPackModal, PaymentModal, ChangeStockModal } from '@modals';
import { ProductNode, Cart, CartSelection, Order, SelectionTree, ProductPack, Promotion, Coupon } from '@datamodel';
import { FungibleCard, CartRow, CartPackRow, ProductPackCard } from '@molecules';
import { Spacing, Mixins, Typography, Icon, Commons } from '@styles';
import { useTheme } from '@themeProvider';
import { FlatGrid, SmartFlatGridView, useModal, useSnackbar } from '@lib';
import { useLanguage } from '@languageProvider';
import { Button, Switch, TextInput } from '@atoms';
import { parsePrice, findFinalTotal, parseDiscount, objectFilter } from '@utils/helpers';
import { saveDraft, logOut, setLastMenu, hardLogOut } from '@redux/actions';
import { useFocusEffect } from '@react-navigation/native';
import Animation from '@utils/animations';
import { useTabBarIsCollapsed } from '@navigations';
import { KeyboardSafeScrollView } from '@lib';
import { printService } from '@utils/printing';

const screenTracker = new Trackings.Tracker('OrderScreen');

const styles = StyleSheet.create({
    paymentSelect: {
        height: 56,
        ...Mixins.rounding(56),
        marginLeft: Spacing.XXS,
        flex: 1,
        marginVertical: Spacing.XS,
        alignItems: 'center',
        justifyContent: 'center'
    },
    navBarButton: {
        height: 40,
        ...Mixins.rounding(40),
        marginLeft: Spacing.M,
        alignItems: 'center',
        justifyContent: 'center',
        ...Platform.select({
            native: {}, default: {
                userSelect: 'none'
            }
        }),
        paddingHorizontal: Spacing.M
    }
});

const Navbar = ({ navbar, scrollToArea, ...props }) => {
    const { useElevation, useColor } = useTheme();
    const elevation = useElevation(2);
    const title = useColor('title');
    if (Object.keys(navbar).length <= 1) return <></>;
    return <ScrollView
        horizontal
        {...props}
        contentContainerStyle={[{ paddingVertical: Spacing.XS }]}
    >
        {Object.keys(navbar).map(k => (
            <Pressable key={k} style={[styles.navBarButton, elevation]} onPress={() => { scrollToArea(k) }}>
                <Text style={[Typography.SUBTITLE, { color: title }]}>{navbar[k].title}</Text>
            </Pressable>
        ))}
    </ScrollView>
}

const DiscountWidget = ({ discount, setDiscount, free, setFree, open, setOpen, total, style, scrollToInput }) => {
    const animation = useRef(new Animated.Value(open ? 1 : 0)).current;
    const { translate } = useLanguage();
    const { useColor, isDark } = useTheme();
    const [background, title, primary, label, line, inputBackground] = useColor(['background', 'title', 'primary', 'label', 'line', 'inputBackground']);

    useEffect(() => {
        Animated.timing(animation, Animation.short(open ? 1 : 0)).start();
    }, [open]);

    useEffect(() => {
        if (free) {
            setDiscount(total / 100 + '')
        }
    }, [total])

    const switchPress = () => {
        if (free) {
            setDiscount('');
        } else {
            setDiscount(total / 100 + '');
        }
        setFree(!free);
    }

    return (
        <View style={[style, { overflow: 'hidden' }]}>
            <Pressable hitSlop={Spacing.M} onPress={() => setOpen(!open)} style={[Mixins.HORIZONTAL, { alignItems: 'center' }]}>
                <Text style={[Typography.OVERLINE, { color: label }]}>{translate('applyADiscount?')}</Text>

                <Animated.View
                    style={{ transform: [{ rotate: animation.interpolate(Animation.keyframes('0deg', '180deg')) }] }}
                >
                    <Icon name="down-arrow" size={24} color={primary} />
                </Animated.View>
            </Pressable>
            <Animated.View style={{
                maxHeight: animation.interpolate(Animation.keyframes(0, 120))
            }}>
                {!!open &&
                    <>
                        <TextInput
                            scrollToInput={scrollToInput}
                            keyboardType="numeric"
                            prefix="€"
                            editable={!free}
                            placeholder={translate('discount')}
                            style={{ flexGrow: 1, marginBottom: Spacing.XXS }}
                            value={discount}
                            setValue={setDiscount}
                        />
                        <Switch label={translate('free')} checked={free} onPress={switchPress} />
                    </>
                }

            </Animated.View>
        </View>
    )
}

export const OrderScreen = ({ navigation, route }) => {
    const isRendered = useRef();
    const orderStartedAt = useRef({
        time: null,
        total: 0,
    });
    const { isCollapsed } = useTabBarIsCollapsed();
    // const dlen = useSelector(state => state.drafts.length);
    const menus = useSelector(state => state.menus);
    const [lastMenu, lastPacks, lastPromotions] = useSelector(state => [state.lastMenu, state.lastPacks, state.lastPromotions]);
    const preparationGroups = useSelector(state => state.preparationGroups);
    const { eventId, placeId } = useSelector(state => state.authentication);
    const pickupBar = useSelector(state => state.pickupBars[state.authentication.pickupBarId]);
    // const totalDrafts = useRef(dlen);
    const menuScollRef = useRef();
    const totalPacks = useRef(0);
    const kbSafe = useRef();
    const dispatch = useDispatch();
    const [menu, setMenu] = useState(lastMenu);
    const [packs, setPacks] = useState(lastPacks);
    const [areas, setAreas] = useState();
    const [discount, setDiscount] = useState('');
    const [free, setFree] = useState(false);
    const [open, setOpen] = useState(false);
    const [stockEditingMode, setStockEditingMode] = useState(false);
    const navbarRef = useRef({});
    const [navbar, setNavbar] = useState({});
    const [serverCart, setServerCart] = useState({
        selections: [],
        promotion_codes: [],
    });

    const [promotions, setPromotions] = useState(lastPromotions);
    const usedPromotions = useMemo(() => Cart.findPromotionUses(Cart.parse(serverCart)), [serverCart]);
    const tempPromotions = useMemo(() => objectFilter(Promotion.updatePromotionUses(JSON.parse(JSON.stringify(promotions)), usedPromotions), p => Promotion.maxUses(p) > 0), [promotions, usedPromotions]);
    const { coupons, offers } = useMemo(() => Promotion.collectionSplitByType(tempPromotions), [tempPromotions]);

    const { pushModal, pushAlert } = useModal();
    const { pushSnack } = useSnackbar();
    const { translate } = useLanguage();
    const { useColor, isDark } = useTheme();
    const [background, title, primary, label, line, inputBackground] = useColor(['background', 'title', 'primary', 'label', 'line', 'inputBackground']);
    const settings = useSelector(state => state.settings);

    const stackedCart = useMemo(() => {
        return Cart.stackSelections(Cart.addSelectionIds(Cart.parse(serverCart)));
    }, [serverCart]);

    const cartCount = useMemo(() => {
        let total = 0;
        let grouped = {};

        const addToGrouped = (id, qty = 1) => {
            if (id in grouped) {
                grouped[id] += qty;
            } else {
                grouped[id] = qty;
            }
        }

        Object.keys(stackedCart.selections).forEach(selId => {
            const sel = stackedCart.selections[selId];
            addToGrouped(CartSelection.getAreaMainSelection(sel, areas), sel.quantity);
            total += sel.quantity;
        })

        Object.keys(stackedCart.packs).forEach(pIndex => {
            const pack = stackedCart.packs[pIndex];
            addToGrouped(pack.packId, pack.quantity);
            total += pack.quantity * pack.length;
        });

        return { total, grouped };
    }, [stackedCart]);

    const productsInfo = useMemo(() => {
        const ret = {};

        // Add standalone products info
        for (const _selId in stackedCart.selections) {
            ret[_selId] = CartSelection.getProductInfo(stackedCart.selections[_selId], menu);
        }

        // Add pack products info
        for (const _packIndex in stackedCart.packs) {
            const selections = stackedCart.packs[_packIndex].selections;
            for (const _selId in selections) {
                ret[_selId] = CartSelection.getProductInfo(selections[_selId], menu);
            }
        }

        return ret;
    }, [stackedCart]);

    const totalPrice = useMemo(() => {
        let price = Cart.calculateStandalonePrices(Cart.parse(serverCart), menu, promotions);

        price += Object.keys(stackedCart.packs).reduce((prevPacksPrice, packIndex) => {
            const cartPack = stackedCart.packs[packIndex];
            const selectionPrices = CartSelection.collectionCalculatePrices(cartPack.selections, menu);

            const selectionByPackItems = Object.keys(cartPack.selections).reduce((acc, selId) => {
                const packItemId = cartPack.selections[selId].item.packId;
                if (packItemId in acc) {
                    acc[packItemId].push(cartPack.selections[selId]);
                } else {
                    acc[packItemId] = [cartPack.selections[selId]];
                }
                return acc;
            }, {});

            const packPrice = Object.keys(selectionByPackItems).reduce((acc, packItemId) => {
                const packItem = packs[cartPack.packId].items[packItemId];
                const selections = selectionByPackItems[packItemId];
                let itemPrice = selections.reduce((prev, sel) => prev + selectionPrices[sel._selectionId] * sel.quantity, 0)
                if (packItem.coupon) {
                    itemPrice = Coupon.discountedPrice(packItem.coupon, itemPrice);
                }
                return acc + itemPrice;
            }, 0);

            return prevPacksPrice + packPrice * cartPack.quantity;
        }, 0);

        return price;
    }, [serverCart.selections.length, menu, packs, stackedCart])

    const addToCartPress = (serverItem, { quantity, selectedPromotions = {} }) => {
        const selections = serverCart.selections;

        const nItems = serverItem.length || 1;
        // serverItem.item.promotion_codes = promotionIds;

        const addSelections = Array.from({ length: quantity }, _ => JSON.parse(JSON.stringify(serverItem))).flat();

        // Add promotions
        Object.keys(selectedPromotions).forEach(promoId => {
            const { quantity } = selectedPromotions[promoId];
            addSelections.slice(0, quantity).forEach(sItem => {
                sItem.item.promotion_codes.push(promoId);
            })
        })

        let isPackItem = false;
        addSelections.forEach(sel => {
            if (sel.item.pack_item) {
                sel._pack_index = totalPacks.current;
                sel._pack_length = nItems;
                isPackItem = true;
            }
        });

        if (isPackItem) totalPacks.current++;

        setServerCart({
            ...serverCart,
            selections: [...selections, ...addSelections],
        })
    };

    const printOrder = async (order, { grill } = { grill: false }) => {
        try {
            screenTracker.printTicket(grill ? 'GrillTicket' : 'ClientTicket', {
                order: order.orderId,
            })

            const ticket = grill ?
                Order.asGrillTicketHTML(order, preparationGroups, menus) :
                Order.asTicketHTML(order, preparationGroups, menus, { pickupBar, paperWidth: settings.paperWidth });


            if (!ticket) return;

            printService.print({ html: ticket });
        } catch (error) {
            console.log('PRINT ERROR:', error);
        }
        return true;
    }

    const refreshMenu = async () => {
        try {
            const response = await Orders.getMenu();
            if (response.status === 'ok') {

                const { packs } = await Orders.getPacks();
                if (!packs) {
                    throw ('Error getting packs');
                }

                const { promotions } = await PickupBars.getPromotions();
                if (!promotions) {
                    throw ('Error getting promotions');
                }

                // TODO: Change menu only if something has changed

                const fullPacks = ProductPack.collectionAddInformation(packs, response.menu);
                // console.log(fullPacks, packs)
                // const fullPacks = {}
                // const promotions = {}

                dispatch(setLastMenu(response.menu, fullPacks, promotions));
                setMenu(response.menu);
                setPacks(fullPacks);
                setPromotions(promotions);

                // setServerCart({
                //     selections: [],
                //     promotion_codes: [],
                // })

                // Set areas with whichever menu is going to be used
                setAreas(ProductNode.formatAreas(ProductNode.standaloneProducts(response.menu)))
                return response.menu;

            } else {
                throw ('Error getting menu')
            }
        } catch (error) {
            console.error(error);
            if (error.statusCode === 403 || error.statusCode === 401) {
                dispatch(hardLogOut);
            }
        }
    }

    useEffect(() => {
        (async () => {
            let menu = lastMenu;
            if (!route.params?.menu) {
                // Is not a saved order
                // Get last online menu
                await refreshMenu();
            } else {
                if (menu.nodeId) {
                    // Set areas with whichever menu is going to be used
                    setAreas(ProductNode.formatAreas(ProductNode.standaloneProducts(menu)))
                }
            }
        })();
    }, []);

    useEffect(() => {
        setNavbar(navbarRef.current);
    }, [isCollapsed])

    const displayItemModal = useMemo(() => AddItemModal.display({
        addToCartPress,
        coupons,
        offers,
        showStepper: true,
    }), [addToCartPress, coupons, offers]);

    const productCardPress = (fungibleParent) => (option) => {


        const selectionTree = SelectionTree.init(fungibleParent);
        const nodeId = option.nodeId;
        SelectionTree.mark(selectionTree, { nodeId, value: true });

        const _trackConfig = {
            fungible: fungibleParent.nodeId,
            option: option.nodeId,
        }

        if (stockEditingMode) {
            screenTracker.tap('ProductCard', {
                ..._trackConfig,
                action: 'editStock'
            });
            ChangeStockModal.display(fungibleParent, refreshMenu)(option, selectionTree);
            return;
        }

        if (selectionTree.marked && selectionTree.solved && selectionTree.valid) {
            addToCartPress(SelectionTree.toServer(SelectionTree.addMainSelection(selectionTree, fungibleParent)), { quantity: 1 });

            screenTracker.tap('ProductCard', {
                ..._trackConfig,
                action: 'addToCart'
            });

        } else {
            displayItemModal(fungibleParent)(option, selectionTree);

            screenTracker.tap('ProductCard', {
                ..._trackConfig,
                action: 'display.AddItemModal'
            });
        }

    }

    const packCardPress = (pack) => {
        screenTracker.tap('PackCard', {
            action: 'display.AddPackModal',
            pack: pack.packId
        });

        const packAreas = ProductPack.getModalAreas(pack);

        pushModal({
            children: <AddPackModal
                style={{ maxHeight: Commons.dHeight - Spacing.NAVBAR * 2, width: Commons.dWidth - Spacing.MARGINS * 2 }}
                pack={pack}
                addToCartPress={addToCartPress}
                productCardPress={productCardPress}
                areas={packAreas}
            />,
            style: {
                justifyContent: 'center',
                alignItems: 'center',
            }
        })

    }


    const deleteSelection = (_selId) => {
        screenTracker.tap('DeleteSelectionButton', {
            action: 'deleteFromCart',
        })
        const cart = Cart.unstackSelection(stackedCart);

        setServerCart(Cart.toServer({
            ...cart,
            selections: cart.selections.filter(s => s._selectionId !== _selId)
        }));
    }

    const setSelectionQuantity = (_selId) => (quantity) => {
        screenTracker.tap('ProductStepper', {
            action: 'updateProductQuantity',
            quantity: quantity + ''
        })
        const cart = Cart.unstackSelection({
            ...stackedCart,
            selections: {
                ...stackedCart.selections,
                [_selId]: {
                    ...stackedCart.selections[_selId],
                    quantity
                }
            }
        });

        setServerCart(Cart.toServer({
            ...cart,
            selections: cart.selections
        }));
    }


    const setPackQuantity = (_packIndex, cartPack) => (quantity) => {
        screenTracker.tap('PackStepper', {
            action: 'updatePackQuantity',
            quantity: quantity + ''
        })
        const cart = Cart.parse(serverCart);

        const selections = [];

        // Add those which are not in the pack
        cart.selections.forEach(s => {
            if (s._packIndex != _packIndex) {
                selections.push(s)
            }
        })

        // Add the pack ones, unrolling them first
        const singlePack = Object.keys(cartPack.selections).flatMap(_selId => {
            const itemSel = cartPack.selections[_selId];
            return new Array(itemSel.quantity).fill(itemSel)
        })

        const packSelections = (new Array(quantity).fill(singlePack)).flat();
        selections.push(...packSelections);

        setServerCart(Cart.toServer({ ...cart, selections }));

    }

    useEffect(() => {
        if (serverCart.selections.length > 0 && orderStartedAt.current.total === 0) {
            orderStartedAt.current.time = new Date();
        }

        orderStartedAt.current.total = serverCart.selections.length;

        navigation.setParams({
            serverCart,
            menu,
            updateCart: true,
        })
    }, [serverCart]);

    const deleteOrder = () => {
        setServerCart({
            selections: [],
            promotion_codes: []
        });
    }

    const addToNavigation = ({ offset, key, title }) => {
        navbarRef.current = {
            ...navbarRef.current,
            [key]: { offset, title }
        }

        if (!isRendered.current && Object.keys(navbarRef.current).length === areas?.filter(a => a?.fungibleProducts?.length).length) {
            isRendered.current = true;
            setNavbar(navbarRef.current);
        }
    }

    const scrollToArea = (key) => {
        if (menuScollRef.current) {
            menuScollRef.current.scrollTo({
                y: navbar[key].offset
            })
        }
    }

    const showPaymentModal = async () => {
        screenTracker.tap('PayButton', {
            action: 'show.PaymentModal',
        })

        const onMarkAsPaid = async (order) => {

            deleteOrder();

            const printKitchenTicket = () => {
                if (!Order.isGrillPrintable(order, preparationGroups)) return;
                if (pickupBar.printKitchenTickets === 'always') {
                    printOrder(order, { grill: true });
                } else if (pickupBar.printKitchenTickets === 'optional') {
                    pushAlert({
                        title: translate('printGrillTicket'),
                        text: translate('printGrillTicketExplanation'),
                        actions: [{
                            function: () => { return true },
                            displayText: 'No',
                            type: 'destructive'
                        }, {
                            function: () => {
                                printOrder(order, { grill: true });
                                return true;
                            },
                            displayText: translate('print'),
                            type: 'main'
                        }],
                        style: {
                            maxWidth: '60vw',
                            margin: 'auto'
                        }
                    })
                }
            }

            if (pickupBar.printCustomerTickets === 'always') {
                await printOrder(order, { grill: false });
                printKitchenTicket();
            } else if (pickupBar.printCustomerTickets === 'optional') {
                pushAlert({
                    title: translate('printCustomerTicket'),
                    text: translate('printCustomerTicketExplanation'),
                    actions: [{
                        function: () => {
                            printKitchenTicket(); return true
                        },
                        displayText: 'No',
                        type: 'destructive'
                    }, {
                        function: async () => {
                            await printOrder(order);
                            printKitchenTicket();
                            return true;
                        },
                        displayText: translate('print'),
                        type: 'main'
                    }],
                    style: {
                        maxWidth: '60vw',
                        margin: 'auto'
                    }
                })
            } else {
                printKitchenTicket();
            }
        }

        //TODO: only create the order when not presenting a payment method modal
        try {
            // const serviceType = pickupBar.hasPreparedPickup ? 'prepared' : 'standard';
            const response = await Orders.create({ ...serverCart, amountOff: parseDiscount(discount), eventId, placeId, startedAt: orderStartedAt.current.time });

            if (response.status !== 'ok') {
                pushSnack({
                    type: 'warning',
                    icon: 'warning',
                    text: translate('somethingWentWrong')
                });
                return;
            }

            const order = response.order;
            const total = findFinalTotal(totalPrice, discount)

            pushModal({
                style: {
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                children: <PaymentModal {...{ mode: 'generic', total, orderId: order.orderId, refreshMenu, callback: onMarkAsPaid }}
                    style={{ maxWidth: 500 }}
                />
            })


        } catch (error) {
            console.log(error);
            switch (error.mainError) {
                case 'out_of_stock':
                    pushSnack({
                        type: 'warning',
                        icon: 'warning',
                        text: translate('somethingOutOfStock')
                    })



                    const outOfStockIds = error.keycodes.map(code => code.split(':')[1]);

                    setServerCart(
                        Cart.toServer(Cart.removeSelectionWithProductIds(Cart.parse(serverCart), outOfStockIds))
                    )

                    await refreshMenu();
                    break;

                case 'menu_sales_not_available':
                    pushSnack({
                        type: 'warning',
                        icon: 'warning',
                        text: translate('salesAreNotAvailable')
                    })
                    break;

                case 'inactive_menu':
                    pushSnack({
                        type: 'warning',
                        icon: 'warning',
                        text: translate('thisMenuIsOutdated')
                    })
                    refreshMenu();
                    break;


                default:
                    pushSnack({
                        type: 'warning',
                        icon: 'warning',
                        text: translate('somethingWentWrong')
                    })
            }


            if (error.statusCode === 403 || error.statusCode === 401) {
                dispatch(hardLogOut);
            }
        }

    }


    const buttonsDisabled = {
        disabled: cartCount.total <= 0,
        status: cartCount.total > 0 ? 'default' : 'disabled'
    }

    return (
        <View style={[{ flex: 1, justifyContent: 'center', backgroundColor: background }, Mixins.HORIZONTAL]}>
            <StatusBar hidden />
            <View style={{ flexGrow: 3, flexShrink: 1, paddingTop: Spacing.XS }}>
                <Navbar navbar={navbar} scrollToArea={scrollToArea} style={{ flexGrow: 0, marginBottom: Spacing.XS }} />
                <ScrollView
                    ref={menuScollRef}
                    style={{ flex: 3 }}
                    showsVerticalScrollIndicator={false}
                >

                    {!!areas &&
                        areas.filter(a => a.fungibleProducts.length).map(area => (
                            <View key={area.areaId}
                                style={{ marginHorizontal: Spacing.MARGINS }}
                                onLayout={({ nativeEvent }) => {
                                    addToNavigation({
                                        offset: nativeEvent.layout.y,
                                        key: area.areaId,
                                        title: area.name
                                    })
                                }}>
                                <Text style={[Typography.H6, { color: title, marginBottom: Spacing.M }]}>{area.name}</Text>
                                <ScrollView
                                    contentContainerStyle={{
                                        flexWrap: 'wrap',
                                        flexDirection: 'row',
                                        paddingVertical: Spacing.XXS,
                                        paddingHorizontal: Spacing.XXS,
                                    }}
                                    style={{ marginBottom: Spacing.M }}
                                >
                                    {area.fungibleProducts.map(fp => (
                                        <FungibleCard
                                            wiggle={stockEditingMode}
                                            pressThrough={stockEditingMode}
                                            cartCount={cartCount.grouped}
                                            displayModal={productCardPress(fp.node)}
                                            key={fp.nodeId}
                                            product={fp.node}
                                            style={{ marginBottom: Spacing.M, marginRight: Spacing.M }}
                                            options={fp.options} />
                                    ))}
                                </ScrollView>
                            </View>
                        ))}

                    {Object.keys(packs).length > 0 &&
                        <View>

                            <Text style={[Typography.H6, { color: title, marginBottom: Spacing.M, marginHorizontal: Spacing.MARGINS }]}>{translate('packs')}</Text>

                            <FlatGrid
                                data={Object.keys(packs)}
                                contentContainerStyle={{ paddingHorizontal: Spacing.MARGINS }}
                                renderItem={({ item, index }) => {

                                    if (item.blank) {
                                        return <View style={{ marginBottom: Spacing.M, marginLeft: index % 2 ? Spacing.M : 0, flex: 1 }} />
                                    }
                                    return <ProductPackCard
                                        cartCount={cartCount.grouped[item]}
                                        onPress={() => packCardPress(packs[item])}
                                        pack={packs[item]}
                                        key={item}
                                        style={{ marginBottom: Spacing.M, marginLeft: index % 2 ? Spacing.M : 0, flex: 1 }} />
                                }}
                                numColumns={2}
                            />
                        </View>
                    }

                </ScrollView>

                <View style={[Mixins.HORIZONTAL, { justifyContent: 'flex-end', margin: Spacing.MARGINS }]}>
                    {/* <Button type={stockEditingMode ? 'secondary' : 'primary'} size="condensed" onPress={() =>{
                        screenTracker.tap('StockEditButton', { action: 'toggle.stockEditingMode' });
                        setStockEditingMode(!stockEditingMode)}} style={{ marginRight: Spacing.XS }} title={translate(stockEditingMode ? 'finishEditingStock' : 'editStock')} icon="receipt" color="warning" /> */}
                    <Button size="condensed" {...buttonsDisabled} onPress={deleteOrder} title={translate('deleteOrder')} icon="trash" color="destructive" />
                </View>
            </View>

            <KeyboardSafeScrollView
                bounces={false}
                scrollToOverflowEnabled
                ref={kbSafe}
                contentContainerStyle={{ flex: 1 }}
                showsVerticalScrollIndicator={false}
                style={{ minWidth: Commons.cartMenuWidth, width: Commons.cartMenuWidth, borderLeftWidth: 1, borderColor: line }}>
                <ScrollView style={{ flexGrow: 1 }}>

                    {!!stackedCart?.packs &&
                        Object.keys(stackedCart.packs).sort().map((_packIndex, i) => {
                            const cartPack = stackedCart.packs[_packIndex];
                            const pack = packs[cartPack.packId];
                            const packPrices = CartSelection.collectionCalculatePrices(cartPack.selections, menu);
                            return <CartPackRow
                                {...{
                                    style: {
                                        paddingHorizontal: Spacing.L,
                                        paddingVertical: Spacing.M,
                                        borderBottomWidth: 1,
                                        borderColor: line,
                                        backgroundColor: i % 2 ? inputBackground : background
                                    },
                                    cartPack,
                                    pack,
                                    productsInfo,
                                    packPrices,
                                    setPackQuantity: setPackQuantity(_packIndex, cartPack)
                                }}
                            />
                        })
                    }

                    {!!stackedCart?.selections &&
                        Object.keys(stackedCart.selections).sort().map((_selId, i) => {
                            const itemPrice = CartSelection.calculatePrice(stackedCart.selections[_selId], menu, promotions);
                            const promotionCodes = stackedCart.selections[_selId].item.promotionCodes
                            const itemPromotions = promotionCodes.map(pId => promotions[pId]);
                            const itemTempPromotions = promotionCodes.map(pId => tempPromotions[pId]);
                            const qty = stackedCart.selections[_selId].quantity;
                            const maxRemainingUses = itemTempPromotions.length ? Math.min(itemTempPromotions.map(p => Promotion.maxUses(p))) : undefined;

                            return <CartRow
                                style={{ paddingHorizontal: Spacing.MARGINS, borderBottomWidth: 1, borderColor: line, backgroundColor: (i + Object.keys(stackedCart.packs).length) % 2 ? inputBackground : background }}
                                cartPrice={itemPrice * qty}
                                key={_selId}
                                deleteSelection={() => deleteSelection(_selId)}
                                setSelectionQuantity={(v) => setSelectionQuantity(_selId)(v)}
                                productsInfo={productsInfo[_selId]}
                                cartSelection={stackedCart.selections[_selId]}
                                promotions={itemPromotions}
                                maxQuantity={(maxRemainingUses + qty) || undefined}
                            />
                        })
                    }
                </ScrollView>
                <View style={{ paddingHorizontal: Spacing.MARGINS, borderTopWidth: 1, borderColor: line, paddingTop: Spacing.XS }}>
                    <DiscountWidget
                        scrollToInput={(args) => kbSafe.current.scrollToInput(args)}
                        {...{ discount, setDiscount, open, setOpen, total: totalPrice, free, setFree }} style={{ marginBottom: Spacing.XS }} />
                </View>
                <View style={{ paddingHorizontal: Spacing.MARGINS, ...Mixins.HORIZONTAL }}>
                    <Text style={[Typography.TITLE, { color: title, flexGrow: 1 }]}>Total</Text>
                    {discount !== '' && findFinalTotal(totalPrice, discount) !== totalPrice &&
                        <Text style={[Typography.TITLE, { color: label, textDecorationLine: 'line-through' }]}>{parsePrice(totalPrice, '€')}</Text>
                    }
                    <Text style={[Typography.TITLE, { color: primary }]}> {parsePrice(findFinalTotal(totalPrice, discount), '€')}</Text>

                </View>
                <View style={{ padding: Spacing.MARGINS, paddingTop: Spacing.XS }}>
                    <Button {...buttonsDisabled} loadOnPress onPress={showPaymentModal} title={translate('pay')} />
                </View>
            </KeyboardSafeScrollView>

        </View>
    );
}
