import rootReducer from '@redux/reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer, createTransform, createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import AsyncStorage from '@react-native-async-storage/async-storage';
import service, { Trackings } from '@services';
import { printService } from '@utils/printing';

// Transform for redux-persist that sets the token and uuid of the service
// singleton upon rehydrating
const TokenTransform = createTransform(
    (inboundState, key) => {
        return { ...inboundState }
    },
    (outboundState, key) => {
        service.api_token = outboundState.token;
        service.bar_id = outboundState.pickupBarId;
        Trackings.TRACKING_CONFIG.userId = outboundState.userId;
        return { ...outboundState }
    },
    { whitelist: ['authentication'] }
)

const ReadingSessionTransform = createTransform(
    (inboundState, key) => {
        return { ...inboundState }
    },
    (outboundState, key) => {
        return { ...outboundState, visible: false }
    },
    { whitelist: ['readingSession'] }
)

const SettingsTransform = createTransform(
    (inboundState, key) => {
        return { ...inboundState }
    },
    (outboundState, key) => {
        // Sets print service printing mode upon rehydrating
        printService.mode = outboundState.printMode;
        return { ...outboundState }
    },
    { whitelist: ['settings'] }
);


const migrations = {
    1: (state) => {
        // New menu representation. Packs implementation
        return {
            ...state,
            history: {},
            menus: {},
        };
    },
}



const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  transforms: [TokenTransform, ReadingSessionTransform, SettingsTransform],
  version: 1,
  migrate: createMigrate(migrations, { debug: true }),
  // debug: __DEV__,
};
const middlewares = [thunk]
const enhancers = []
const initialState = {}

const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers
)

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, initialState, composedEnhancers)
const persistor = persistStore(store);
export { store, persistor };